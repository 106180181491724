/*!
 * Ace Admin Template v3.0.1
 * Copyright 2013-2020
 * You need a commercial license to use this product
 * https://bit.ly/35ciMLb
 */
.navbar-white {
  background-color: #fff;
}

.navbar-white .navbar-inner {
  border-bottom: 1px solid #e4e4e4;
}

.navbar-white .navbar-toggler:hover, .navbar-white .navbar-toggler:focus {
  background-color: rgba(255, 255, 255, 0.1);
}

.navbar-white .navbar-toggler[aria-expanded="true"] {
  background-color: #e9f2fa;
}

.navbar-white .navbar-nav > .nav > .nav-item > .nav-link:not(.disabled) {
  color: #5e6267;
  background-color: transparent;
}

.navbar-white .navbar-nav > .nav > .nav-item:hover > .nav-link:not(.disabled) {
  color: #575a5f;
  background-color: #eef1f5;
}

.navbar-white .navbar-nav > .nav > .nav-item > .nav-link:active:not(.disabled) {
  color: #575a5f;
  background-color: #eef1f5;
}

.navbar-white .navbar-nav > .nav.has-active-border > .nav-item > .nav-link:not(.disabled)::before {
  background-color: #99a4bb;
}

.navbar-white .navbar-nav > .nav > .nav-item.show > .nav-link:not(.disabled) {
  color: #575a5f;
  background-color: #eef1f5;
}

@media (min-width: 992px) {
  .body-container > .navbar-white .navbar-intro {
    border-right: 1px solid #f0f0f0;
  }
  .navbar-white .navbar-nav > .nav > .nav-item > .nav-link::after {
    content: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-white.navbar {
    background-color: #fff;
  }
  .navbar-white .navbar-inner {
    box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.075) !important;
    border-bottom: 1px solid #e4e4e4;
  }
  .navbar-white .navbar-intro {
    background-color: #fff !important;
  }
  .navbar-white .navbar-input {
    border-bottom-color: #b8d2e3;
  }
  .navbar-white .navbar-collapse {
    border: 0px solid rgba(69, 124, 168, 0.13);
    border-width: 1px 0;
  }
  .navbar-white .navbar-collapse > .navbar-nav > .nav > .nav-item {
    border-bottom: 1px solid rgba(69, 124, 168, 0.13);
  }
  .navbar-white .navbar-collapse > .navbar-nav > .nav > .nav-item.dropdown.show {
    background-color: rgba(232, 239, 245, 0.5);
  }
  .navbar-white .navbar-collapse > .navbar-nav > .nav > .nav-item.dropdown.show .dropdown-menu {
    border: 1px solid rgba(0, 0, 0, 0.09) !important;
    margin-top: 1px;
  }
  .navbar-white .navbar-collapse > .navbar-nav > .nav > .nav-item > .nav-link.active {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-white .navbar-collapse > .navbar-nav > .nav > .nav-item > .nav-link:hover {
    background-color: #fff;
    color: #5e6267;
  }
  .navbar-white .navbar-collapse > .navbar-nav > .nav > .nav-item.show > .nav-link {
    background-color: transparent;
    color: #5e6267;
  }
}

@media (min-width: 992px) {
  .navbar-white .navbar-fixed > .navbar-inner {
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1), inset 1px -1px 0px 0px rgba(0, 0, 0, 0.05);
  }
  .navbar-white .navbar-fixed.container {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  }
  .navbar-white .navbar-fixed.container .navbar-inner {
    left: auto;
    right: auto;
  }
}

.navbar-white .navbar-input {
  color: #596b83;
  background-color: #eff2f4;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
}

.navbar-white .navbar-input:focus {
  border-color: #b8d2e3;
  background-color: #fff;
}

.navbar-white .navbar-input:not(:focus):hover {
  border-color: #d3dadf;
  background-color: #f2f4f6;
}

.navbar-white .navbar-input::placeholder {
  color: rgba(89, 107, 131, 0.8);
}

.navbar-white .navbar-input::-moz-placeholder {
  color: #596b83;
}

.navbar-white2 {
  background-color: #fff;
}

.navbar-white2 .navbar-inner {
  border-bottom: 1px solid #e4e4e4;
}

.navbar-white2 .navbar-toggler:hover, .navbar-white2 .navbar-toggler:focus {
  background-color: rgba(255, 255, 255, 0.1);
}

.navbar-white2 .navbar-toggler[aria-expanded="true"] {
  background-color: #e9f2fa;
}

.navbar-white2 .navbar-nav > .nav > .nav-item > .nav-link:not(.disabled) {
  color: #617694;
  background-color: transparent;
}

.navbar-white2 .navbar-nav > .nav > .nav-item:hover > .nav-link:not(.disabled) {
  color: #586a84;
  background-color: #ebf1f6;
}

.navbar-white2 .navbar-nav > .nav > .nav-item > .nav-link:active:not(.disabled) {
  color: #586a84;
  background-color: #ebf1f6;
}

.navbar-white2 .navbar-nav > .nav.has-active-border > .nav-item > .nav-link:not(.disabled)::before {
  background-color: #76abd4;
}

.navbar-white2 .navbar-nav > .nav > .nav-item.show > .nav-link:not(.disabled) {
  color: #586a84;
  background-color: #ebf1f6;
}

@media (min-width: 992px) {
  .body-container > .navbar-white2 .navbar-intro {
    border-right: 1px solid #f0f0f0;
  }
  .navbar-white2 .navbar-nav > .nav > .nav-item > .nav-link::after {
    content: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-white2.navbar {
    background-color: #fff;
  }
  .navbar-white2 .navbar-inner {
    box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.075) !important;
    border-bottom: 1px solid #e4e4e4;
  }
  .navbar-white2 .navbar-intro {
    background-color: #fff !important;
  }
  .navbar-white2 .navbar-input {
    border-bottom-color: rgba(0, 0, 0, 0.25);
  }
  .navbar-white2 .navbar-collapse {
    border: 0px solid rgba(69, 124, 168, 0.13);
    border-width: 1px 0;
  }
  .navbar-white2 .navbar-collapse > .navbar-nav > .nav > .nav-item {
    border-bottom: 1px solid rgba(69, 124, 168, 0.13);
  }
  .navbar-white2 .navbar-collapse > .navbar-nav > .nav > .nav-item.dropdown.show {
    background-color: rgba(232, 239, 245, 0.5);
  }
  .navbar-white2 .navbar-collapse > .navbar-nav > .nav > .nav-item.dropdown.show .dropdown-menu {
    border: 1px solid rgba(0, 0, 0, 0.09) !important;
    margin-top: 1px;
  }
  .navbar-white2 .navbar-collapse > .navbar-nav > .nav > .nav-item > .nav-link.active {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-white2 .navbar-collapse > .navbar-nav > .nav > .nav-item > .nav-link:hover {
    background-color: #fff;
    color: #617694;
  }
  .navbar-white2 .navbar-collapse > .navbar-nav > .nav > .nav-item.show > .nav-link {
    background-color: transparent;
    color: #617694;
  }
}

@media (min-width: 992px) {
  .navbar-white2 .navbar-fixed > .navbar-inner {
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1), inset 1px -1px 0px 0px rgba(0, 0, 0, 0.05);
  }
  .navbar-white2 .navbar-fixed.container {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  }
  .navbar-white2 .navbar-fixed.container .navbar-inner {
    left: auto;
    right: auto;
  }
}

.navbar-white2 .navbar-input {
  color: #535865;
  background-color: transparent;
  border-width: 0 0 1px;
  border-style: solid;
  border-color: transparent;
}

.navbar-white2 .navbar-input:focus {
  border-color: rgba(0, 0, 0, 0.25);
}

.navbar-white2 .navbar-input:not(:focus):hover {
  border-color: rgba(0, 0, 0, 0.175);
}

.navbar-white2 .navbar-input::placeholder {
  color: rgba(83, 88, 101, 0.8);
}

.navbar-white2 .navbar-input::-moz-placeholder {
  color: #535865;
}

.navbar-light {
  background-color: whitesmoke;
}

.navbar-light .navbar-inner {
  border-bottom: 1px solid #e4e4e4;
}

.navbar-light .navbar-toggler:hover, .navbar-light .navbar-toggler:focus {
  background-color: rgba(255, 255, 255, 0.1);
}

.navbar-light .navbar-toggler[aria-expanded="true"] {
  background-color: #f0f5f9;
}

.navbar-light .navbar-nav > .nav > .nav-item > .nav-link:not(.disabled) {
  color: rgba(0, 0, 0, 0.5);
  background-color: transparent;
}

.navbar-light .navbar-nav > .nav > .nav-item:hover > .nav-link:not(.disabled) {
  color: #575a5f;
  background-color: rgba(232, 239, 245, 0.5);
}

.navbar-light .navbar-nav > .nav > .nav-item > .nav-link:active:not(.disabled) {
  color: #575a5f;
  background-color: rgba(232, 239, 245, 0.5);
}

.navbar-light .navbar-nav > .nav > .nav-item.show > .nav-link:not(.disabled) {
  color: #575a5f;
  background-color: rgba(232, 239, 245, 0.5);
}

@media (min-width: 992px) {
  .navbar-light .navbar-nav > .nav > .nav-item > .nav-link::after {
    content: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-light.navbar {
    background-color: #fff;
  }
  .navbar-light .navbar-inner {
    box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.075) !important;
    border-bottom: 1px solid #e4e4e4;
  }
  .navbar-light .navbar-intro {
    background-color: #fff !important;
  }
  .navbar-light .navbar-input {
    border-bottom-color: #b8d2e3;
  }
  .navbar-light .navbar-collapse {
    border: 0px solid rgba(69, 124, 168, 0.13);
    border-width: 1px 0;
  }
  .navbar-light .navbar-collapse > .navbar-nav > .nav > .nav-item {
    border-bottom: 1px solid rgba(69, 124, 168, 0.13);
  }
  .navbar-light .navbar-collapse > .navbar-nav > .nav > .nav-item.dropdown.show {
    background-color: whitesmoke;
  }
  .navbar-light .navbar-collapse > .navbar-nav > .nav > .nav-item.dropdown.show .dropdown-menu {
    border: 1px solid rgba(0, 0, 0, 0.09) !important;
    margin-top: 1px;
  }
  .navbar-light .navbar-collapse > .navbar-nav > .nav > .nav-item > .nav-link.active {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-light .navbar-collapse > .navbar-nav > .nav > .nav-item > .nav-link:hover {
    background-color: whitesmoke;
    color: rgba(0, 0, 0, 0.5);
  }
  .navbar-light .navbar-collapse > .navbar-nav > .nav > .nav-item.show > .nav-link {
    background-color: transparent;
    color: rgba(0, 0, 0, 0.5);
  }
}

@media (min-width: 992px) {
  .navbar-light .navbar-fixed > .navbar-inner {
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1), inset 1px -1px 0px 0px rgba(0, 0, 0, 0.05);
  }
  .navbar-light .navbar-fixed.container {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  }
  .navbar-light .navbar-fixed.container .navbar-inner {
    left: auto;
    right: auto;
  }
}

.navbar-light .navbar-input {
  color: #596b83;
  background-color: #eff2f4;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
}

.navbar-light .navbar-input:focus {
  border-color: #b8d2e3;
  background-color: #fff;
}

.navbar-light .navbar-input:not(:focus):hover {
  border-color: #d3dadf;
  background-color: #f2f4f6;
}

.navbar-light .navbar-input::placeholder {
  color: rgba(89, 107, 131, 0.8);
}

.navbar-light .navbar-input::-moz-placeholder {
  color: #596b83;
}

.navbar-lightblue {
  background-color: #f2f8fd;
}

.navbar-lightblue .navbar-inner {
  border-bottom: 1px solid #dfedf8;
}

.navbar-lightblue .navbar-toggler:hover, .navbar-lightblue .navbar-toggler:focus {
  background-color: rgba(255, 255, 255, 0.1);
}

.navbar-lightblue .navbar-toggler[aria-expanded="true"] {
  background-color: #f0f5f9;
}

.navbar-lightblue .navbar-nav > .nav > .nav-item > .nav-link:not(.disabled) {
  color: #45749a;
  background-color: transparent;
}

.navbar-lightblue .navbar-nav > .nav > .nav-item:hover > .nav-link:not(.disabled) {
  color: #fff;
  background-color: #5c86a8;
}

.navbar-lightblue .navbar-nav > .nav > .nav-item > .nav-link:active:not(.disabled) {
  color: #fff;
  background-color: #5c86a8;
}

.navbar-lightblue .navbar-nav > .nav > .nav-item.show > .nav-link:not(.disabled) {
  color: #fff;
  background-color: #537c9c;
}

@media (min-width: 992px) {
  .navbar-lightblue .navbar-intro {
    background-color: #fdfeff;
  }
  .body-container > .navbar-lightblue .navbar-intro {
    border-right: 1px solid #e5edf5;
  }
  .navbar-lightblue .navbar-nav > .nav > .nav-item > .nav-link::after {
    content: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-lightblue.navbar {
    background-color: #fff;
  }
  .navbar-lightblue .navbar-inner {
    box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.075) !important;
    border-bottom: 1px solid #dfedf8;
  }
  .navbar-lightblue .navbar-intro {
    background-color: #fff !important;
  }
  .navbar-lightblue .navbar-input {
    border-bottom-color: #b8d2e3;
  }
  .navbar-lightblue .navbar-collapse {
    border: 0px solid #dae5ee;
    border-width: 1px 0;
  }
  .navbar-lightblue .navbar-collapse > .navbar-nav > .nav > .nav-item {
    border-bottom: 1px solid #dae5ee;
  }
  .navbar-lightblue .navbar-collapse > .navbar-nav > .nav > .nav-item.dropdown.show {
    background-color: #f2f8fd;
  }
  .navbar-lightblue .navbar-collapse > .navbar-nav > .nav > .nav-item.dropdown.show .dropdown-menu {
    border: 1px solid rgba(0, 0, 0, 0.09) !important;
    margin-top: 1px;
  }
  .navbar-lightblue .navbar-collapse > .navbar-nav > .nav > .nav-item > .nav-link.active {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-lightblue .navbar-collapse > .navbar-nav > .nav > .nav-item > .nav-link:hover {
    background-color: #f2f8fd;
    color: #45749a;
  }
  .navbar-lightblue .navbar-collapse > .navbar-nav > .nav > .nav-item.show > .nav-link {
    background-color: transparent;
    color: #45749a;
  }
}

@media (min-width: 992px) {
  .navbar-lightblue .navbar-fixed > .navbar-inner {
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1), inset 1px -1px 0px 0px rgba(0, 0, 0, 0.05);
  }
  .navbar-lightblue .navbar-fixed.container {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  }
  .navbar-lightblue .navbar-fixed.container .navbar-inner {
    left: auto;
    right: auto;
  }
}

.navbar-lightblue .navbar-input {
  color: #596b83;
  background-color: #eff2f4;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
}

.navbar-lightblue .navbar-input:focus {
  border-color: #b8d2e3;
  background-color: #fff;
}

.navbar-lightblue .navbar-input:not(:focus):hover {
  border-color: #d3dadf;
  background-color: #f2f4f6;
}

.navbar-lightblue .navbar-input::placeholder {
  color: rgba(89, 107, 131, 0.8);
}

.navbar-lightblue .navbar-input::-moz-placeholder {
  color: #596b83;
}

.navbar-lightpurple {
  background-color: #f2f1f9;
}

.navbar-lightpurple .navbar-inner {
  border-bottom: 1px solid #e8e5f0;
}

.navbar-lightpurple .navbar-toggler:hover, .navbar-lightpurple .navbar-toggler:focus {
  background-color: rgba(255, 255, 255, 0.1);
}

.navbar-lightpurple .navbar-toggler[aria-expanded="true"] {
  background-color: #f0f5f9;
}

.navbar-lightpurple .navbar-nav > .nav > .nav-item > .nav-link:not(.disabled) {
  color: #6f609a;
  background-color: transparent;
}

.navbar-lightpurple .navbar-nav > .nav > .nav-item:hover > .nav-link:not(.disabled) {
  color: #fff;
  background-color: #7f73c6;
}

.navbar-lightpurple .navbar-nav > .nav > .nav-item > .nav-link:active:not(.disabled) {
  color: #fff;
  background-color: #7f73c6;
}

.navbar-lightpurple .navbar-nav > .nav > .nav-item.show > .nav-link:not(.disabled) {
  color: #fff;
  background-color: #7265c0;
}

@media (min-width: 992px) {
  .navbar-lightpurple .navbar-intro {
    background-color: #fdfdfe;
  }
  .body-container > .navbar-lightpurple .navbar-intro {
    border-right: 1px solid #efedf5;
  }
  .navbar-lightpurple .navbar-nav > .nav > .nav-item > .nav-link::after {
    content: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-lightpurple.navbar {
    background-color: #fff;
  }
  .navbar-lightpurple .navbar-inner {
    box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.075) !important;
    border-bottom: 1px solid #e8e5f0;
  }
  .navbar-lightpurple .navbar-intro {
    background-color: #fff !important;
  }
  .navbar-lightpurple .navbar-input {
    border-bottom-color: #b8d2e3;
  }
  .navbar-lightpurple .navbar-collapse {
    border: 0px solid #f2eff7;
    border-width: 1px 0;
  }
  .navbar-lightpurple .navbar-collapse > .navbar-nav > .nav > .nav-item {
    border-bottom: 1px solid #f2eff7;
  }
  .navbar-lightpurple .navbar-collapse > .navbar-nav > .nav > .nav-item.dropdown.show {
    background-color: #f2f1f9;
  }
  .navbar-lightpurple .navbar-collapse > .navbar-nav > .nav > .nav-item.dropdown.show .dropdown-menu {
    border: 1px solid rgba(0, 0, 0, 0.09) !important;
    margin-top: 1px;
  }
  .navbar-lightpurple .navbar-collapse > .navbar-nav > .nav > .nav-item > .nav-link.active {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-lightpurple .navbar-collapse > .navbar-nav > .nav > .nav-item > .nav-link:hover {
    background-color: #f2f1f9;
    color: #6f609a;
  }
  .navbar-lightpurple .navbar-collapse > .navbar-nav > .nav > .nav-item.show > .nav-link {
    background-color: transparent;
    color: #6f609a;
  }
}

@media (min-width: 992px) {
  .navbar-lightpurple .navbar-fixed > .navbar-inner {
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1), inset 1px -1px 0px 0px rgba(0, 0, 0, 0.05);
  }
  .navbar-lightpurple .navbar-fixed.container {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  }
  .navbar-lightpurple .navbar-fixed.container .navbar-inner {
    left: auto;
    right: auto;
  }
}

.navbar-lightpurple .navbar-input {
  color: #596b83;
  background-color: #eff2f4;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
}

.navbar-lightpurple .navbar-input:focus {
  border-color: #b8d2e3;
  background-color: #fff;
}

.navbar-lightpurple .navbar-input:not(:focus):hover {
  border-color: #d3dadf;
  background-color: #f2f4f6;
}

.navbar-lightpurple .navbar-input::placeholder {
  color: rgba(89, 107, 131, 0.8);
}

.navbar-lightpurple .navbar-input::-moz-placeholder {
  color: #596b83;
}

.navbar-lightgreen {
  background-color: #effced;
}

.navbar-lightgreen .navbar-inner {
  border-bottom: 1px solid #e7f1e5;
}

.navbar-lightgreen .navbar-toggler:hover, .navbar-lightgreen .navbar-toggler:focus {
  background-color: rgba(255, 255, 255, 0.1);
}

.navbar-lightgreen .navbar-toggler[aria-expanded="true"] {
  background-color: #f0f5f9;
}

.navbar-lightgreen .navbar-nav > .nav > .nav-item > .nav-link:not(.disabled) {
  color: #5c7164;
  background-color: transparent;
}

.navbar-lightgreen .navbar-nav > .nav > .nav-item:hover > .nav-link:not(.disabled) {
  color: #fff;
  background-color: #5fa267;
}

.navbar-lightgreen .navbar-nav > .nav > .nav-item > .nav-link:active:not(.disabled) {
  color: #fff;
  background-color: #85c28c;
}

.navbar-lightgreen .navbar-nav > .nav > .nav-item.show > .nav-link:not(.disabled) {
  color: #fff;
  background-color: #57965e;
}

@media (min-width: 992px) {
  .navbar-lightgreen .navbar-intro {
    background-color: #fcfffc;
  }
  .body-container > .navbar-lightgreen .navbar-intro {
    border-right: 1px solid #e7f1e5;
  }
  .navbar-lightgreen .navbar-nav > .nav > .nav-item > .nav-link::after {
    content: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-lightgreen.navbar {
    background-color: #fff;
  }
  .navbar-lightgreen .navbar-inner {
    box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.075) !important;
    border-bottom: 1px solid #e7f1e5;
  }
  .navbar-lightgreen .navbar-intro {
    background-color: #fff !important;
  }
  .navbar-lightgreen .navbar-input {
    border-bottom-color: #b8d2e3;
  }
  .navbar-lightgreen .navbar-collapse {
    border: 0px solid #eff7f0;
    border-width: 1px 0;
  }
  .navbar-lightgreen .navbar-collapse > .navbar-nav > .nav > .nav-item {
    border-bottom: 1px solid #eff7f0;
  }
  .navbar-lightgreen .navbar-collapse > .navbar-nav > .nav > .nav-item.dropdown.show {
    background-color: #effced;
  }
  .navbar-lightgreen .navbar-collapse > .navbar-nav > .nav > .nav-item.dropdown.show .dropdown-menu {
    border: 1px solid rgba(0, 0, 0, 0.09) !important;
    margin-top: 1px;
  }
  .navbar-lightgreen .navbar-collapse > .navbar-nav > .nav > .nav-item > .nav-link.active {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-lightgreen .navbar-collapse > .navbar-nav > .nav > .nav-item > .nav-link:hover {
    background-color: #effced;
    color: #5c7164;
  }
  .navbar-lightgreen .navbar-collapse > .navbar-nav > .nav > .nav-item.show > .nav-link {
    background-color: transparent;
    color: #5c7164;
  }
}

@media (min-width: 992px) {
  .navbar-lightgreen .navbar-fixed > .navbar-inner {
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1), inset 1px -1px 0px 0px rgba(0, 0, 0, 0.05);
  }
  .navbar-lightgreen .navbar-fixed.container {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  }
  .navbar-lightgreen .navbar-fixed.container .navbar-inner {
    left: auto;
    right: auto;
  }
}

.navbar-lightgreen .navbar-input {
  color: #596b83;
  background-color: #eff2f4;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
}

.navbar-lightgreen .navbar-input:focus {
  border-color: #b8d2e3;
  background-color: #fff;
}

.navbar-lightgreen .navbar-input:not(:focus):hover {
  border-color: #d3dadf;
  background-color: #f2f4f6;
}

.navbar-lightgreen .navbar-input::placeholder {
  color: rgba(89, 107, 131, 0.8);
}

.navbar-lightgreen .navbar-input::-moz-placeholder {
  color: #596b83;
}

.navbar-lightgrey {
  background-color: #f4f5f7;
}

.navbar-lightgrey .navbar-toggler:hover, .navbar-lightgrey .navbar-toggler:focus {
  background-color: rgba(255, 255, 255, 0.1);
}

.navbar-lightgrey .navbar-toggler[aria-expanded="true"] {
  background-color: #f0f5f9;
}

.navbar-lightgrey .navbar-nav > .nav > .nav-item > .nav-link:not(.disabled) {
  color: #60646b;
  background-color: transparent;
}

.navbar-lightgrey .navbar-nav > .nav > .nav-item:hover > .nav-link:not(.disabled) {
  color: #fff;
  background-color: #79848a;
}

.navbar-lightgrey .navbar-nav > .nav > .nav-item > .nav-link:active:not(.disabled) {
  color: #fff;
  background-color: #79848a;
}

.navbar-lightgrey .navbar-nav > .nav > .nav-item.show > .nav-link:not(.disabled) {
  color: #fff;
  background-color: #6f7a7f;
}

@media (min-width: 992px) {
  .navbar-lightgrey .navbar-intro {
    background-color: #e4e5e7;
  }
  .navbar-lightgrey .navbar-nav > .nav > .nav-item > .nav-link::after {
    content: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-lightgrey.navbar {
    background-color: #fff;
  }
  .navbar-lightgrey .navbar-inner {
    box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.075) !important;
    border-bottom: 1px solid #e6eaed;
  }
  .navbar-lightgrey .navbar-intro {
    background-color: #fff !important;
  }
  .navbar-lightgrey .navbar-input {
    border-bottom-color: #b8d2e3;
  }
  .navbar-lightgrey .navbar-collapse {
    border: 0px solid #f1f3f3;
    border-width: 1px 0;
  }
  .navbar-lightgrey .navbar-collapse > .navbar-nav > .nav > .nav-item {
    border-bottom: 1px solid #f1f3f3;
  }
  .navbar-lightgrey .navbar-collapse > .navbar-nav > .nav > .nav-item.dropdown.show {
    background-color: #f4f5f7;
  }
  .navbar-lightgrey .navbar-collapse > .navbar-nav > .nav > .nav-item.dropdown.show .dropdown-menu {
    border: 1px solid rgba(0, 0, 0, 0.09) !important;
    margin-top: 1px;
  }
  .navbar-lightgrey .navbar-collapse > .navbar-nav > .nav > .nav-item > .nav-link.active {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-lightgrey .navbar-collapse > .navbar-nav > .nav > .nav-item > .nav-link:hover {
    background-color: #f4f5f7;
    color: #60646b;
  }
  .navbar-lightgrey .navbar-collapse > .navbar-nav > .nav > .nav-item.show > .nav-link {
    background-color: transparent;
    color: #60646b;
  }
}

@media (min-width: 992px) {
  .navbar-lightgrey .navbar-fixed > .navbar-inner {
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1), inset 1px -1px 0px 0px rgba(0, 0, 0, 0.05);
  }
  .navbar-lightgrey .navbar-fixed.container {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  }
  .navbar-lightgrey .navbar-fixed.container .navbar-inner {
    left: auto;
    right: auto;
  }
}

.navbar-lightgrey .navbar-input {
  color: #596b83;
  background-color: #eff2f4;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
}

.navbar-lightgrey .navbar-input:focus {
  border-color: #b8d2e3;
  background-color: #fff;
}

.navbar-lightgrey .navbar-input:not(:focus):hover {
  border-color: #d3dadf;
  background-color: #f2f4f6;
}

.navbar-lightgrey .navbar-input::placeholder {
  color: rgba(89, 107, 131, 0.8);
}

.navbar-lightgrey .navbar-input::-moz-placeholder {
  color: #596b83;
}

.navbar-blue {
  background-color: #2872ba;
}

.navbar-blue .navbar-toggler:hover, .navbar-blue .navbar-toggler:focus {
  background-color: rgba(255, 255, 255, 0.1);
}

.navbar-blue .navbar-toggler[aria-expanded="true"] {
  background-color: rgba(0, 0, 0, 0.1);
}

.navbar-blue .navbar-nav > .nav > .nav-item > .nav-link:not(.disabled) {
  color: rgba(255, 255, 255, 0.92);
  background-color: transparent;
}

.navbar-blue .navbar-nav > .nav > .nav-item:hover > .nav-link:not(.disabled) {
  background-color: rgba(255, 255, 255, 0.1);
}

.navbar-blue .navbar-nav > .nav > .nav-item > .nav-link:active:not(.disabled) {
  background-color: rgba(0, 0, 0, 0.1);
}

.navbar-blue .navbar-nav > .nav.has-active-border > .nav-item > .nav-link:not(.disabled)::before {
  background-color: rgba(255, 255, 255, 0.73);
}

.navbar-blue .navbar-nav > .nav > .nav-item.show > .nav-link:not(.disabled) {
  background-color: rgba(0, 0, 0, 0.15);
}

@media (min-width: 992px) {
  .navbar-blue .navbar-intro {
    background-color: #2468a9;
  }
  .navbar-blue .navbar-nav > .nav > .nav-item {
    border: 0px solid rgba(0, 0, 0, 0.1);
  }
  .navbar-blue .navbar-nav > .nav > .nav-item {
    border-width: 0 0 0 1px;
  }
  .navbar-blue .navbar-nav > .nav > .nav-item:last-of-type {
    border-width: 0 1px;
  }
  .navbar-blue .navbar-nav > .nav > .nav-item.show,
  .navbar-blue .navbar-nav > .nav > .nav-item.show + .nav-item {
    border-color: rgba(0, 0, 0, 0.2);
  }
  .navbar-blue .navbar-nav > .nav > .nav-item > .nav-link::after {
    content: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-blue .navbar-input {
    border-bottom-color: rgba(255, 255, 255, 0.7);
  }
  .navbar-blue .navbar-collapse {
    border: 0px solid rgba(34, 85, 117, 0.2);
    border-width: 1px 0;
  }
  .navbar-blue .navbar-collapse > .navbar-nav > .nav > .nav-item {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .navbar-blue .navbar-collapse > .navbar-nav > .nav > .nav-item.dropdown.show {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-blue .navbar-collapse > .navbar-nav > .nav > .nav-item > .nav-link.active {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-blue .navbar-collapse > .navbar-nav > .nav > .nav-item.show > .nav-link {
    background-color: transparent;
  }
}

@media (min-width: 992px) {
  .navbar-blue .navbar-fixed > .navbar-inner {
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1), inset 1px -1px 0px 0px rgba(0, 0, 0, 0.05);
  }
  .navbar-blue .navbar-fixed.container {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  }
  .navbar-blue .navbar-fixed.container .navbar-inner {
    left: auto;
    right: auto;
  }
}

.navbar-blue .navbar-input {
  color: #fff;
  background-color: transparent;
  border-width: 0 0 1px;
  border-style: solid;
  border-color: transparent;
}

.navbar-blue .navbar-input:focus {
  border-color: rgba(255, 255, 255, 0.7);
}

.navbar-blue .navbar-input:not(:focus):hover {
  border-color: rgba(255, 255, 255, 0.7);
}

.navbar-blue .navbar-input::placeholder {
  color: rgba(255, 255, 255, 0.8);
}

.navbar-blue .navbar-input::-moz-placeholder {
  color: #fff;
}

.navbar-dark {
  background-color: #4d5762;
}

.navbar-dark .navbar-toggler:hover, .navbar-dark .navbar-toggler:focus {
  background-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler[aria-expanded="true"] {
  background-color: rgba(0, 0, 0, 0.1);
}

.navbar-dark .navbar-nav > .nav > .nav-item > .nav-link:not(.disabled) {
  color: rgba(255, 255, 255, 0.92);
  background-color: transparent;
}

.navbar-dark .navbar-nav > .nav > .nav-item:hover > .nav-link:not(.disabled) {
  background-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-nav > .nav > .nav-item > .nav-link:active:not(.disabled) {
  background-color: rgba(0, 0, 0, 0.1);
}

.navbar-dark .navbar-nav > .nav.has-active-border > .nav-item > .nav-link:not(.disabled)::before {
  background-color: rgba(255, 255, 255, 0.73);
}

.navbar-dark .navbar-nav > .nav > .nav-item.show > .nav-link:not(.disabled) {
  background-color: rgba(0, 0, 0, 0.15);
}

@media (min-width: 992px) {
  .navbar-dark .navbar-intro {
    background-color: #464f59;
  }
  .navbar-dark .navbar-nav > .nav > .nav-item {
    border: 0px solid rgba(0, 0, 0, 0.1);
  }
  .navbar-dark .navbar-nav > .nav > .nav-item {
    border-width: 0 0 0 1px;
  }
  .navbar-dark .navbar-nav > .nav > .nav-item:last-of-type {
    border-width: 0 1px;
  }
  .navbar-dark .navbar-nav > .nav > .nav-item.show,
  .navbar-dark .navbar-nav > .nav > .nav-item.show + .nav-item {
    border-color: rgba(0, 0, 0, 0.2);
  }
  .navbar-dark .navbar-nav > .nav > .nav-item > .nav-link::after {
    content: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-dark .navbar-input {
    border-bottom-color: rgba(255, 255, 255, 0.7);
  }
  .navbar-dark .navbar-collapse {
    border: 0px solid rgba(34, 85, 117, 0.2);
    border-width: 1px 0;
  }
  .navbar-dark .navbar-collapse > .navbar-nav > .nav > .nav-item {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .navbar-dark .navbar-collapse > .navbar-nav > .nav > .nav-item.dropdown.show {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-dark .navbar-collapse > .navbar-nav > .nav > .nav-item > .nav-link.active {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-dark .navbar-collapse > .navbar-nav > .nav > .nav-item.show > .nav-link {
    background-color: transparent;
  }
}

@media (min-width: 992px) {
  .navbar-dark .navbar-fixed > .navbar-inner {
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1), inset 1px -1px 0px 0px rgba(0, 0, 0, 0.05);
  }
  .navbar-dark .navbar-fixed.container {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  }
  .navbar-dark .navbar-fixed.container .navbar-inner {
    left: auto;
    right: auto;
  }
}

.navbar-dark .navbar-input {
  color: #fff;
  background-color: transparent;
  border-width: 0 0 1px;
  border-style: solid;
  border-color: transparent;
}

.navbar-dark .navbar-input:focus {
  border-color: rgba(255, 255, 255, 0.7);
}

.navbar-dark .navbar-input:not(:focus):hover {
  border-color: rgba(255, 255, 255, 0.7);
}

.navbar-dark .navbar-input::placeholder {
  color: rgba(255, 255, 255, 0.8);
}

.navbar-dark .navbar-input::-moz-placeholder {
  color: #fff;
}

.navbar-purple {
  background-color: #63529f;
}

.navbar-purple .navbar-toggler:hover, .navbar-purple .navbar-toggler:focus {
  background-color: rgba(255, 255, 255, 0.1);
}

.navbar-purple .navbar-toggler[aria-expanded="true"] {
  background-color: rgba(0, 0, 0, 0.1);
}

.navbar-purple .navbar-nav > .nav > .nav-item > .nav-link:not(.disabled) {
  color: rgba(255, 255, 255, 0.95);
  background-color: transparent;
}

.navbar-purple .navbar-nav > .nav > .nav-item:hover > .nav-link:not(.disabled) {
  background-color: rgba(255, 255, 255, 0.125);
}

.navbar-purple .navbar-nav > .nav > .nav-item > .nav-link:active:not(.disabled) {
  background-color: rgba(0, 0, 0, 0.075);
}

.navbar-purple .navbar-nav > .nav.has-active-border > .nav-item > .nav-link:not(.disabled)::before {
  background-color: rgba(255, 255, 255, 0.73);
}

.navbar-purple .navbar-nav > .nav > .nav-item.show > .nav-link:not(.disabled) {
  background-color: rgba(0, 0, 0, 0.1);
}

@media (min-width: 992px) {
  .navbar-purple .navbar-intro {
    background-color: #5a4b91;
  }
  .navbar-purple .navbar-nav > .nav > .nav-item {
    border: 0px solid rgba(0, 0, 0, 0.1);
  }
  .navbar-purple .navbar-nav > .nav > .nav-item {
    border-width: 0 0 0 1px;
  }
  .navbar-purple .navbar-nav > .nav > .nav-item:last-of-type {
    border-width: 0 1px;
  }
  .navbar-purple .navbar-nav > .nav > .nav-item.show,
  .navbar-purple .navbar-nav > .nav > .nav-item.show + .nav-item {
    border-color: rgba(0, 0, 0, 0.2);
  }
  .navbar-purple .navbar-nav > .nav > .nav-item > .nav-link::after {
    content: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-purple .navbar-input {
    border-bottom-color: rgba(255, 255, 255, 0.7);
  }
  .navbar-purple .navbar-collapse {
    border: 0px solid rgba(34, 85, 117, 0.2);
    border-width: 1px 0;
  }
  .navbar-purple .navbar-collapse > .navbar-nav > .nav > .nav-item {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .navbar-purple .navbar-collapse > .navbar-nav > .nav > .nav-item.dropdown.show {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-purple .navbar-collapse > .navbar-nav > .nav > .nav-item > .nav-link.active {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-purple .navbar-collapse > .navbar-nav > .nav > .nav-item.show > .nav-link {
    background-color: transparent;
  }
}

@media (min-width: 992px) {
  .navbar-purple .navbar-fixed > .navbar-inner {
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1), inset 1px -1px 0px 0px rgba(0, 0, 0, 0.05);
  }
  .navbar-purple .navbar-fixed.container {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  }
  .navbar-purple .navbar-fixed.container .navbar-inner {
    left: auto;
    right: auto;
  }
}

.navbar-purple .navbar-input {
  color: #fff;
  background-color: transparent;
  border-width: 0 0 1px;
  border-style: solid;
  border-color: transparent;
}

.navbar-purple .navbar-input:focus {
  border-color: rgba(255, 255, 255, 0.7);
}

.navbar-purple .navbar-input:not(:focus):hover {
  border-color: rgba(255, 255, 255, 0.7);
}

.navbar-purple .navbar-input::placeholder {
  color: rgba(255, 255, 255, 0.8);
}

.navbar-purple .navbar-input::-moz-placeholder {
  color: #fff;
}

.navbar-plum {
  background-color: #824c80;
}

.navbar-plum .navbar-toggler:hover, .navbar-plum .navbar-toggler:focus {
  background-color: rgba(255, 255, 255, 0.1);
}

.navbar-plum .navbar-toggler[aria-expanded="true"] {
  background-color: rgba(0, 0, 0, 0.1);
}

.navbar-plum .navbar-nav > .nav > .nav-item > .nav-link:not(.disabled) {
  color: rgba(255, 255, 255, 0.95);
  background-color: transparent;
}

.navbar-plum .navbar-nav > .nav > .nav-item:hover > .nav-link:not(.disabled) {
  background-color: rgba(255, 255, 255, 0.125);
}

.navbar-plum .navbar-nav > .nav > .nav-item > .nav-link:active:not(.disabled) {
  background-color: rgba(0, 0, 0, 0.075);
}

.navbar-plum .navbar-nav > .nav.has-active-border > .nav-item > .nav-link:not(.disabled)::before {
  background-color: rgba(255, 255, 255, 0.73);
}

.navbar-plum .navbar-nav > .nav > .nav-item.show > .nav-link:not(.disabled) {
  background-color: rgba(0, 0, 0, 0.1);
}

@media (min-width: 992px) {
  .navbar-plum .navbar-intro {
    background-color: #764574;
  }
  .navbar-plum .navbar-nav > .nav > .nav-item {
    border: 0px solid rgba(0, 0, 0, 0.1);
  }
  .navbar-plum .navbar-nav > .nav > .nav-item {
    border-width: 0 0 0 1px;
  }
  .navbar-plum .navbar-nav > .nav > .nav-item:last-of-type {
    border-width: 0 1px;
  }
  .navbar-plum .navbar-nav > .nav > .nav-item.show,
  .navbar-plum .navbar-nav > .nav > .nav-item.show + .nav-item {
    border-color: rgba(0, 0, 0, 0.2);
  }
  .navbar-plum .navbar-nav > .nav > .nav-item > .nav-link::after {
    content: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-plum .navbar-input {
    border-bottom-color: rgba(255, 255, 255, 0.7);
  }
  .navbar-plum .navbar-collapse {
    border: 0px solid rgba(34, 85, 117, 0.2);
    border-width: 1px 0;
  }
  .navbar-plum .navbar-collapse > .navbar-nav > .nav > .nav-item {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .navbar-plum .navbar-collapse > .navbar-nav > .nav > .nav-item.dropdown.show {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-plum .navbar-collapse > .navbar-nav > .nav > .nav-item > .nav-link.active {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-plum .navbar-collapse > .navbar-nav > .nav > .nav-item.show > .nav-link {
    background-color: transparent;
  }
}

@media (min-width: 992px) {
  .navbar-plum .navbar-fixed > .navbar-inner {
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1), inset 1px -1px 0px 0px rgba(0, 0, 0, 0.05);
  }
  .navbar-plum .navbar-fixed.container {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  }
  .navbar-plum .navbar-fixed.container .navbar-inner {
    left: auto;
    right: auto;
  }
}

.navbar-plum .navbar-input {
  color: #fff;
  background-color: transparent;
  border-width: 0 0 1px;
  border-style: solid;
  border-color: transparent;
}

.navbar-plum .navbar-input:focus {
  border-color: rgba(255, 255, 255, 0.7);
}

.navbar-plum .navbar-input:not(:focus):hover {
  border-color: rgba(255, 255, 255, 0.7);
}

.navbar-plum .navbar-input::placeholder {
  color: rgba(255, 255, 255, 0.8);
}

.navbar-plum .navbar-input::-moz-placeholder {
  color: #fff;
}

.navbar-secondary {
  background-color: #678;
}

.navbar-secondary .navbar-toggler:hover, .navbar-secondary .navbar-toggler:focus {
  background-color: rgba(255, 255, 255, 0.1);
}

.navbar-secondary .navbar-toggler[aria-expanded="true"] {
  background-color: rgba(0, 0, 0, 0.1);
}

.navbar-secondary .navbar-nav > .nav > .nav-item > .nav-link:not(.disabled) {
  color: rgba(255, 255, 255, 0.95);
  background-color: transparent;
}

.navbar-secondary .navbar-nav > .nav > .nav-item:hover > .nav-link:not(.disabled) {
  background-color: rgba(255, 255, 255, 0.125);
}

.navbar-secondary .navbar-nav > .nav > .nav-item > .nav-link:active:not(.disabled) {
  background-color: rgba(0, 0, 0, 0.075);
}

.navbar-secondary .navbar-nav > .nav.has-active-border > .nav-item > .nav-link:not(.disabled)::before {
  background-color: rgba(255, 255, 255, 0.73);
}

.navbar-secondary .navbar-nav > .nav > .nav-item.show > .nav-link:not(.disabled) {
  background-color: rgba(0, 0, 0, 0.1);
}

@media (min-width: 992px) {
  .navbar-secondary .navbar-intro {
    background-color: #5d6c7c;
  }
  .navbar-secondary .navbar-nav > .nav > .nav-item {
    border: 0px solid rgba(0, 0, 0, 0.1);
  }
  .navbar-secondary .navbar-nav > .nav > .nav-item {
    border-width: 0 0 0 1px;
  }
  .navbar-secondary .navbar-nav > .nav > .nav-item:last-of-type {
    border-width: 0 1px;
  }
  .navbar-secondary .navbar-nav > .nav > .nav-item.show,
  .navbar-secondary .navbar-nav > .nav > .nav-item.show + .nav-item {
    border-color: rgba(0, 0, 0, 0.2);
  }
  .navbar-secondary .navbar-nav > .nav > .nav-item > .nav-link::after {
    content: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-secondary .navbar-input {
    border-bottom-color: rgba(255, 255, 255, 0.7);
  }
  .navbar-secondary .navbar-collapse {
    border: 0px solid rgba(34, 85, 117, 0.2);
    border-width: 1px 0;
  }
  .navbar-secondary .navbar-collapse > .navbar-nav > .nav > .nav-item {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .navbar-secondary .navbar-collapse > .navbar-nav > .nav > .nav-item.dropdown.show {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-secondary .navbar-collapse > .navbar-nav > .nav > .nav-item > .nav-link.active {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-secondary .navbar-collapse > .navbar-nav > .nav > .nav-item.show > .nav-link {
    background-color: transparent;
  }
}

@media (min-width: 992px) {
  .navbar-secondary .navbar-fixed > .navbar-inner {
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1), inset 1px -1px 0px 0px rgba(0, 0, 0, 0.05);
  }
  .navbar-secondary .navbar-fixed.container {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  }
  .navbar-secondary .navbar-fixed.container .navbar-inner {
    left: auto;
    right: auto;
  }
}

.navbar-secondary .navbar-input {
  color: #fff;
  background-color: transparent;
  border-width: 0 0 1px;
  border-style: solid;
  border-color: transparent;
}

.navbar-secondary .navbar-input:focus {
  border-color: rgba(255, 255, 255, 0.7);
}

.navbar-secondary .navbar-input:not(:focus):hover {
  border-color: rgba(255, 255, 255, 0.7);
}

.navbar-secondary .navbar-input::placeholder {
  color: rgba(255, 255, 255, 0.8);
}

.navbar-secondary .navbar-input::-moz-placeholder {
  color: #fff;
}

.navbar-teal {
  background-color: #017878;
}

.navbar-teal .navbar-toggler:hover, .navbar-teal .navbar-toggler:focus {
  background-color: rgba(255, 255, 255, 0.1);
}

.navbar-teal .navbar-toggler[aria-expanded="true"] {
  background-color: rgba(0, 0, 0, 0.1);
}

.navbar-teal .navbar-nav > .nav > .nav-item > .nav-link:not(.disabled) {
  color: rgba(255, 255, 255, 0.95);
  background-color: transparent;
}

.navbar-teal .navbar-nav > .nav > .nav-item:hover > .nav-link:not(.disabled) {
  background-color: rgba(255, 255, 255, 0.125);
}

.navbar-teal .navbar-nav > .nav > .nav-item > .nav-link:active:not(.disabled) {
  background-color: rgba(0, 0, 0, 0.075);
}

.navbar-teal .navbar-nav > .nav.has-active-border > .nav-item > .nav-link:not(.disabled)::before {
  background-color: rgba(255, 255, 255, 0.73);
}

.navbar-teal .navbar-nav > .nav > .nav-item.show > .nav-link:not(.disabled) {
  background-color: rgba(0, 0, 0, 0.1);
}

@media (min-width: 992px) {
  .navbar-teal .navbar-intro {
    background-color: #016d6d;
  }
  .navbar-teal .navbar-nav > .nav > .nav-item {
    border: 0px solid rgba(0, 0, 0, 0.1);
  }
  .navbar-teal .navbar-nav > .nav > .nav-item {
    border-width: 0 0 0 1px;
  }
  .navbar-teal .navbar-nav > .nav > .nav-item:last-of-type {
    border-width: 0 1px;
  }
  .navbar-teal .navbar-nav > .nav > .nav-item.show,
  .navbar-teal .navbar-nav > .nav > .nav-item.show + .nav-item {
    border-color: rgba(0, 0, 0, 0.2);
  }
  .navbar-teal .navbar-nav > .nav > .nav-item > .nav-link::after {
    content: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-teal .navbar-input {
    border-bottom-color: rgba(255, 255, 255, 0.7);
  }
  .navbar-teal .navbar-collapse {
    border: 0px solid rgba(34, 85, 117, 0.2);
    border-width: 1px 0;
  }
  .navbar-teal .navbar-collapse > .navbar-nav > .nav > .nav-item {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .navbar-teal .navbar-collapse > .navbar-nav > .nav > .nav-item.dropdown.show {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-teal .navbar-collapse > .navbar-nav > .nav > .nav-item > .nav-link.active {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-teal .navbar-collapse > .navbar-nav > .nav > .nav-item.show > .nav-link {
    background-color: transparent;
  }
}

@media (min-width: 992px) {
  .navbar-teal .navbar-fixed > .navbar-inner {
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1), inset 1px -1px 0px 0px rgba(0, 0, 0, 0.05);
  }
  .navbar-teal .navbar-fixed.container {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  }
  .navbar-teal .navbar-fixed.container .navbar-inner {
    left: auto;
    right: auto;
  }
}

.navbar-teal .navbar-input {
  color: #fff;
  background-color: transparent;
  border-width: 0 0 1px;
  border-style: solid;
  border-color: transparent;
}

.navbar-teal .navbar-input:focus {
  border-color: rgba(255, 255, 255, 0.7);
}

.navbar-teal .navbar-input:not(:focus):hover {
  border-color: rgba(255, 255, 255, 0.7);
}

.navbar-teal .navbar-input::placeholder {
  color: rgba(255, 255, 255, 0.8);
}

.navbar-teal .navbar-input::-moz-placeholder {
  color: #fff;
}

.navbar-darkblue {
  background-color: #1166a6;
}

.navbar-darkblue .navbar-toggler:hover, .navbar-darkblue .navbar-toggler:focus {
  background-color: rgba(255, 255, 255, 0.1);
}

.navbar-darkblue .navbar-toggler[aria-expanded="true"] {
  background-color: rgba(0, 0, 0, 0.1);
}

.navbar-darkblue .navbar-nav > .nav > .nav-item > .nav-link:not(.disabled) {
  color: rgba(255, 255, 255, 0.95);
  background-color: transparent;
}

.navbar-darkblue .navbar-nav > .nav > .nav-item:hover > .nav-link:not(.disabled) {
  background-color: rgba(255, 255, 255, 0.125);
}

.navbar-darkblue .navbar-nav > .nav > .nav-item > .nav-link:active:not(.disabled) {
  background-color: rgba(0, 0, 0, 0.075);
}

.navbar-darkblue .navbar-nav > .nav.has-active-border > .nav-item > .nav-link:not(.disabled)::before {
  background-color: rgba(255, 255, 255, 0.73);
}

.navbar-darkblue .navbar-nav > .nav > .nav-item.show > .nav-link:not(.disabled) {
  background-color: rgba(0, 0, 0, 0.1);
}

@media (min-width: 992px) {
  .navbar-darkblue .navbar-intro {
    background-color: #0f5d97;
  }
  .navbar-darkblue .navbar-nav > .nav > .nav-item {
    border: 0px solid rgba(0, 0, 0, 0.1);
  }
  .navbar-darkblue .navbar-nav > .nav > .nav-item {
    border-width: 0 0 0 1px;
  }
  .navbar-darkblue .navbar-nav > .nav > .nav-item:last-of-type {
    border-width: 0 1px;
  }
  .navbar-darkblue .navbar-nav > .nav > .nav-item.show,
  .navbar-darkblue .navbar-nav > .nav > .nav-item.show + .nav-item {
    border-color: rgba(0, 0, 0, 0.2);
  }
  .navbar-darkblue .navbar-nav > .nav > .nav-item > .nav-link::after {
    content: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-darkblue .navbar-input {
    border-bottom-color: rgba(255, 255, 255, 0.7);
  }
  .navbar-darkblue .navbar-collapse {
    border: 0px solid rgba(34, 85, 117, 0.2);
    border-width: 1px 0;
  }
  .navbar-darkblue .navbar-collapse > .navbar-nav > .nav > .nav-item {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .navbar-darkblue .navbar-collapse > .navbar-nav > .nav > .nav-item.dropdown.show {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-darkblue .navbar-collapse > .navbar-nav > .nav > .nav-item > .nav-link.active {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-darkblue .navbar-collapse > .navbar-nav > .nav > .nav-item.show > .nav-link {
    background-color: transparent;
  }
}

@media (min-width: 992px) {
  .navbar-darkblue .navbar-fixed > .navbar-inner {
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1), inset 1px -1px 0px 0px rgba(0, 0, 0, 0.05);
  }
  .navbar-darkblue .navbar-fixed.container {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  }
  .navbar-darkblue .navbar-fixed.container .navbar-inner {
    left: auto;
    right: auto;
  }
}

.navbar-darkblue .navbar-input {
  color: #fff;
  background-color: transparent;
  border-width: 0 0 1px;
  border-style: solid;
  border-color: transparent;
}

.navbar-darkblue .navbar-input:focus {
  border-color: rgba(255, 255, 255, 0.7);
}

.navbar-darkblue .navbar-input:not(:focus):hover {
  border-color: rgba(255, 255, 255, 0.7);
}

.navbar-darkblue .navbar-input::placeholder {
  color: rgba(255, 255, 255, 0.8);
}

.navbar-darkblue .navbar-input::-moz-placeholder {
  color: #fff;
}

.navbar-slategrey {
  background-color: slategrey;
}

.navbar-slategrey .navbar-toggler:hover, .navbar-slategrey .navbar-toggler:focus {
  background-color: rgba(255, 255, 255, 0.1);
}

.navbar-slategrey .navbar-toggler[aria-expanded="true"] {
  background-color: rgba(0, 0, 0, 0.1);
}

.navbar-slategrey .navbar-nav > .nav > .nav-item > .nav-link:not(.disabled) {
  color: rgba(255, 255, 255, 0.95);
  background-color: transparent;
}

.navbar-slategrey .navbar-nav > .nav > .nav-item:hover > .nav-link:not(.disabled) {
  background-color: rgba(255, 255, 255, 0.125);
}

.navbar-slategrey .navbar-nav > .nav > .nav-item > .nav-link:active:not(.disabled) {
  background-color: rgba(0, 0, 0, 0.075);
}

.navbar-slategrey .navbar-nav > .nav.has-active-border > .nav-item > .nav-link:not(.disabled)::before {
  background-color: rgba(255, 255, 255, 0.73);
}

.navbar-slategrey .navbar-nav > .nav > .nav-item.show > .nav-link:not(.disabled) {
  background-color: rgba(0, 0, 0, 0.1);
}

@media (min-width: 992px) {
  .navbar-slategrey .navbar-intro {
    background-color: #667483;
  }
  .navbar-slategrey .navbar-nav > .nav > .nav-item {
    border: 0px solid rgba(0, 0, 0, 0.1);
  }
  .navbar-slategrey .navbar-nav > .nav > .nav-item {
    border-width: 0 0 0 1px;
  }
  .navbar-slategrey .navbar-nav > .nav > .nav-item:last-of-type {
    border-width: 0 1px;
  }
  .navbar-slategrey .navbar-nav > .nav > .nav-item.show,
  .navbar-slategrey .navbar-nav > .nav > .nav-item.show + .nav-item {
    border-color: rgba(0, 0, 0, 0.2);
  }
  .navbar-slategrey .navbar-nav > .nav > .nav-item > .nav-link::after {
    content: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-slategrey .navbar-input {
    border-bottom-color: rgba(255, 255, 255, 0.7);
  }
  .navbar-slategrey .navbar-collapse {
    border: 0px solid rgba(34, 85, 117, 0.2);
    border-width: 1px 0;
  }
  .navbar-slategrey .navbar-collapse > .navbar-nav > .nav > .nav-item {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .navbar-slategrey .navbar-collapse > .navbar-nav > .nav > .nav-item.dropdown.show {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-slategrey .navbar-collapse > .navbar-nav > .nav > .nav-item > .nav-link.active {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-slategrey .navbar-collapse > .navbar-nav > .nav > .nav-item.show > .nav-link {
    background-color: transparent;
  }
}

@media (min-width: 992px) {
  .navbar-slategrey .navbar-fixed > .navbar-inner {
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1), inset 1px -1px 0px 0px rgba(0, 0, 0, 0.05);
  }
  .navbar-slategrey .navbar-fixed.container {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  }
  .navbar-slategrey .navbar-fixed.container .navbar-inner {
    left: auto;
    right: auto;
  }
}

.navbar-slategrey .navbar-input {
  color: #fff;
  background-color: transparent;
  border-width: 0 0 1px;
  border-style: solid;
  border-color: transparent;
}

.navbar-slategrey .navbar-input:focus {
  border-color: rgba(255, 255, 255, 0.7);
}

.navbar-slategrey .navbar-input:not(:focus):hover {
  border-color: rgba(255, 255, 255, 0.7);
}

.navbar-slategrey .navbar-input::placeholder {
  color: rgba(255, 255, 255, 0.8);
}

.navbar-slategrey .navbar-input::-moz-placeholder {
  color: #fff;
}

.navbar-green {
  background-color: #2b9870;
}

.navbar-green .navbar-toggler:hover, .navbar-green .navbar-toggler:focus {
  background-color: rgba(255, 255, 255, 0.1);
}

.navbar-green .navbar-toggler[aria-expanded="true"] {
  background-color: rgba(0, 0, 0, 0.1);
}

.navbar-green .navbar-nav > .nav > .nav-item > .nav-link:not(.disabled) {
  color: rgba(255, 255, 255, 0.95);
  background-color: transparent;
}

.navbar-green .navbar-nav > .nav > .nav-item:hover > .nav-link:not(.disabled) {
  background-color: rgba(255, 255, 255, 0.125);
}

.navbar-green .navbar-nav > .nav > .nav-item > .nav-link:active:not(.disabled) {
  background-color: rgba(0, 0, 0, 0.075);
}

.navbar-green .navbar-nav > .nav.has-active-border > .nav-item > .nav-link:not(.disabled)::before {
  background-color: rgba(255, 255, 255, 0.73);
}

.navbar-green .navbar-nav > .nav > .nav-item.show > .nav-link:not(.disabled) {
  background-color: rgba(0, 0, 0, 0.1);
}

@media (min-width: 992px) {
  .navbar-green .navbar-intro {
    background-color: #278a66;
  }
  .navbar-green .navbar-nav > .nav > .nav-item {
    border: 0px solid rgba(0, 0, 0, 0.1);
  }
  .navbar-green .navbar-nav > .nav > .nav-item {
    border-width: 0 0 0 1px;
  }
  .navbar-green .navbar-nav > .nav > .nav-item:last-of-type {
    border-width: 0 1px;
  }
  .navbar-green .navbar-nav > .nav > .nav-item.show,
  .navbar-green .navbar-nav > .nav > .nav-item.show + .nav-item {
    border-color: rgba(0, 0, 0, 0.2);
  }
  .navbar-green .navbar-nav > .nav > .nav-item > .nav-link::after {
    content: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-green .navbar-input {
    border-bottom-color: rgba(255, 255, 255, 0.7);
  }
  .navbar-green .navbar-collapse {
    border: 0px solid rgba(34, 85, 117, 0.2);
    border-width: 1px 0;
  }
  .navbar-green .navbar-collapse > .navbar-nav > .nav > .nav-item {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .navbar-green .navbar-collapse > .navbar-nav > .nav > .nav-item.dropdown.show {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-green .navbar-collapse > .navbar-nav > .nav > .nav-item > .nav-link.active {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-green .navbar-collapse > .navbar-nav > .nav > .nav-item.show > .nav-link {
    background-color: transparent;
  }
}

@media (min-width: 992px) {
  .navbar-green .navbar-fixed > .navbar-inner {
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1), inset 1px -1px 0px 0px rgba(0, 0, 0, 0.05);
  }
  .navbar-green .navbar-fixed.container {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  }
  .navbar-green .navbar-fixed.container .navbar-inner {
    left: auto;
    right: auto;
  }
}

.navbar-green .navbar-input {
  color: #fff;
  background-color: transparent;
  border-width: 0 0 1px;
  border-style: solid;
  border-color: transparent;
}

.navbar-green .navbar-input:focus {
  border-color: rgba(255, 255, 255, 0.7);
}

.navbar-green .navbar-input:not(:focus):hover {
  border-color: rgba(255, 255, 255, 0.7);
}

.navbar-green .navbar-input::placeholder {
  color: rgba(255, 255, 255, 0.8);
}

.navbar-green .navbar-input::-moz-placeholder {
  color: #fff;
}

.navbar-cadetblue {
  background-color: #4f9193;
}

.navbar-cadetblue .navbar-toggler:hover, .navbar-cadetblue .navbar-toggler:focus {
  background-color: rgba(255, 255, 255, 0.1);
}

.navbar-cadetblue .navbar-toggler[aria-expanded="true"] {
  background-color: rgba(0, 0, 0, 0.1);
}

.navbar-cadetblue .navbar-nav > .nav > .nav-item > .nav-link:not(.disabled) {
  color: rgba(255, 255, 255, 0.95);
  background-color: transparent;
}

.navbar-cadetblue .navbar-nav > .nav > .nav-item:hover > .nav-link:not(.disabled) {
  background-color: rgba(255, 255, 255, 0.125);
}

.navbar-cadetblue .navbar-nav > .nav > .nav-item > .nav-link:active:not(.disabled) {
  background-color: rgba(0, 0, 0, 0.075);
}

.navbar-cadetblue .navbar-nav > .nav.has-active-border > .nav-item > .nav-link:not(.disabled)::before {
  background-color: rgba(255, 255, 255, 0.73);
}

.navbar-cadetblue .navbar-nav > .nav > .nav-item.show > .nav-link:not(.disabled) {
  background-color: rgba(0, 0, 0, 0.1);
}

@media (min-width: 992px) {
  .navbar-cadetblue .navbar-intro {
    background-color: #488486;
  }
  .navbar-cadetblue .navbar-nav > .nav > .nav-item {
    border: 0px solid rgba(0, 0, 0, 0.1);
  }
  .navbar-cadetblue .navbar-nav > .nav > .nav-item {
    border-width: 0 0 0 1px;
  }
  .navbar-cadetblue .navbar-nav > .nav > .nav-item:last-of-type {
    border-width: 0 1px;
  }
  .navbar-cadetblue .navbar-nav > .nav > .nav-item.show,
  .navbar-cadetblue .navbar-nav > .nav > .nav-item.show + .nav-item {
    border-color: rgba(0, 0, 0, 0.2);
  }
  .navbar-cadetblue .navbar-nav > .nav > .nav-item > .nav-link::after {
    content: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-cadetblue .navbar-input {
    border-bottom-color: rgba(255, 255, 255, 0.7);
  }
  .navbar-cadetblue .navbar-collapse {
    border: 0px solid rgba(34, 85, 117, 0.2);
    border-width: 1px 0;
  }
  .navbar-cadetblue .navbar-collapse > .navbar-nav > .nav > .nav-item {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .navbar-cadetblue .navbar-collapse > .navbar-nav > .nav > .nav-item.dropdown.show {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-cadetblue .navbar-collapse > .navbar-nav > .nav > .nav-item > .nav-link.active {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-cadetblue .navbar-collapse > .navbar-nav > .nav > .nav-item.show > .nav-link {
    background-color: transparent;
  }
}

@media (min-width: 992px) {
  .navbar-cadetblue .navbar-fixed > .navbar-inner {
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1), inset 1px -1px 0px 0px rgba(0, 0, 0, 0.05);
  }
  .navbar-cadetblue .navbar-fixed.container {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  }
  .navbar-cadetblue .navbar-fixed.container .navbar-inner {
    left: auto;
    right: auto;
  }
}

.navbar-cadetblue .navbar-input {
  color: #fff;
  background-color: transparent;
  border-width: 0 0 1px;
  border-style: solid;
  border-color: transparent;
}

.navbar-cadetblue .navbar-input:focus {
  border-color: rgba(255, 255, 255, 0.7);
}

.navbar-cadetblue .navbar-input:not(:focus):hover {
  border-color: rgba(255, 255, 255, 0.7);
}

.navbar-cadetblue .navbar-input::placeholder {
  color: rgba(255, 255, 255, 0.8);
}

.navbar-cadetblue .navbar-input::-moz-placeholder {
  color: #fff;
}

.navbar-darkgreen {
  background-color: #5b8c5b;
}

.navbar-darkgreen .navbar-toggler:hover, .navbar-darkgreen .navbar-toggler:focus {
  background-color: rgba(255, 255, 255, 0.1);
}

.navbar-darkgreen .navbar-toggler[aria-expanded="true"] {
  background-color: rgba(0, 0, 0, 0.1);
}

.navbar-darkgreen .navbar-nav > .nav > .nav-item > .nav-link:not(.disabled) {
  color: rgba(255, 255, 255, 0.95);
  background-color: transparent;
}

.navbar-darkgreen .navbar-nav > .nav > .nav-item:hover > .nav-link:not(.disabled) {
  background-color: rgba(255, 255, 255, 0.125);
}

.navbar-darkgreen .navbar-nav > .nav > .nav-item > .nav-link:active:not(.disabled) {
  background-color: rgba(0, 0, 0, 0.075);
}

.navbar-darkgreen .navbar-nav > .nav.has-active-border > .nav-item > .nav-link:not(.disabled)::before {
  background-color: rgba(255, 255, 255, 0.73);
}

.navbar-darkgreen .navbar-nav > .nav > .nav-item.show > .nav-link:not(.disabled) {
  background-color: rgba(0, 0, 0, 0.1);
}

@media (min-width: 992px) {
  .navbar-darkgreen .navbar-intro {
    background-color: #537f53;
  }
  .navbar-darkgreen .navbar-nav > .nav > .nav-item {
    border: 0px solid rgba(0, 0, 0, 0.1);
  }
  .navbar-darkgreen .navbar-nav > .nav > .nav-item {
    border-width: 0 0 0 1px;
  }
  .navbar-darkgreen .navbar-nav > .nav > .nav-item:last-of-type {
    border-width: 0 1px;
  }
  .navbar-darkgreen .navbar-nav > .nav > .nav-item.show,
  .navbar-darkgreen .navbar-nav > .nav > .nav-item.show + .nav-item {
    border-color: rgba(0, 0, 0, 0.2);
  }
  .navbar-darkgreen .navbar-nav > .nav > .nav-item > .nav-link::after {
    content: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-darkgreen .navbar-input {
    border-bottom-color: rgba(255, 255, 255, 0.7);
  }
  .navbar-darkgreen .navbar-collapse {
    border: 0px solid rgba(34, 85, 117, 0.2);
    border-width: 1px 0;
  }
  .navbar-darkgreen .navbar-collapse > .navbar-nav > .nav > .nav-item {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .navbar-darkgreen .navbar-collapse > .navbar-nav > .nav > .nav-item.dropdown.show {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-darkgreen .navbar-collapse > .navbar-nav > .nav > .nav-item > .nav-link.active {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-darkgreen .navbar-collapse > .navbar-nav > .nav > .nav-item.show > .nav-link {
    background-color: transparent;
  }
}

@media (min-width: 992px) {
  .navbar-darkgreen .navbar-fixed > .navbar-inner {
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1), inset 1px -1px 0px 0px rgba(0, 0, 0, 0.05);
  }
  .navbar-darkgreen .navbar-fixed.container {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  }
  .navbar-darkgreen .navbar-fixed.container .navbar-inner {
    left: auto;
    right: auto;
  }
}

.navbar-darkgreen .navbar-input {
  color: #fff;
  background-color: transparent;
  border-width: 0 0 1px;
  border-style: solid;
  border-color: transparent;
}

.navbar-darkgreen .navbar-input:focus {
  border-color: rgba(255, 255, 255, 0.7);
}

.navbar-darkgreen .navbar-input:not(:focus):hover {
  border-color: rgba(255, 255, 255, 0.7);
}

.navbar-darkgreen .navbar-input::placeholder {
  color: rgba(255, 255, 255, 0.8);
}

.navbar-darkgreen .navbar-input::-moz-placeholder {
  color: #fff;
}

.navbar-orange {
  background-color: #d0812c;
}

.navbar-orange .navbar-toggler:hover, .navbar-orange .navbar-toggler:focus {
  background-color: rgba(255, 255, 255, 0.1);
}

.navbar-orange .navbar-toggler[aria-expanded="true"] {
  background-color: rgba(0, 0, 0, 0.1);
}

.navbar-orange .navbar-nav > .nav > .nav-item > .nav-link:not(.disabled) {
  color: rgba(255, 255, 255, 0.95);
  background-color: transparent;
}

.navbar-orange .navbar-nav > .nav > .nav-item:hover > .nav-link:not(.disabled) {
  background-color: rgba(255, 255, 255, 0.125);
}

.navbar-orange .navbar-nav > .nav > .nav-item > .nav-link:active:not(.disabled) {
  background-color: rgba(0, 0, 0, 0.075);
}

.navbar-orange .navbar-nav > .nav.has-active-border > .nav-item > .nav-link:not(.disabled)::before {
  background-color: rgba(255, 255, 255, 0.73);
}

.navbar-orange .navbar-nav > .nav > .nav-item.show > .nav-link:not(.disabled) {
  background-color: rgba(0, 0, 0, 0.1);
}

@media (min-width: 992px) {
  .navbar-orange .navbar-intro {
    background-color: #bd7528;
  }
  .navbar-orange .navbar-nav > .nav > .nav-item {
    border: 0px solid rgba(0, 0, 0, 0.1);
  }
  .navbar-orange .navbar-nav > .nav > .nav-item {
    border-width: 0 0 0 1px;
  }
  .navbar-orange .navbar-nav > .nav > .nav-item:last-of-type {
    border-width: 0 1px;
  }
  .navbar-orange .navbar-nav > .nav > .nav-item.show,
  .navbar-orange .navbar-nav > .nav > .nav-item.show + .nav-item {
    border-color: rgba(0, 0, 0, 0.2);
  }
  .navbar-orange .navbar-nav > .nav > .nav-item > .nav-link::after {
    content: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-orange .navbar-input {
    border-bottom-color: rgba(255, 255, 255, 0.7);
  }
  .navbar-orange .navbar-collapse {
    border: 0px solid rgba(34, 85, 117, 0.2);
    border-width: 1px 0;
  }
  .navbar-orange .navbar-collapse > .navbar-nav > .nav > .nav-item {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .navbar-orange .navbar-collapse > .navbar-nav > .nav > .nav-item.dropdown.show {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-orange .navbar-collapse > .navbar-nav > .nav > .nav-item > .nav-link.active {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-orange .navbar-collapse > .navbar-nav > .nav > .nav-item.show > .nav-link {
    background-color: transparent;
  }
}

@media (min-width: 992px) {
  .navbar-orange .navbar-fixed > .navbar-inner {
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1), inset 1px -1px 0px 0px rgba(0, 0, 0, 0.05);
  }
  .navbar-orange .navbar-fixed.container {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  }
  .navbar-orange .navbar-fixed.container .navbar-inner {
    left: auto;
    right: auto;
  }
}

.navbar-orange .navbar-input {
  color: #fff;
  background-color: transparent;
  border-width: 0 0 1px;
  border-style: solid;
  border-color: transparent;
}

.navbar-orange .navbar-input:focus {
  border-color: rgba(255, 255, 255, 0.7);
}

.navbar-orange .navbar-input:not(:focus):hover {
  border-color: rgba(255, 255, 255, 0.7);
}

.navbar-orange .navbar-input::placeholder {
  color: rgba(255, 255, 255, 0.8);
}

.navbar-orange .navbar-input::-moz-placeholder {
  color: #fff;
}

.navbar-brown {
  background-color: #a97047;
}

.navbar-brown .navbar-toggler:hover, .navbar-brown .navbar-toggler:focus {
  background-color: rgba(255, 255, 255, 0.1);
}

.navbar-brown .navbar-toggler[aria-expanded="true"] {
  background-color: rgba(0, 0, 0, 0.1);
}

.navbar-brown .navbar-nav > .nav > .nav-item > .nav-link:not(.disabled) {
  color: #fff;
  background-color: transparent;
}

.navbar-brown .navbar-nav > .nav > .nav-item:hover > .nav-link:not(.disabled) {
  background-color: rgba(0, 0, 0, 0.12);
}

.navbar-brown .navbar-nav > .nav > .nav-item > .nav-link:active:not(.disabled) {
  background-color: rgba(0, 0, 0, 0.075);
}

.navbar-brown .navbar-nav > .nav.has-active-border > .nav-item > .nav-link:not(.disabled)::before {
  background-color: rgba(255, 255, 255, 0.73);
}

.navbar-brown .navbar-nav > .nav > .nav-item.show > .nav-link:not(.disabled) {
  background-color: rgba(0, 0, 0, 0.1);
}

@media (min-width: 992px) {
  .navbar-brown .navbar-intro {
    background-color: #9a6641;
  }
  .navbar-brown .navbar-nav > .nav > .nav-item {
    border: 0px solid rgba(0, 0, 0, 0.1);
  }
  .navbar-brown .navbar-nav > .nav > .nav-item {
    border-width: 0 0 0 1px;
  }
  .navbar-brown .navbar-nav > .nav > .nav-item:last-of-type {
    border-width: 0 1px;
  }
  .navbar-brown .navbar-nav > .nav > .nav-item.show,
  .navbar-brown .navbar-nav > .nav > .nav-item.show + .nav-item {
    border-color: rgba(0, 0, 0, 0.2);
  }
  .navbar-brown .navbar-nav > .nav > .nav-item > .nav-link::after {
    content: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-brown .navbar-input {
    border-bottom-color: rgba(255, 255, 255, 0.7);
  }
  .navbar-brown .navbar-collapse {
    border: 0px solid rgba(34, 85, 117, 0.2);
    border-width: 1px 0;
  }
  .navbar-brown .navbar-collapse > .navbar-nav > .nav > .nav-item {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  .navbar-brown .navbar-collapse > .navbar-nav > .nav > .nav-item.dropdown.show {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-brown .navbar-collapse > .navbar-nav > .nav > .nav-item > .nav-link.active {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-brown .navbar-collapse > .navbar-nav > .nav > .nav-item.show > .nav-link {
    background-color: transparent;
  }
}

@media (min-width: 992px) {
  .navbar-brown .navbar-fixed > .navbar-inner {
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1), inset 1px -1px 0px 0px rgba(0, 0, 0, 0.05);
  }
  .navbar-brown .navbar-fixed.container {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  }
  .navbar-brown .navbar-fixed.container .navbar-inner {
    left: auto;
    right: auto;
  }
}

.navbar-brown .navbar-input {
  color: #fff;
  background-color: transparent;
  border-width: 0 0 1px;
  border-style: solid;
  border-color: transparent;
}

.navbar-brown .navbar-input:focus {
  border-color: rgba(255, 255, 255, 0.7);
}

.navbar-brown .navbar-input:not(:focus):hover {
  border-color: rgba(255, 255, 255, 0.7);
}

.navbar-brown .navbar-input::placeholder {
  color: rgba(255, 255, 255, 0.8);
}

.navbar-brown .navbar-input::-moz-placeholder {
  color: #fff;
}

.navbar-skyblue {
  background-color: #4aa0df;
}

.navbar-skyblue .navbar-toggler:hover, .navbar-skyblue .navbar-toggler:focus {
  background-color: rgba(255, 255, 255, 0.1);
}

.navbar-skyblue .navbar-toggler[aria-expanded="true"] {
  background-color: rgba(0, 0, 0, 0.1);
}

.navbar-skyblue .navbar-nav > .nav > .nav-item > .nav-link:not(.disabled) {
  color: #fff;
  background-color: transparent;
}

.navbar-skyblue .navbar-nav > .nav > .nav-item:hover > .nav-link:not(.disabled) {
  color: rgba(255, 255, 255, 0.87);
  background-color: rgba(0, 0, 0, 0.125);
}

.navbar-skyblue .navbar-nav > .nav > .nav-item > .nav-link:active:not(.disabled) {
  color: rgba(255, 255, 255, 0.93);
  background-color: rgba(0, 0, 0, 0.15);
}

.navbar-skyblue .navbar-nav > .nav.has-active-border > .nav-item > .nav-link:not(.disabled)::before {
  background-color: rgba(255, 255, 255, 0.73);
}

.navbar-skyblue .navbar-nav > .nav > .nav-item.show > .nav-link:not(.disabled) {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.15);
}

@media (min-width: 992px) {
  .navbar-skyblue .navbar-intro {
    background-color: #3b91d1;
  }
  .navbar-skyblue .navbar-nav > .nav > .nav-item {
    border: 0px solid rgba(0, 0, 0, 0.1);
  }
  .navbar-skyblue .navbar-nav > .nav > .nav-item {
    border-width: 0 0 0 1px;
  }
  .navbar-skyblue .navbar-nav > .nav > .nav-item:last-of-type {
    border-width: 0 1px;
  }
  .navbar-skyblue .navbar-nav > .nav > .nav-item.show,
  .navbar-skyblue .navbar-nav > .nav > .nav-item.show + .nav-item {
    border-color: rgba(0, 0, 0, 0.2);
  }
  .navbar-skyblue .navbar-nav > .nav > .nav-item > .nav-link::after {
    content: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-skyblue .navbar-input {
    border-bottom-color: rgba(255, 255, 255, 0.4);
  }
  .navbar-skyblue .navbar-collapse {
    border: 0px solid rgba(34, 85, 117, 0.2);
    border-width: 1px 0;
  }
  .navbar-skyblue .navbar-collapse > .navbar-nav > .nav > .nav-item {
    border-bottom: 1px solid rgba(255, 255, 255, 0.22);
  }
  .navbar-skyblue .navbar-collapse > .navbar-nav > .nav > .nav-item.dropdown.show {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-skyblue .navbar-collapse > .navbar-nav > .nav > .nav-item > .nav-link.active {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .navbar-skyblue .navbar-collapse > .navbar-nav > .nav > .nav-item.show > .nav-link {
    background-color: transparent;
  }
}

@media (min-width: 992px) {
  .navbar-skyblue .navbar-fixed > .navbar-inner {
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1), inset 1px -1px 0px 0px rgba(0, 0, 0, 0.05);
  }
  .navbar-skyblue .navbar-fixed.container {
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  }
  .navbar-skyblue .navbar-fixed.container .navbar-inner {
    left: auto;
    right: auto;
  }
}

.navbar-skyblue .navbar-input {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
}

.navbar-skyblue .navbar-input:focus {
  border-color: rgba(255, 255, 255, 0.4);
  background-color: rgba(255, 255, 255, 0.05);
}

.navbar-skyblue .navbar-input:not(:focus):hover {
  border-color: rgba(255, 255, 255, 0.25);
}

.navbar-skyblue .navbar-input::placeholder {
  color: rgba(255, 255, 255, 0.8);
}

.navbar-skyblue .navbar-input::-moz-placeholder {
  color: #fff;
}

.sidebar-light {
  background-color: #f1f3f6;
  --active-border-offset-right: 0px;
}

.sidebar-light .sidebar-inner {
  background: inherit;
  box-shadow: inset -1px 0 0 0 #e2e5e8;
}

.sidebar-light.toggling.has-open:not(.collapsed) .sidebar-inner {
  border-right: 1px solid #e2e5e8;
}

@media (min-width: 1200px) {
  .sidebar-light:not(.sidebar-h) .nav {
    position: relative;
    left: -1px;
  }
  .sidebar-light:not(.sidebar-h) .nav > .nav-item.active:not(.open) > .nav-link,
  .sidebar-light.collapsed:not(.sidebar-h) .sidebar-inner:not(.is-hover) .nav.active-on-right > .nav-item.active > .nav-link {
    left: 1px;
  }
  .sidebar-light:not(.sidebar-h) .nav.has-active-border > .nav-item.open > .nav-link::before,
  .sidebar-light:not(.sidebar-h) .nav.has-active-border .submenu .nav-link::before {
    margin-left: 1px;
  }
  .sidebar-light.hoverable.collapsed:not(.sidebar-h) .nav {
    left: auto;
  }
  .sidebar-light.hoverable.collapsed:not(.sidebar-h) .nav .nav-item > .nav-link {
    left: auto;
  }
  .sidebar-light.hoverable.collapsed:not(.sidebar-h) .nav.has-active-border > .nav-item > .nav-link::before,
  .sidebar-light.hoverable.collapsed:not(.sidebar-h) .nav.has-active-border .submenu .nav-link::before {
    margin-left: auto;
  }
}

@media (min-width: 1200px) {
  .sidebar-light.sidebar-h .nav > .nav-item.active > .nav-link {
    top: 1px;
  }
  .sidebar-light.sidebar-h .nav.active-on-right > .nav-item::before, .sidebar-light.sidebar-h .nav.active-on-right > .nav-item > .nav-link::before {
    bottom: auto !important;
    top: 0 !important;
  }
  .sidebar-light.sidebar-h .align-items-xl-end .nav.active-on-right > .nav-item::before, .sidebar-light.sidebar-h .align-items-xl-end .nav.active-on-right > .nav-item > .nav-link::before {
    top: -1px !important;
  }
}

@media (max-width: 1199.98px) {
  .sidebar-light.sidebar-push .nav {
    position: relative;
    left: -1px;
  }
  .sidebar-light.sidebar-push .nav > .nav-item.active:not(.open) > .nav-link {
    left: 1px;
  }
  .sidebar-light.sidebar-push .nav.has-active-border > .nav-item.open > .nav-link::before,
  .sidebar-light.sidebar-push .nav.has-active-border .submenu .nav-link::before {
    margin-left: 1px;
  }
}

.sidebar-light .nav.active-on-right .nav-item.active .nav-link::before {
  right: 0px;
}

@media (max-width: 1199.98px) {
  .sidebar-light:not(.sidebar-push) .sidebar-inner {
    box-shadow: none;
    border-right: 1px solid #d7dbdf;
  }
  .sidebar-light:not(.sidebar-fixed) .sidebar-inner {
    border-bottom: 1px solid #d7dbdf;
  }
}

.sidebar-light .nav > .nav-item {
  margin-bottom: 1px;
}

.sidebar-light .nav > .nav-item > .nav-link {
  color: #4e4e4e;
  font-size: 0.975rem;
}

.sidebar-light .nav > .nav-item > .nav-link > .nav-icon {
  color: #5c5c5c;
  font-size: 1.2em;
}

.sidebar-light .nav > .nav-item.open > .nav-link {
  color: #313131;
}

.sidebar-light.sidebar-spaced .nav > .nav-item.open:not(.active) > .nav-link {
  background-color: #e2e5ea;
}

.sidebar-light .nav > .nav-item.open > .nav-link > .nav-icon {
  color: #4a4a4a;
}

.sidebar-light .nav > .nav-item > .nav-link:hover {
  background-color: #e2e5ea;
  color: #2e2f30;
}

.sidebar-light .nav > .nav-item > .nav-link:hover > .nav-icon {
  color: #454647;
}

.sidebar-light .nav > .nav-item > .submenu {
  background-color: #fff;
}

.sidebar-light .submenu .nav-link {
  color: #616161;
  font-size: 0.95rem;
}

.sidebar-light .submenu .nav-item.open > .nav-link {
  color: #1f74b6;
}

.sidebar-light .submenu .nav-item > .nav-link:hover {
  color: #126cae;
  background-color: #e8f1f9;
}

.sidebar-light .submenu .nav-icon {
  color: #99a0a8;
  font-size: 1.1875rem;
}

.sidebar-light .submenu .nav-link:hover > .nav-icon {
  color: #92afd0;
}

.sidebar-light .submenu .nav-item.open > .nav-link > .nav-icon {
  color: #92afd0;
}

.sidebar-light .submenu .nav-item.active > .nav-link > .nav-icon {
  color: #70a1d1;
}

.sidebar-light .caret {
  font-size: 0.925em;
  color: #91979f;
}

.sidebar-light .open > .nav-link > .caret {
  color: #6b6b6c;
}

.sidebar-light .nav-item > .nav-link:hover > .caret {
  color: #6b6b6c;
}

.sidebar-light .nav-item.active > .nav-link > .caret {
  color: inherit;
  opacity: 0.82;
}

.sidebar-light .nav-item.active.open > .nav-link > .caret {
  color: inherit;
  opacity: 0.92;
}

.sidebar-light .nav > .nav-item.active {
  border: 1px solid #e1e4e7;
  border-width: 1px 0;
}

@media (min-width: 1200px) {
  .sidebar-light.sidebar-h .nav > .nav-item.active {
    border-width: 0 1px;
  }
}

.sidebar-light .nav > .nav-item.active > .nav-link {
  color: #2f73b2;
  background-color: #fff;
  font-weight: 600;
}

.sidebar-light .nav > .nav-item.active > .nav-link > .nav-icon {
  color: #427fb8;
}

.sidebar-light .submenu .nav-item.active > .nav-link {
  color: #1b6eae;
}

.sidebar-light .submenu .nav-item.active > .nav-link > .nav-icon {
  color: #70a1d1;
}

.sidebar-light .submenu .nav-item.active:not(.open) > .nav-link {
  background-color: #e5eff7;
  font-weight: 600;
}

@media (min-width: 1200px) {
  .sidebar-light.hoverable.collapsed .submenu .nav-item.active.open > .nav-link, .sidebar-light.sidebar-hover:not(.collapsed) .submenu .nav-item.active.open > .nav-link {
    color: #1b6eae;
    background-color: #e5eff7;
    font-weight: 600;
  }
  .sidebar-light.hoverable.collapsed .submenu .nav-item.active > .nav-link > .nav-text::before, .sidebar-light.sidebar-hover:not(.collapsed) .submenu .nav-item.active > .nav-link > .nav-text::before {
    content: "";
    display: block;
    opacity: 1;
  }
}

.sidebar-light .nav.has-active-border .nav-item.active > .nav-link::before {
  content: "";
  border-color: #4b95ce;
  top: -1px;
  bottom: -1px;
}

.sidebar-light .submenu .nav-link {
  padding-left: 4em;
}

.sidebar-light .submenu .submenu .nav-link {
  padding-left: 4.75em;
}

.sidebar-light .submenu .submenu .submenu .nav-link {
  padding-left: 5.375em;
}

.sidebar-light .submenu .nav-text::before {
  display: none;
  position: absolute;
  left: 3em;
  z-index: 1;
  opacity: 0.65;
  top: calc(50% - 0.1875rem);
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 0.75rem;
  background-color: currentColor;
}

.sidebar-light .submenu .submenu .nav-text::before {
  left: 3.75em;
}

.sidebar-light .submenu .submenu .submenu .nav-text::before {
  left: 4.375em;
}

.sidebar-light .submenu .nav-item > .nav-link:hover > .nav-text::before {
  content: "";
  display: block;
  opacity: 0.8;
}

.sidebar-light .submenu .nav-item.active:not(.open) > .nav-link > .nav-text::before {
  content: "";
  display: block;
  opacity: 1;
}

@media (min-width: 1200px) {
  .sidebar-light.hoverable.collapsed .submenu .nav-text::before, .sidebar-light.sidebar-hover:not(.collapsed) .submenu .nav-text::before {
    left: calc(0.5em + 1px) !important;
  }
}

.sidebar-light .nav-item-caption {
  color: #65707c;
  font-size: 0.875rem;
}

.sidebar-light .nav-item-divider {
  border-top: 1px solid #dde3e6;
}

.sidebar-light .submenu .nav-item-divider {
  border-top-color: #eceff1;
}

.sidebar-light .nav > .nav-item > .submenu > .submenu-inner {
  border: 1px solid #e1e4e7;
  border-width: 1px 0;
}

.sidebar-light .nav > .nav-item.active > .submenu > .submenu-inner {
  border-bottom-width: 0;
}

.sidebar-light .nav > .nav-item.open + .nav-item.active,
.sidebar-light .nav > .nav-item.is-toggling + .nav-item.active {
  margin-top: -2px;
}

.sidebar-light .ace-scroll {
  scrollbar-color: #9dbad5 #dce3ea;
}

.sidebar-light .ace-scroll::-webkit-scrollbar-track {
  background-color: #dce3ea;
  border-right: 1px solid #f1f3f6;
}

.sidebar-light .ace-scroll::-webkit-scrollbar-thumb {
  background-color: #9dbad5;
  border-right: 1px solid #f1f3f6;
}

.sidebar-light.sidebar-spaced .nav {
  margin-left: 0;
}

.sidebar-light.sidebar-spaced .nav > .nav-item.active:not(.open) > .nav-link {
  left: auto;
}

.sidebar-light.sidebar-spaced .nav > .nav-item.active {
  border-width: 1px;
  border-color: #d0d5d9;
}

@media (min-width: 1200px) {
  .sidebar-light.toggling .nav > .nav-item.active.open {
    transition: none;
  }
  .sidebar-light.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.open:not(.active), .sidebar-light.hoverable.collapsed .nav > .nav-item.open:not(.active) {
    border-color: transparent;
    background-color: transparent;
  }
  .sidebar-light.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.open:not(.active) > .nav-link, .sidebar-light.hoverable.collapsed .nav > .nav-item.open:not(.active) > .nav-link {
    color: #4e4e4e;
    background-color: transparent;
  }
  .sidebar-light.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.open:not(.active) > .nav-link > .nav-icon, .sidebar-light.hoverable.collapsed .nav > .nav-item.open:not(.active) > .nav-link > .nav-icon {
    color: #5c5c5c;
  }
  .sidebar-light.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.active.open, .sidebar-light.hoverable.collapsed .nav > .nav-item.active.open {
    border-width: 1px 0;
    transition: none;
  }
}

.sidebar-light .sidebar-search-input {
  color: rgba(68, 80, 92, 0.9);
  border-bottom-color: rgba(204, 204, 204, 0.4);
}

.sidebar-light .sidebar-search-input:focus {
  border-bottom-color: rgba(140, 174, 213, 0.73);
}

.sidebar-light .sidebar-search-input::placeholder {
  color: rgba(129, 131, 136, 0.5);
}

.sidebar-light .sidebar-search-input::-moz-placeholder {
  color: #818388;
}

.sidebar-white {
  background-color: #fff;
  --active-border-offset-right: 2px;
}

.sidebar-white .sidebar-inner {
  border-right: 1px solid #dee6ee;
  background: inherit;
}

.sidebar-white .nav.active-on-right .nav-item.active .nav-link::before {
  right: 2px;
}

@media (max-width: 1199.98px) {
  .sidebar-white:not(.sidebar-fixed) .sidebar-inner {
    border-bottom: 1px solid #dee6ee;
  }
}

.sidebar-white .nav > .nav-item {
  margin-bottom: 2px;
}

.sidebar-white .nav > .nav-item > .nav-link {
  color: #677892;
  font-size: 0.925rem;
  font-weight: 600;
}

.sidebar-white .nav > .nav-item > .nav-link > .nav-icon {
  color: #8593a8;
  font-size: 1.25em;
}

.sidebar-white .nav > .nav-item.open > .nav-link {
  color: #3179b9;
  background-color: transparent;
}

.sidebar-white.sidebar-spaced .nav > .nav-item.open:not(.active) > .nav-link {
  background-color: #e5f0fa;
}

.sidebar-white .nav > .nav-item.open > .nav-link > .nav-icon {
  color: #357bbc;
}

.sidebar-white .nav > .nav-item > .nav-link:hover {
  background-color: #e5f0fa;
  color: #1d6bb3;
}

.sidebar-white .nav > .nav-item > .nav-link:hover > .nav-icon {
  color: #498ac4;
}

.sidebar-white .nav > .nav-item > .submenu {
  background-color: #fff;
}

.sidebar-white .submenu .nav-link {
  color: #5d6c7e;
  font-size: 0.9125rem;
}

.sidebar-white .submenu .nav-item.open > .nav-link {
  color: #3e7ab1;
}

.sidebar-white .submenu .nav-item > .nav-link:hover {
  color: #1d6bb3;
  background-color: #e5f0fa;
}

.sidebar-white .submenu .nav-icon {
  color: #99a0a8;
  font-size: 1.1875rem;
}

.sidebar-white .submenu .nav-link:hover > .nav-icon {
  color: #92afd0;
}

.sidebar-white .submenu .nav-item.open > .nav-link > .nav-icon {
  color: #92afd0;
}

.sidebar-white .submenu .nav-item.active > .nav-link > .nav-icon {
  color: #70a1d1;
}

.sidebar-white .caret {
  font-size: 0.925em;
  color: #91979f;
}

.sidebar-white .open > .nav-link > .caret {
  color: #599bce;
}

.sidebar-white .nav-item > .nav-link:hover > .caret {
  color: inherit;
  opacity: 0.82;
}

.sidebar-white .nav-item.active > .nav-link > .caret {
  color: inherit;
  opacity: 0.82;
}

.sidebar-white .nav-item.active.open > .nav-link > .caret {
  color: inherit;
  opacity: 0.92;
}

@media (min-width: 1200px) {
  .sidebar-white.sidebar-h .nav > .nav-item.active {
    border-width: 0 1px;
  }
}

.sidebar-white .nav > .nav-item.active > .nav-link {
  color: #0f62a6;
  background-color: #e3eff9;
}

.sidebar-white .nav > .nav-item.active:not(.open) > .nav-link {
  font-weight: 600;
}

.sidebar-white .nav > .nav-item.active.open > .nav-link:not(:hover) {
  color: #1d6bb3;
  background-color: transparent;
}

@media (min-width: 1200px) {
  .sidebar-white.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.active.open > .nav-link {
    background-color: #e3eff9;
  }
}

.sidebar-white .nav > .nav-item.active > .nav-link > .nav-icon {
  color: #357bbc;
}

.sidebar-white .submenu .nav-item.active > .nav-link {
  color: #0f62a6;
}

.sidebar-white .submenu .nav-item.active > .nav-link > .nav-icon {
  color: #70a1d1;
}

.sidebar-white .submenu .nav-item.active:not(.open) > .nav-link {
  background-color: #e3eff9;
  font-weight: 600;
}

@media (min-width: 1200px) {
  .sidebar-white.hoverable.collapsed .submenu .nav-item.active.open > .nav-link, .sidebar-white.sidebar-hover:not(.collapsed) .submenu .nav-item.active.open > .nav-link {
    color: #0f62a6;
    background-color: #e3eff9;
    font-weight: 600;
  }
  .sidebar-white.hoverable.collapsed .submenu .nav-item.active > .nav-link > .nav-text::before, .sidebar-white.sidebar-hover:not(.collapsed) .submenu .nav-item.active > .nav-link > .nav-text::before {
    content: "";
    display: block;
    opacity: 1;
  }
}

@media (hover: hover) {
  .sidebar-white.sidebar-h .nav > .nav-item > .nav-link:hover::before {
    border-color: #63a1d8;
  }
}

.sidebar-white .nav.has-active-border .nav-item.active > .nav-link::before {
  content: "";
  border-color: #4f95d3;
}

.sidebar-white .submenu .nav-link {
  padding-left: 4em;
}

.sidebar-white .submenu .submenu .nav-link {
  padding-left: 4.75em;
}

.sidebar-white .submenu .submenu .submenu .nav-link {
  padding-left: 5.375em;
}

.sidebar-white .submenu .nav-text::before {
  display: none;
  position: absolute;
  left: 3em;
  z-index: 1;
  opacity: 0.65;
  top: calc(50% - 0.1875rem);
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 0.75rem;
  background-color: currentColor;
}

.sidebar-white .submenu .submenu .nav-text::before {
  left: 3.75em;
}

.sidebar-white .submenu .submenu .submenu .nav-text::before {
  left: 4.375em;
}

.sidebar-white .submenu .nav-item > .nav-link:hover > .nav-text::before {
  content: "";
  display: block;
  opacity: 0.8;
}

.sidebar-white .submenu .nav-item.active:not(.open) > .nav-link > .nav-text::before {
  content: "";
  display: block;
  opacity: 1;
}

@media (min-width: 1200px) {
  .sidebar-white.hoverable.collapsed .submenu .nav-text::before, .sidebar-white.sidebar-hover:not(.collapsed) .submenu .nav-text::before {
    left: calc(0.5em + 1px) !important;
  }
}

.sidebar-white .nav-item-caption {
  color: #77818e;
  font-size: 0.875rem;
}

.sidebar-white .nav-item-divider {
  border-top: 1px solid #dde3e6;
}

.sidebar-white .submenu .nav-item-divider {
  border-top-color: #eceff1;
}

.sidebar-white .ace-scroll {
  scrollbar-color: #9dbad5 #dce3ea;
}

.sidebar-white .ace-scroll::-webkit-scrollbar-track {
  background-color: #dce3ea;
  border-right: 1px solid #fff;
}

.sidebar-white .ace-scroll::-webkit-scrollbar-thumb {
  background-color: #9dbad5;
  border-right: 1px solid #fff;
}

.sidebar-white.sidebar-spaced .nav > .nav-item {
  border-radius: 0;
}

@media (min-width: 1200px) {
  .sidebar-white.sidebar-spaced.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item {
    border-radius: 0;
  }
}

@media (min-width: 1200px) {
  .sidebar-white.toggling .nav > .nav-item.active.open {
    transition: none;
  }
  .sidebar-white.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.open:not(.active), .sidebar-white.hoverable.collapsed .nav > .nav-item.open:not(.active) {
    border-color: transparent;
    background-color: transparent;
  }
  .sidebar-white.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.open:not(.active) > .nav-link, .sidebar-white.hoverable.collapsed .nav > .nav-item.open:not(.active) > .nav-link {
    color: #677892;
    background-color: transparent;
  }
  .sidebar-white.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.open:not(.active) > .nav-link > .nav-icon, .sidebar-white.hoverable.collapsed .nav > .nav-item.open:not(.active) > .nav-link > .nav-icon {
    color: #8593a8;
  }
  .sidebar-white.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.active.open, .sidebar-white.hoverable.collapsed .nav > .nav-item.active.open {
    transition: none;
  }
}

.sidebar-white .sidebar-search-input {
  color: rgba(68, 80, 92, 0.9);
  border-bottom-color: rgba(204, 204, 204, 0.4);
}

.sidebar-white .sidebar-search-input:focus {
  border-bottom-color: rgba(140, 174, 213, 0.73);
}

.sidebar-white .sidebar-search-input::placeholder {
  color: rgba(129, 131, 136, 0.5);
}

.sidebar-white .sidebar-search-input::-moz-placeholder {
  color: #818388;
}

.sidebar-white2 {
  background-color: #fff;
  --active-border-offset-right: 2px;
}

.sidebar-white2 .sidebar-inner {
  border-right: 1px solid #dee6ee;
  background: inherit;
}

.sidebar-white2 .nav.active-on-right .nav-item.active .nav-link::before {
  right: 2px;
}

@media (max-width: 1199.98px) {
  .sidebar-white2:not(.sidebar-fixed) .sidebar-inner {
    border-bottom: 1px solid #dee6ee;
  }
}

.sidebar-white2 .nav > .nav-item {
  margin-bottom: 2px;
}

.sidebar-white2 .nav > .nav-item > .nav-link {
  color: #575c5f;
  font-size: 0.9625rem;
  min-height: 3.5em;
}

.sidebar-white2 .nav > .nav-item > .nav-link > .nav-icon {
  color: #73869b;
  font-size: 1.25em;
}

.sidebar-white2 .nav > .nav-item.open > .nav-link {
  color: rgba(0, 0, 0, 0.73);
  font-weight: 600;
  background-color: transparent;
}

.sidebar-white2.sidebar-spaced .nav > .nav-item.open:not(.active) > .nav-link {
  background-color: #e5f0fa;
}

.sidebar-white2 .nav > .nav-item.open > .nav-link > .nav-icon {
  color: #357bbc;
}

.sidebar-white2 .nav > .nav-item > .nav-link:hover {
  background-color: #e5f0fa;
  color: rgba(0, 0, 0, 0.75);
}

.sidebar-white2 .nav > .nav-item > .nav-link:hover > .nav-icon {
  color: #4685bb;
}

.sidebar-white2 .nav > .nav-item > .submenu {
  background-color: #fff;
}

.sidebar-white2 .submenu .nav-link {
  color: #575c5f;
  font-size: 0.9125rem;
}

.sidebar-white2 .submenu .nav-item.open > .nav-link {
  color: #3e7ab1;
}

.sidebar-white2 .submenu .nav-item > .nav-link:hover {
  color: #2e699c;
  background-color: #e5f0fa;
}

.sidebar-white2 .submenu .nav-icon {
  color: #99a0a8;
  font-size: 1.1875rem;
}

.sidebar-white2 .submenu .nav-link:hover > .nav-icon {
  color: #92afd0;
}

.sidebar-white2 .submenu .nav-item.open > .nav-link > .nav-icon {
  color: #92afd0;
}

.sidebar-white2 .submenu .nav-item.active > .nav-link > .nav-icon {
  color: #70a1d1;
}

.sidebar-white2 .caret {
  font-size: 0.925em;
  color: #91979f;
}

.sidebar-white2 .open > .nav-link > .caret {
  color: inherit;
  opacity: 0.82;
}

.sidebar-white2 .nav-item > .nav-link:hover > .caret {
  color: inherit;
  opacity: 0.82;
}

.sidebar-white2 .nav-item.active > .nav-link > .caret {
  color: inherit;
  opacity: 0.82;
}

.sidebar-white2 .nav-item.active.open > .nav-link > .caret {
  color: inherit;
  opacity: 0.92;
}

@media (min-width: 1200px) {
  .sidebar-white2.sidebar-h .nav > .nav-item.active {
    border-width: 0 1px;
  }
}

.sidebar-white2 .nav > .nav-item.active > .nav-link {
  color: #0f62a6;
  background-color: #e3eff9;
  font-weight: 600;
}

.sidebar-white2 .nav > .nav-item.active.open > .nav-link:not(:hover) {
  color: #1d6bb3;
}

.sidebar-white2 .nav > .nav-item.active > .nav-link > .nav-icon {
  color: #357bbc;
}

.sidebar-white2 .submenu .nav-item.active > .nav-link {
  color: #0f62a6;
}

.sidebar-white2 .submenu .nav-item.active > .nav-link > .nav-icon {
  color: #70a1d1;
}

.sidebar-white2 .submenu .nav-item.active:not(.open) > .nav-link {
  font-weight: 600;
}

@media (min-width: 1200px) {
  .sidebar-white2.hoverable.collapsed .submenu .nav-item.active.open > .nav-link, .sidebar-white2.sidebar-hover:not(.collapsed) .submenu .nav-item.active.open > .nav-link {
    color: #0f62a6;
    font-weight: 600;
  }
  .sidebar-white2.hoverable.collapsed .submenu .nav-item.active > .nav-link > .nav-text::before, .sidebar-white2.sidebar-hover:not(.collapsed) .submenu .nav-item.active > .nav-link > .nav-text::before {
    content: "";
    display: block;
    opacity: 1;
  }
}

@media (hover: hover) {
  .sidebar-white2.sidebar-h .nav > .nav-item > .nav-link:hover::before {
    border-color: #63a1d8;
  }
}

.sidebar-white2 .nav.has-active-border .nav-item.active > .nav-link::before {
  content: "";
  border-color: #4f95d3;
}

.sidebar-white2 .submenu .nav-link {
  padding-left: 4em;
}

.sidebar-white2 .submenu .submenu .nav-link {
  padding-left: 4.75em;
}

.sidebar-white2 .submenu .submenu .submenu .nav-link {
  padding-left: 5.375em;
}

.sidebar-white2 .submenu .nav-text::before {
  display: none;
  position: absolute;
  left: 3em;
  z-index: 1;
  opacity: 0.65;
  top: calc(50% - 0.1875rem);
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 0.75rem;
  background-color: currentColor;
}

.sidebar-white2 .submenu .submenu .nav-text::before {
  left: 3.75em;
}

.sidebar-white2 .submenu .submenu .submenu .nav-text::before {
  left: 4.375em;
}

.sidebar-white2 .submenu .nav-item > .nav-link:hover > .nav-text::before {
  content: "";
  display: block;
  opacity: 0.8;
}

.sidebar-white2 .submenu .nav-item.active:not(.open) > .nav-link > .nav-text::before {
  content: "";
  display: block;
  opacity: 1;
}

@media (min-width: 1200px) {
  .sidebar-white2.hoverable.collapsed .submenu .nav-text::before, .sidebar-white2.sidebar-hover:not(.collapsed) .submenu .nav-text::before {
    left: calc(0.5em + 1px) !important;
  }
}

.sidebar-white2 .nav-item-caption {
  color: #507bab;
  font-size: 0.875rem;
}

.sidebar-white2 .nav-item-divider {
  border-top: 1px solid #dde3e6;
}

.sidebar-white2 .submenu .nav-item-divider {
  border-top-color: #eceff1;
}

.sidebar-white2 .ace-scroll {
  scrollbar-color: #9dbad5 #dce3ea;
}

.sidebar-white2 .ace-scroll::-webkit-scrollbar-track {
  background-color: #dce3ea;
  border-right: 1px solid #fff;
}

.sidebar-white2 .ace-scroll::-webkit-scrollbar-thumb {
  background-color: #9dbad5;
  border-right: 1px solid #fff;
}

.sidebar-white2.sidebar-spaced .nav > .nav-item {
  border-radius: 0;
}

@media (min-width: 1200px) {
  .sidebar-white2.sidebar-spaced.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item {
    border-radius: 0;
  }
}

@media (min-width: 1200px) {
  .sidebar-white2.toggling .nav > .nav-item.active.open {
    transition: none;
  }
  .sidebar-white2.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.open:not(.active), .sidebar-white2.hoverable.collapsed .nav > .nav-item.open:not(.active) {
    border-color: transparent;
    background-color: transparent;
  }
  .sidebar-white2.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.open:not(.active) > .nav-link, .sidebar-white2.hoverable.collapsed .nav > .nav-item.open:not(.active) > .nav-link {
    color: #575c5f;
    background-color: transparent;
  }
  .sidebar-white2.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.open:not(.active) > .nav-link > .nav-icon, .sidebar-white2.hoverable.collapsed .nav > .nav-item.open:not(.active) > .nav-link > .nav-icon {
    color: #73869b;
  }
  .sidebar-white2.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.active.open, .sidebar-white2.hoverable.collapsed .nav > .nav-item.active.open {
    transition: none;
  }
}

.sidebar-white2 .sidebar-search-input {
  color: rgba(68, 80, 92, 0.9);
  border-bottom-color: rgba(204, 204, 204, 0.4);
}

.sidebar-white2 .sidebar-search-input:focus {
  border-bottom-color: rgba(140, 174, 213, 0.73);
}

.sidebar-white2 .sidebar-search-input::placeholder {
  color: rgba(129, 131, 136, 0.5);
}

.sidebar-white2 .sidebar-search-input::-moz-placeholder {
  color: #818388;
}

.sidebar-white3 {
  background-color: #fff;
  --active-border-offset-right: 2px;
}

.sidebar-white3 .sidebar-inner {
  border-right: 1px solid #dee6ee;
  background: inherit;
}

.sidebar-white3 .nav.active-on-right .nav-item.active .nav-link::before {
  right: 2px;
}

@media (max-width: 1199.98px) {
  .sidebar-white3:not(.sidebar-fixed) .sidebar-inner {
    border-bottom: 1px solid #dee6ee;
  }
}

.sidebar-white3 .nav > .nav-item {
  margin-bottom: 2px;
}

.sidebar-white3 .nav > .nav-item > .nav-link {
  color: #575c5f;
  font-size: 0.9625rem;
  min-height: 3.5em;
}

.sidebar-white3 .nav > .nav-item > .nav-link > .nav-icon {
  color: #73869b;
  font-size: 1.25em;
}

.sidebar-white3 .nav > .nav-item.open > .nav-link {
  color: rgba(0, 0, 0, 0.73);
  font-weight: 600;
  background-color: transparent;
}

.sidebar-white3.sidebar-spaced .nav > .nav-item.open:not(.active) > .nav-link {
  background-color: #e5f0fa;
}

.sidebar-white3 .nav > .nav-item.open > .nav-link > .nav-icon {
  color: #357bbc;
}

.sidebar-white3 .nav > .nav-item > .nav-link:hover {
  background-color: #e5f0fa;
  color: rgba(0, 0, 0, 0.75);
}

.sidebar-white3 .nav > .nav-item > .nav-link:hover > .nav-icon {
  color: #4685bb;
}

.sidebar-white3 .nav > .nav-item > .submenu {
  background-color: #fff;
}

.sidebar-white3 .submenu .nav-link {
  color: #575c5f;
  font-size: 0.9125rem;
}

.sidebar-white3 .submenu .nav-item.open > .nav-link {
  color: #3e7ab1;
}

.sidebar-white3 .submenu .nav-item > .nav-link:hover {
  color: #2e699c;
  background-color: #e5f0fa;
}

.sidebar-white3 .submenu .nav-icon {
  color: #99a0a8;
  font-size: 1.1875rem;
}

.sidebar-white3 .submenu .nav-link:hover > .nav-icon {
  color: #92afd0;
}

.sidebar-white3 .submenu .nav-item.open > .nav-link > .nav-icon {
  color: #92afd0;
}

.sidebar-white3 .submenu .nav-item.active > .nav-link > .nav-icon {
  color: #70a1d1;
}

.sidebar-white3 .caret {
  font-size: 0.925em;
  color: #91979f;
}

.sidebar-white3 .open > .nav-link > .caret {
  color: inherit;
  opacity: 0.82;
}

.sidebar-white3 .nav-item > .nav-link:hover > .caret {
  color: inherit;
  opacity: 0.82;
}

.sidebar-white3 .nav-item.active > .nav-link > .caret {
  color: inherit;
  opacity: 0.82;
}

.sidebar-white3 .nav-item.active.open > .nav-link > .caret {
  color: inherit;
  opacity: 0.92;
}

@media (min-width: 1200px) {
  .sidebar-white3.sidebar-h .nav > .nav-item.active {
    border-width: 0 1px;
  }
}

.sidebar-white3 .nav > .nav-item.active > .nav-link {
  color: #0f63a8;
  background-color: #e3eff9;
  font-weight: 600;
}

.sidebar-white3 .nav > .nav-item.active.open > .nav-link:not(:hover) {
  color: #1d6bb3;
}

.sidebar-white3 .nav > .nav-item.active > .nav-link > .nav-icon {
  color: #357bbc;
}

.sidebar-white3 .submenu .nav-item.active > .nav-link {
  color: #0f62a6;
}

.sidebar-white3 .submenu .nav-item.active > .nav-link > .nav-icon {
  color: #70a1d1;
}

.sidebar-white3 .submenu .nav-item.active:not(.open) > .nav-link {
  font-weight: 600;
}

@media (min-width: 1200px) {
  .sidebar-white3.hoverable.collapsed .submenu .nav-item.active.open > .nav-link, .sidebar-white3.sidebar-hover:not(.collapsed) .submenu .nav-item.active.open > .nav-link {
    color: #0f62a6;
    font-weight: 600;
  }
  .sidebar-white3.hoverable.collapsed .submenu .nav-item.active > .nav-link > .nav-text::before, .sidebar-white3.sidebar-hover:not(.collapsed) .submenu .nav-item.active > .nav-link > .nav-text::before {
    content: "";
    display: block;
    opacity: 1;
  }
}

.sidebar-white3 .nav.has-active-border .nav-item.active > .nav-link::before {
  content: "";
  border-color: #4f95d3;
  top: 0.5rem;
  bottom: 0.5rem;
  margin-left: 1px;
  border-radius: 6px;
}

.sidebar-white3 .submenu .nav-link {
  padding-left: 4em;
}

.sidebar-white3 .submenu .submenu .nav-link {
  padding-left: 4.75em;
}

.sidebar-white3 .submenu .submenu .submenu .nav-link {
  padding-left: 5.375em;
}

.sidebar-white3 .submenu .nav-text::before {
  display: none;
  position: absolute;
  left: 3em;
  z-index: 1;
  opacity: 0.65;
  top: calc(50% - 0.1875rem);
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 0.75rem;
  background-color: currentColor;
}

.sidebar-white3 .submenu .submenu .nav-text::before {
  left: 3.75em;
}

.sidebar-white3 .submenu .submenu .submenu .nav-text::before {
  left: 4.375em;
}

.sidebar-white3 .submenu .nav-item > .nav-link:hover > .nav-text::before {
  content: "";
  display: block;
  opacity: 0.8;
}

.sidebar-white3 .submenu .nav-item.active:not(.open) > .nav-link > .nav-text::before {
  content: "";
  display: block;
  opacity: 1;
}

@media (min-width: 1200px) {
  .sidebar-white3.hoverable.collapsed .submenu .nav-text::before, .sidebar-white3.sidebar-hover:not(.collapsed) .submenu .nav-text::before {
    left: calc(0.5em + 1px) !important;
  }
}

.sidebar-white3 .nav-item-caption {
  color: #507bab;
  font-size: 0.875rem;
}

.sidebar-white3 .nav-item-divider {
  border-top: 1px solid #dde3e6;
}

.sidebar-white3 .submenu .nav-item-divider {
  border-top-color: #eceff1;
}

.sidebar-white3 .ace-scroll {
  scrollbar-color: #9dbad5 #dce3ea;
}

.sidebar-white3 .ace-scroll::-webkit-scrollbar-track {
  background-color: #dce3ea;
  border-right: 1px solid #fff;
}

.sidebar-white3 .ace-scroll::-webkit-scrollbar-thumb {
  background-color: #9dbad5;
  border-right: 1px solid #fff;
}

.sidebar-white3.sidebar-spaced .nav > .nav-item {
  border-radius: 0;
}

@media (min-width: 1200px) {
  .sidebar-white3.sidebar-spaced.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item {
    border-radius: 0;
  }
}

@media (min-width: 1200px) {
  .sidebar-white3.toggling .nav > .nav-item.active.open {
    transition: none;
  }
  .sidebar-white3.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.open:not(.active), .sidebar-white3.hoverable.collapsed .nav > .nav-item.open:not(.active) {
    border-color: transparent;
    background-color: transparent;
  }
  .sidebar-white3.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.open:not(.active) > .nav-link, .sidebar-white3.hoverable.collapsed .nav > .nav-item.open:not(.active) > .nav-link {
    color: #575c5f;
    background-color: transparent;
  }
  .sidebar-white3.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.open:not(.active) > .nav-link > .nav-icon, .sidebar-white3.hoverable.collapsed .nav > .nav-item.open:not(.active) > .nav-link > .nav-icon {
    color: #73869b;
  }
  .sidebar-white3.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.active.open, .sidebar-white3.hoverable.collapsed .nav > .nav-item.active.open {
    transition: none;
  }
}

.sidebar-white3 .sidebar-search-input {
  color: rgba(68, 80, 92, 0.9);
  border-bottom-color: rgba(204, 204, 204, 0.4);
}

.sidebar-white3 .sidebar-search-input:focus {
  border-bottom-color: rgba(140, 174, 213, 0.73);
}

.sidebar-white3 .sidebar-search-input::placeholder {
  color: rgba(129, 131, 136, 0.5);
}

.sidebar-white3 .sidebar-search-input::-moz-placeholder {
  color: #818388;
}

.sidebar-white4 {
  background-color: #fff;
  --active-border-offset-right: 2px;
}

.sidebar-white4 .sidebar-inner {
  border-right: 1px solid #dee6ee;
  background: inherit;
}

.sidebar-white4 .nav.active-on-right .nav-item.active .nav-link::before {
  right: 2px;
}

@media (max-width: 1199.98px) {
  .sidebar-white4:not(.sidebar-fixed) .sidebar-inner {
    border-bottom: 1px solid #dee6ee;
  }
}

.sidebar-white4 .nav > .nav-item::after {
  content: "";
  display: block;
  position: absolute;
  bottom: -1px;
  left: 1.25rem;
  right: 1.25rem;
  height: 0;
  width: auto;
  border: 0px solid #eceeef;
  border-width: 0 0 1px 0;
}

@media (min-width: 1200px) {
  .sidebar-white4.sidebar-h .nav > .nav-item::after {
    display: none;
  }
}

.sidebar-white4 .nav > .nav-item {
  margin-bottom: 2px;
}

.sidebar-white4 .nav > .nav-item > .nav-link {
  color: #4f5052;
  font-size: 0.925rem;
}

.sidebar-white4 .nav > .nav-item > .nav-link > .nav-icon {
  color: #888997;
  font-size: 1.25em;
}

.sidebar-white4 .nav > .nav-item.open > .nav-link {
  color: rgba(0, 0, 0, 0.73);
  font-weight: 600;
  background-color: transparent;
}

.sidebar-white4 .nav > .nav-item.open > .nav-link > .nav-icon {
  color: #6a6c7d;
}

.sidebar-white4 .nav > .nav-item > .nav-link:hover {
  color: rgba(0, 0, 0, 0.73);
}

.sidebar-white4 .nav > .nav-item > .nav-link:hover > .nav-icon {
  color: #888997;
}

.sidebar-white4 .nav > .nav-item > .submenu {
  background-color: #fff;
}

.sidebar-white4 .submenu .nav-link {
  color: #4f5052;
  font-size: 0.9125rem;
}

.sidebar-white4 .submenu .nav-item.open > .nav-link {
  color: #356da2;
}

.sidebar-white4 .submenu .nav-item > .nav-link:hover {
  color: #3b7ab5;
  font-weight: 600;
}

.sidebar-white4 .submenu .nav-icon {
  color: #99a0a8;
  font-size: 1.1875rem;
}

.sidebar-white4 .submenu .nav-link:hover > .nav-icon {
  color: #92afd0;
}

.sidebar-white4 .submenu .nav-item.open > .nav-link > .nav-icon {
  color: #92afd0;
}

.sidebar-white4 .submenu .nav-item.active > .nav-link > .nav-icon {
  color: #70a1d1;
}

.sidebar-white4 .caret {
  font-size: 0.925em;
  color: #91979f;
}

.sidebar-white4 .open > .nav-link > .caret {
  color: inherit;
  opacity: 0.82;
}

.sidebar-white4 .nav-item > .nav-link:hover > .caret {
  color: inherit;
  opacity: 0.82;
}

.sidebar-white4 .nav-item.active > .nav-link > .caret {
  color: inherit;
}

.sidebar-white4 .nav-item.active.open > .nav-link > .caret {
  color: inherit;
  opacity: 0.92;
}

@media (min-width: 1200px) {
  .sidebar-white4.sidebar-h .nav > .nav-item.active {
    border-width: 0 1px;
  }
}

.sidebar-white4 .nav > .nav-item.active > .nav-link {
  color: #0f62a6;
  font-weight: 600;
}

.sidebar-white4 .nav > .nav-item.active.open > .nav-link:not(:hover) {
  color: #1d6bb3;
  background-color: transparent;
}

.sidebar-white4 .nav > .nav-item.active > .nav-link > .nav-icon {
  color: #357bbc;
}

.sidebar-white4 .submenu .nav-item.active > .nav-link {
  color: #0f62a6;
  font-weight: 600;
}

.sidebar-white4 .submenu .nav-item.active > .nav-link > .nav-icon {
  color: #70a1d1;
}

@media (min-width: 1200px) {
  .sidebar-white4.hoverable.collapsed .submenu .nav-item.active.open > .nav-link, .sidebar-white4.sidebar-hover:not(.collapsed) .submenu .nav-item.active.open > .nav-link {
    color: #0f62a6;
    font-weight: 600;
  }
}

.sidebar-white4 .nav.has-active-border .nav-item.active > .nav-link::before {
  content: "";
  border-color: #4f95d3;
  top: 0.5rem;
  bottom: 0.5rem;
}

.sidebar-white4 .submenu .nav-link {
  padding-left: 4em;
}

.sidebar-white4 .submenu .submenu .nav-link {
  padding-left: 4.75em;
}

.sidebar-white4 .submenu .submenu .submenu .nav-link {
  padding-left: 5.375em;
}

.sidebar-white4 .submenu .nav-text::before {
  display: none;
  position: absolute;
  left: 3em;
  z-index: 1;
  opacity: 0.65;
  top: calc(50% - 0.1875rem);
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 0.75rem;
  background-color: currentColor;
}

.sidebar-white4 .submenu .submenu .nav-text::before {
  left: 3.75em;
}

.sidebar-white4 .submenu .submenu .submenu .nav-text::before {
  left: 4.375em;
}

.sidebar-white4 .submenu .nav-item > .nav-link:hover > .nav-text::before {
  content: "";
  display: block;
  opacity: 0.8;
}

@media (min-width: 1200px) {
  .sidebar-white4.hoverable.collapsed .submenu .nav-text::before, .sidebar-white4.sidebar-hover:not(.collapsed) .submenu .nav-text::before {
    left: calc(0.5em + 1px) !important;
  }
}

.sidebar-white4 .nav-item-caption {
  color: #747577;
  font-size: 0.875rem;
}

.sidebar-white4 .nav-item-divider {
  border-top: 1px solid #dde3e6;
}

.sidebar-white4 .submenu .nav-item-divider {
  border-top-color: #eceff1;
}

.sidebar-white4 .ace-scroll {
  scrollbar-color: #9dbad5 #dce3ea;
}

.sidebar-white4 .ace-scroll::-webkit-scrollbar-track {
  background-color: #dce3ea;
  border-right: 1px solid #fff;
}

.sidebar-white4 .ace-scroll::-webkit-scrollbar-thumb {
  background-color: #9dbad5;
  border-right: 1px solid #fff;
}

.sidebar-white4.sidebar-spaced .nav > .nav-item {
  border-radius: 0;
}

@media (min-width: 1200px) {
  .sidebar-white4.sidebar-spaced.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item {
    border-radius: 0;
  }
}

@media (min-width: 1200px) {
  .sidebar-white4.toggling .nav > .nav-item.active.open {
    transition: none;
  }
  .sidebar-white4.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.open:not(.active), .sidebar-white4.hoverable.collapsed .nav > .nav-item.open:not(.active) {
    border-color: transparent;
    background-color: transparent;
  }
  .sidebar-white4.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.open:not(.active) > .nav-link, .sidebar-white4.hoverable.collapsed .nav > .nav-item.open:not(.active) > .nav-link {
    color: #4f5052;
    background-color: transparent;
  }
  .sidebar-white4.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.open:not(.active) > .nav-link > .nav-icon, .sidebar-white4.hoverable.collapsed .nav > .nav-item.open:not(.active) > .nav-link > .nav-icon {
    color: #888997;
  }
  .sidebar-white4.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.active.open, .sidebar-white4.hoverable.collapsed .nav > .nav-item.active.open {
    transition: none;
  }
}

.sidebar-white4 .sidebar-search-input {
  color: rgba(68, 80, 92, 0.9);
  border-bottom-color: rgba(204, 204, 204, 0.4);
}

.sidebar-white4 .sidebar-search-input:focus {
  border-bottom-color: rgba(140, 174, 213, 0.73);
}

.sidebar-white4 .sidebar-search-input::placeholder {
  color: rgba(129, 131, 136, 0.5);
}

.sidebar-white4 .sidebar-search-input::-moz-placeholder {
  color: #818388;
}

.sidebar-lightpurple {
  background-color: #f1f0f8;
  --active-border-offset-right: 0px;
}

.sidebar-lightpurple .sidebar-inner {
  background: inherit;
}

.sidebar-lightpurple .nav.active-on-right .nav-item.active .nav-link::before {
  right: 0px;
}

@media (max-width: 1199.98px) {
  .sidebar-lightpurple:not(.sidebar-push) .sidebar-inner {
    box-shadow: none;
    border-right: 1px solid #d1c3e7;
  }
  .sidebar-lightpurple:not(.sidebar-fixed) .sidebar-inner {
    border-bottom: 1px solid #d1c3e7;
  }
}

.sidebar-lightpurple .nav > .nav-item {
  margin-bottom: 2px;
}

.sidebar-lightpurple .nav > .nav-item > .nav-link {
  color: #565164;
  font-size: 0.975rem;
  font-weight: 400;
}

.sidebar-lightpurple .nav > .nav-item > .nav-link > .nav-icon {
  color: #918aa8;
  font-size: 1.25em;
}

.sidebar-lightpurple .nav > .nav-item.open {
  background-color: #fff;
}

.sidebar-lightpurple.sidebar-spaced .nav > .nav-item.open:not(.active) > .nav-link {
  background-color: #fff;
}

.sidebar-lightpurple .nav > .nav-item > .nav-link:hover {
  background-color: #fff;
}

.sidebar-lightpurple .nav > .nav-item > .nav-link:hover > .nav-icon {
  color: #8b83a8;
}

.sidebar-lightpurple .nav > .nav-item > .submenu {
  background-color: #fff;
}

.sidebar-lightpurple .submenu .nav-link {
  color: #635e74;
  font-size: 0.9125rem;
}

.sidebar-lightpurple .submenu .nav-item.open > .nav-link {
  color: #6a5ba6;
}

.sidebar-lightpurple .submenu .nav-item > .nav-link:hover {
  color: #66598f;
  background-color: #ececf9;
}

.sidebar-lightpurple .submenu .nav-icon {
  color: #99a0a8;
  font-size: 1.1875rem;
}

.sidebar-lightpurple .submenu .nav-link:hover > .nav-icon {
  color: #92afd0;
}

.sidebar-lightpurple .submenu .nav-item.open > .nav-link > .nav-icon {
  color: #92afd0;
}

.sidebar-lightpurple .submenu .nav-item.active > .nav-link > .nav-icon {
  color: #70a1d1;
}

.sidebar-lightpurple .caret {
  font-size: 0.925em;
  color: #9893a6;
}

.sidebar-lightpurple .open > .nav-link > .caret {
  color: inherit;
}

.sidebar-lightpurple .nav-item > .nav-link:hover > .caret {
  color: inherit;
  opacity: 0.82;
}

.sidebar-lightpurple .nav-item.active > .nav-link > .caret {
  color: inherit;
  opacity: 0.82;
}

.sidebar-lightpurple .nav-item.active.open > .nav-link > .caret {
  color: inherit;
  opacity: 0.92;
}

@media (min-width: 1200px) {
  .sidebar-lightpurple.sidebar-h .nav > .nav-item.active {
    border-width: 0 1px;
  }
}

.sidebar-lightpurple .nav > .nav-item.active > .nav-link {
  color: #766aaa;
  background-color: #fff;
  font-weight: 600;
}

.sidebar-lightpurple .nav > .nav-item.active.open > .nav-link:not(:hover) {
  color: #1d6bb3;
  background-color: transparent;
}

@media (min-width: 1200px) {
  .sidebar-lightpurple.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.active.open > .nav-link {
    background-color: #fff;
  }
}

.sidebar-lightpurple .nav > .nav-item.active > .nav-link > .nav-icon {
  color: #8779c2;
}

.sidebar-lightpurple .submenu .nav-item.active > .nav-link {
  color: #6a5ba6;
  font-weight: 600;
}

.sidebar-lightpurple .submenu .nav-item.active > .nav-link > .nav-icon {
  color: #70a1d1;
}

.sidebar-lightpurple .submenu .nav-item.active:not(.open) > .nav-link {
  background-color: #ececf9;
}

@media (min-width: 1200px) {
  .sidebar-lightpurple.hoverable.collapsed .submenu .nav-item.active.open > .nav-link, .sidebar-lightpurple.sidebar-hover:not(.collapsed) .submenu .nav-item.active.open > .nav-link {
    color: #6a5ba6;
    background-color: #ececf9;
    font-weight: 600;
  }
  .sidebar-lightpurple.hoverable.collapsed .submenu .nav-item.active > .nav-link > .nav-text::before, .sidebar-lightpurple.sidebar-hover:not(.collapsed) .submenu .nav-item.active > .nav-link > .nav-text::before {
    content: "";
    display: block;
    opacity: 1;
  }
}

@media (min-width: 1200px) {
  .sidebar-lightpurple.expandable.collapsed .sidebar-inner:not(.is-hover) {
    border-right: none !important;
    box-shadow: inset -1px 0 0 0px #d2cee5;
  }
}

.sidebar-lightpurple .submenu .nav-link {
  padding-left: 3.25em;
}

.sidebar-lightpurple .submenu .submenu .nav-link {
  padding-left: 4.5em;
}

.sidebar-lightpurple .submenu .submenu .submenu .nav-link {
  padding-left: 5.25em;
}

.sidebar-lightpurple .submenu .nav-text::before {
  display: none;
  position: absolute;
  left: 2.25em;
  z-index: 1;
  opacity: 0.65;
  top: calc(50% - 0.375rem);
  width: 0;
  height: 0;
  border: 0.375rem solid transparent;
  border-left-color: currentColor;
  border-right-width: 0;
}

.sidebar-lightpurple .submenu .submenu .nav-text::before {
  left: 3.5em;
}

.sidebar-lightpurple .submenu .submenu .submenu .nav-text::before {
  left: 4.25em;
}

.sidebar-lightpurple .submenu .nav-item > .nav-link:hover > .nav-text::before {
  content: "";
  display: block;
  opacity: 0.8;
}

.sidebar-lightpurple .submenu .nav-item.active:not(.open) > .nav-link > .nav-text::before {
  content: "";
  display: block;
  opacity: 1;
}

@media (min-width: 1200px) {
  .sidebar-lightpurple.hoverable.collapsed .submenu .nav-text::before, .sidebar-lightpurple.sidebar-hover:not(.collapsed) .submenu .nav-text::before {
    left: calc(0.5em + 1px) !important;
  }
}

.sidebar-lightpurple .nav-item-caption {
  color: #6e6c79;
  font-size: 0.875rem;
}

.sidebar-lightpurple .nav-item-divider {
  border-top: 1px solid #dde3e6;
}

.sidebar-lightpurple .submenu .nav-item-divider {
  border-top-color: #eceff1;
}

.sidebar-lightpurple .ace-scroll {
  scrollbar-color: rgba(0, 0, 0, 0.175) rgba(0, 0, 0, 0.05);
}

.sidebar-lightpurple .ace-scroll::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.05);
  border-right: 1px solid #f1f0f8;
}

.sidebar-lightpurple .ace-scroll::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.175);
  border-right: 1px solid #f1f0f8;
}

.sidebar-lightpurple.sidebar-spaced .nav > .nav-item {
  border-radius: 0.5rem;
  border-style: solid;
  border-width: 2px;
  transition: border 0.15s;
  border-color: transparent;
}

.sidebar-lightpurple.sidebar-spaced .nav > .nav-item.open {
  border-color: #d9d5e9;
}

.sidebar-lightpurple.sidebar-spaced .nav > .nav-item:hover {
  border-color: #d9d5e9;
}

.sidebar-lightpurple.sidebar-spaced .nav > .nav-item.active {
  border-width: 2px;
  border-color: #bfb6e0;
}

@media (min-width: 1200px) {
  .sidebar-lightpurple.sidebar-spaced.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item {
    border-radius: 0.5rem;
    border-width: 2px;
  }
}

@media (min-width: 1200px) {
  .sidebar-lightpurple.toggling .nav > .nav-item.active.open {
    transition: none;
  }
  .sidebar-lightpurple.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.open:not(.active), .sidebar-lightpurple.hoverable.collapsed .nav > .nav-item.open:not(.active) {
    border-color: transparent;
    background-color: transparent;
  }
  .sidebar-lightpurple.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.open:not(.active) > .nav-link, .sidebar-lightpurple.hoverable.collapsed .nav > .nav-item.open:not(.active) > .nav-link {
    color: #565164;
    background-color: transparent;
  }
  .sidebar-lightpurple.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.open:not(.active) > .nav-link > .nav-icon, .sidebar-lightpurple.hoverable.collapsed .nav > .nav-item.open:not(.active) > .nav-link > .nav-icon {
    color: #918aa8;
  }
  .sidebar-lightpurple.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.active.open, .sidebar-lightpurple.hoverable.collapsed .nav > .nav-item.active.open {
    transition: none;
  }
}

.sidebar-lightpurple .sidebar-search-input {
  color: rgba(68, 80, 92, 0.9);
  border-bottom-color: rgba(204, 204, 204, 0.4);
}

.sidebar-lightpurple .sidebar-search-input:focus {
  border-bottom-color: rgba(140, 174, 213, 0.73);
}

.sidebar-lightpurple .sidebar-search-input::placeholder {
  color: rgba(129, 131, 136, 0.5);
}

.sidebar-lightpurple .sidebar-search-input::-moz-placeholder {
  color: #818388;
}

.sidebar-lightblue {
  background-color: #e9f0f8;
  --active-border-offset-right: 0px;
}

.sidebar-lightblue .sidebar-inner {
  background: inherit;
}

.sidebar-lightblue .nav.active-on-right .nav-item.active .nav-link::before {
  right: 0px;
}

@media (max-width: 1199.98px) {
  .sidebar-lightblue:not(.sidebar-push) .sidebar-inner {
    box-shadow: none;
    border-right: 1px solid #b9d5ee;
  }
  .sidebar-lightblue:not(.sidebar-fixed) .sidebar-inner {
    border-bottom: 1px solid #b9d5ee;
  }
}

.sidebar-lightblue .nav > .nav-item {
  margin-bottom: 2px;
}

.sidebar-lightblue .nav > .nav-item > .nav-link {
  color: #3c5b6b;
  font-size: 0.975rem;
  font-weight: 400;
}

.sidebar-lightblue .nav > .nav-item > .nav-link > .nav-icon {
  color: #5f7987;
  font-size: 1.25em;
}

.sidebar-lightblue .nav > .nav-item.open {
  background-color: #fff;
}

.sidebar-lightblue.sidebar-spaced .nav > .nav-item.open:not(.active) > .nav-link {
  background-color: #fff;
}

.sidebar-lightblue .nav > .nav-item.open > .nav-link > .nav-icon {
  color: #357bbc;
}

.sidebar-lightblue .nav > .nav-item > .nav-link:hover {
  background-color: #fff;
}

.sidebar-lightblue .nav > .nav-item > .submenu {
  background-color: #fff;
}

.sidebar-lightblue .submenu .nav-link {
  color: #5e6061;
  font-size: 0.9125rem;
}

.sidebar-lightblue .submenu .nav-item.open > .nav-link {
  color: #3e7ab1;
}

.sidebar-lightblue .submenu .nav-item > .nav-link:hover {
  color: #1d6bb3;
  background-color: #e5f0fa;
}

.sidebar-lightblue .submenu .nav-icon {
  color: #99a0a8;
  font-size: 1.1875rem;
}

.sidebar-lightblue .submenu .nav-link:hover > .nav-icon {
  color: #92afd0;
}

.sidebar-lightblue .submenu .nav-item.open > .nav-link > .nav-icon {
  color: #92afd0;
}

.sidebar-lightblue .submenu .nav-item.active > .nav-link > .nav-icon {
  color: #70a1d1;
}

.sidebar-lightblue .caret {
  font-size: 0.925em;
  color: #929597;
}

.sidebar-lightblue .open > .nav-link > .caret {
  color: inherit;
  opacity: 0.82;
}

.sidebar-lightblue .nav-item > .nav-link:hover > .caret {
  color: inherit;
  opacity: 0.82;
}

.sidebar-lightblue .nav-item.active > .nav-link > .caret {
  color: inherit;
  opacity: 0.82;
}

.sidebar-lightblue .nav-item.active.open > .nav-link > .caret {
  color: inherit;
  opacity: 0.92;
}

@media (min-width: 1200px) {
  .sidebar-lightblue.sidebar-h .nav > .nav-item.active {
    border-width: 0 1px;
  }
}

.sidebar-lightblue .nav > .nav-item.active > .nav-link {
  color: #3273a8;
  background-color: #fff;
  font-weight: 600;
}

.sidebar-lightblue .nav > .nav-item.active.open > .nav-link:not(:hover) {
  color: #1d6bb3;
  background-color: transparent;
}

@media (min-width: 1200px) {
  .sidebar-lightblue.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.active.open > .nav-link {
    background-color: #fff;
  }
}

.sidebar-lightblue .nav > .nav-item.active > .nav-link > .nav-icon {
  color: #4e8aba;
}

.sidebar-lightblue .submenu .nav-item.active > .nav-link {
  color: #0f62a6;
}

.sidebar-lightblue .submenu .nav-item.active > .nav-link > .nav-icon {
  color: #70a1d1;
}

.sidebar-lightblue .submenu .nav-item.active:not(.open) > .nav-link {
  background-color: #e3eff9;
  font-weight: 600;
}

@media (min-width: 1200px) {
  .sidebar-lightblue.hoverable.collapsed .submenu .nav-item.active.open > .nav-link, .sidebar-lightblue.sidebar-hover:not(.collapsed) .submenu .nav-item.active.open > .nav-link {
    color: #0f62a6;
    background-color: #e3eff9;
    font-weight: 600;
  }
  .sidebar-lightblue.hoverable.collapsed .submenu .nav-item.active > .nav-link > .nav-text::before, .sidebar-lightblue.sidebar-hover:not(.collapsed) .submenu .nav-item.active > .nav-link > .nav-text::before {
    content: "";
    display: block;
    opacity: 1;
  }
}

@media (min-width: 1200px) {
  .sidebar-lightblue.expandable.collapsed .sidebar-inner:not(.is-hover) {
    border-right: none !important;
    box-shadow: inset -1px 0 0 0px #c9dff2;
  }
}

.sidebar-lightblue .submenu .nav-link {
  padding-left: 3.25em;
}

.sidebar-lightblue .submenu .submenu .nav-link {
  padding-left: 4.5em;
}

.sidebar-lightblue .submenu .submenu .submenu .nav-link {
  padding-left: 5.25em;
}

.sidebar-lightblue .submenu .nav-text::before {
  display: none;
  position: absolute;
  left: 2.25em;
  z-index: 1;
  opacity: 0.65;
  top: calc(50% - 0.1875rem);
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 0.75rem;
  background-color: currentColor;
}

.sidebar-lightblue .submenu .submenu .nav-text::before {
  left: 3.5em;
}

.sidebar-lightblue .submenu .submenu .submenu .nav-text::before {
  left: 4.25em;
}

.sidebar-lightblue .submenu .nav-item > .nav-link:hover > .nav-text::before {
  content: "";
  display: block;
  opacity: 0.8;
}

.sidebar-lightblue .submenu .nav-item.active:not(.open) > .nav-link > .nav-text::before {
  content: "";
  display: block;
  opacity: 1;
}

@media (min-width: 1200px) {
  .sidebar-lightblue.hoverable.collapsed .submenu .nav-text::before, .sidebar-lightblue.sidebar-hover:not(.collapsed) .submenu .nav-text::before {
    left: calc(0.5em + 1px) !important;
  }
}

.sidebar-lightblue .nav-item-caption {
  color: #687278;
  font-size: 0.875rem;
}

.sidebar-lightblue .nav-item-divider {
  border-top: 1px solid #dde3e6;
}

.sidebar-lightblue .submenu .nav-item-divider {
  border-top-color: #eceff1;
}

.sidebar-lightblue .ace-scroll {
  scrollbar-color: #9dbad5 #dce3ea;
}

.sidebar-lightblue .ace-scroll::-webkit-scrollbar-track {
  background-color: #dce3ea;
  border-right: 1px solid #e9f0f8;
}

.sidebar-lightblue .ace-scroll::-webkit-scrollbar-thumb {
  background-color: #9dbad5;
  border-right: 1px solid #e9f0f8;
}

.sidebar-lightblue.sidebar-spaced .nav > .nav-item {
  border-radius: 1.75rem;
  border-style: solid;
  border-width: 2px;
  transition: border 0.15s;
  border-color: transparent;
}

.sidebar-lightblue.sidebar-spaced .nav > .nav-item.open {
  border-radius: 1.25rem;
  border-width: 2px;
  border-color: #afd3f0;
}

.sidebar-lightblue.sidebar-spaced .nav > .nav-item:hover {
  border-color: #afd3f0;
}

.sidebar-lightblue.sidebar-spaced .nav > .nav-item.active {
  border-width: 2px;
  border-color: #a1c3df;
}

.sidebar-lightblue.sidebar-spaced .nav > .nav-item.active.open {
  border-width: 2px;
  border-color: #afd3f0 #afd3f0;
}

@media (min-width: 1200px) {
  .sidebar-lightblue.sidebar-spaced.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item {
    border-radius: 1.75rem;
  }
}

@media (min-width: 1200px) {
  .sidebar-lightblue.toggling .nav > .nav-item.active.open {
    transition: none;
  }
  .sidebar-lightblue.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.open:not(.active), .sidebar-lightblue.hoverable.collapsed .nav > .nav-item.open:not(.active) {
    border-color: transparent;
    background-color: transparent;
  }
  .sidebar-lightblue.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.open:not(.active) > .nav-link, .sidebar-lightblue.hoverable.collapsed .nav > .nav-item.open:not(.active) > .nav-link {
    color: #3c5b6b;
    background-color: transparent;
  }
  .sidebar-lightblue.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.open:not(.active) > .nav-link > .nav-icon, .sidebar-lightblue.hoverable.collapsed .nav > .nav-item.open:not(.active) > .nav-link > .nav-icon {
    color: #5f7987;
  }
  .sidebar-lightblue.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.active.open, .sidebar-lightblue.hoverable.collapsed .nav > .nav-item.active.open {
    transition: none;
  }
}

.sidebar-lightblue .sidebar-search-input {
  color: rgba(68, 80, 92, 0.9);
  border-bottom-color: rgba(204, 204, 204, 0.4);
}

.sidebar-lightblue .sidebar-search-input:focus {
  border-bottom-color: rgba(140, 174, 213, 0.73);
}

.sidebar-lightblue .sidebar-search-input::placeholder {
  color: rgba(129, 131, 136, 0.5);
}

.sidebar-lightblue .sidebar-search-input::-moz-placeholder {
  color: #818388;
}

.sidebar-lightblue2 {
  background-color: #e9f1fb;
  --active-border-offset-right: 0px;
}

.sidebar-lightblue2 .sidebar-inner {
  background: inherit;
}

.sidebar-lightblue2 .nav.active-on-right .nav-item.active .nav-link::before {
  right: 0px;
}

@media (max-width: 1199.98px) {
  .sidebar-lightblue2:not(.sidebar-push) .sidebar-inner {
    box-shadow: none;
    border-right: 1px solid #c8dcee;
  }
  .sidebar-lightblue2:not(.sidebar-fixed) .sidebar-inner {
    border-bottom: 1px solid #c8dcee;
  }
}

.sidebar-lightblue2 .nav > .nav-item {
  margin-bottom: 2px;
}

.sidebar-lightblue2 .nav > .nav-item > .nav-link {
  color: #495462;
  font-size: 0.975rem;
  font-weight: 400;
}

.sidebar-lightblue2 .nav > .nav-item > .nav-link > .nav-icon {
  color: #588daa;
  font-size: 1.25em;
}

.sidebar-lightblue2 .nav > .nav-item.open > .nav-link {
  background-color: #fff;
}

.sidebar-lightblue2 .nav > .nav-item > .nav-link:hover {
  background-color: #fff;
}

.sidebar-lightblue2 .nav > .nav-item > .submenu {
  background-color: #fff;
}

.sidebar-lightblue2 .submenu .nav-link {
  color: #5d6c7e;
  font-size: 0.9125rem;
}

.sidebar-lightblue2 .submenu .nav-item.open > .nav-link {
  color: #3e7ab1;
}

.sidebar-lightblue2 .submenu .nav-item > .nav-link:hover {
  color: #1d6bb3;
  background-color: #e5f0fa;
}

.sidebar-lightblue2 .submenu .nav-icon {
  color: #99a0a8;
  font-size: 1.1875rem;
}

.sidebar-lightblue2 .submenu .nav-link:hover > .nav-icon {
  color: #92afd0;
}

.sidebar-lightblue2 .submenu .nav-item.open > .nav-link > .nav-icon {
  color: #92afd0;
}

.sidebar-lightblue2 .submenu .nav-item.active > .nav-link > .nav-icon {
  color: #70a1d1;
}

.sidebar-lightblue2 .caret {
  font-size: 0.925em;
  color: #91979f;
}

.sidebar-lightblue2 .open > .nav-link > .caret {
  color: #599bce;
}

.sidebar-lightblue2 .nav-item > .nav-link:hover > .caret {
  color: inherit;
  opacity: 0.82;
}

.sidebar-lightblue2 .nav-item.active > .nav-link > .caret {
  color: inherit;
  opacity: 0.82;
}

.sidebar-lightblue2 .nav-item.active.open > .nav-link > .caret {
  color: inherit;
  opacity: 0.92;
}

@media (min-width: 1200px) {
  .sidebar-lightblue2.sidebar-h .nav > .nav-item.active {
    border-width: 0 1px;
  }
}

.sidebar-lightblue2 .nav > .nav-item.active > .nav-link {
  color: #fff;
  background-color: #317bc7;
  font-weight: 600;
}

.sidebar-lightblue2 .nav > .nav-item.active.open > .nav-link:not(:hover) {
  color: #1d6bb3;
}

.sidebar-lightblue2 .nav > .nav-item.active > .nav-link > .nav-icon {
  color: #fff;
}

.sidebar-lightblue2 .submenu .nav-item.active > .nav-link {
  color: #0f62a6;
}

.sidebar-lightblue2 .submenu .nav-item.active > .nav-link > .nav-icon {
  color: #70a1d1;
}

.sidebar-lightblue2 .submenu .nav-item.active:not(.open) > .nav-link {
  background-color: #e3eff9;
  font-weight: 600;
}

@media (min-width: 1200px) {
  .sidebar-lightblue2.hoverable.collapsed .submenu .nav-item.active.open > .nav-link, .sidebar-lightblue2.sidebar-hover:not(.collapsed) .submenu .nav-item.active.open > .nav-link {
    color: #0f62a6;
    background-color: #e3eff9;
    font-weight: 600;
  }
  .sidebar-lightblue2.hoverable.collapsed .submenu .nav-item.active > .nav-link > .nav-text::before, .sidebar-lightblue2.sidebar-hover:not(.collapsed) .submenu .nav-item.active > .nav-link > .nav-text::before {
    content: "";
    display: block;
    opacity: 1;
  }
}

.sidebar-lightblue2 .submenu .nav-link {
  padding-left: 3.25em;
}

.sidebar-lightblue2 .submenu .submenu .nav-link {
  padding-left: 4.5em;
}

.sidebar-lightblue2 .submenu .submenu .submenu .nav-link {
  padding-left: 5.25em;
}

.sidebar-lightblue2 .submenu .nav-text::before {
  display: none;
  position: absolute;
  left: 2.25em;
  z-index: 1;
  opacity: 0.65;
  top: calc(50% - 0.1875rem);
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 0.75rem;
  background-color: currentColor;
}

.sidebar-lightblue2 .submenu .submenu .nav-text::before {
  left: 3.5em;
}

.sidebar-lightblue2 .submenu .submenu .submenu .nav-text::before {
  left: 4.25em;
}

.sidebar-lightblue2 .submenu .nav-item > .nav-link:hover > .nav-text::before {
  content: "";
  display: block;
  opacity: 0.8;
}

.sidebar-lightblue2 .submenu .nav-item.active:not(.open) > .nav-link > .nav-text::before {
  content: "";
  display: block;
  opacity: 1;
}

@media (min-width: 1200px) {
  .sidebar-lightblue2.hoverable.collapsed .submenu .nav-text::before, .sidebar-lightblue2.sidebar-hover:not(.collapsed) .submenu .nav-text::before {
    left: calc(0.5em + 1px) !important;
  }
}

.sidebar-lightblue2 .nav-item-caption {
  color: #70808c;
  font-size: 0.875rem;
}

.sidebar-lightblue2 .nav-item-divider {
  border-top: 1px solid #dde3e6;
}

.sidebar-lightblue2 .submenu .nav-item-divider {
  border-top-color: #eceff1;
}

.sidebar-lightblue2 .ace-scroll {
  scrollbar-color: #9dbad5 #dce3ea;
}

.sidebar-lightblue2 .ace-scroll::-webkit-scrollbar-track {
  background-color: #dce3ea;
  border-right: 1px solid #e9f1fb;
}

.sidebar-lightblue2 .ace-scroll::-webkit-scrollbar-thumb {
  background-color: #9dbad5;
  border-right: 1px solid #e9f1fb;
}

.sidebar-lightblue2.sidebar-spaced .nav > .nav-item {
  border-radius: 0.25rem;
}

@media (min-width: 1200px) {
  .sidebar-lightblue2.sidebar-spaced.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item {
    border-radius: 0.25rem;
  }
}

@media (min-width: 1200px) {
  .sidebar-lightblue2.toggling .nav > .nav-item.active.open {
    transition: none;
  }
  .sidebar-lightblue2.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.open:not(.active), .sidebar-lightblue2.hoverable.collapsed .nav > .nav-item.open:not(.active) {
    border-color: transparent;
    background-color: transparent;
  }
  .sidebar-lightblue2.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.open:not(.active) > .nav-link, .sidebar-lightblue2.hoverable.collapsed .nav > .nav-item.open:not(.active) > .nav-link {
    color: #495462;
    background-color: transparent;
  }
  .sidebar-lightblue2.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.open:not(.active) > .nav-link > .nav-icon, .sidebar-lightblue2.hoverable.collapsed .nav > .nav-item.open:not(.active) > .nav-link > .nav-icon {
    color: #588daa;
  }
  .sidebar-lightblue2.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.active.open, .sidebar-lightblue2.hoverable.collapsed .nav > .nav-item.active.open {
    transition: none;
  }
}

.sidebar-lightblue2 .sidebar-search-input {
  color: rgba(68, 80, 92, 0.9);
  border-bottom-color: rgba(204, 204, 204, 0.4);
}

.sidebar-lightblue2 .sidebar-search-input:focus {
  border-bottom-color: rgba(140, 174, 213, 0.73);
}

.sidebar-lightblue2 .sidebar-search-input::placeholder {
  color: rgba(129, 131, 136, 0.5);
}

.sidebar-lightblue2 .sidebar-search-input::-moz-placeholder {
  color: #818388;
}

.sidebar-color {
  background-color: #1e2635;
  --active-border-offset-right: 1px;
}

.sidebar-color .sidebar-inner {
  background: inherit;
}

.sidebar-color .nav.active-on-right .nav-item.active .nav-link::before {
  right: 1px;
}

@media (max-width: 1199.98px) {
  .sidebar-color:not(.sidebar-fixed) .sidebar-inner {
    border-bottom: 1px solid #1e2635;
  }
}

.sidebar-color .nav > .nav-item > .nav-link {
  color: rgba(255, 255, 255, 0.92);
  font-size: 0.95rem;
}

.sidebar-color .nav > .nav-item > .nav-link > .nav-icon {
  color: rgba(255, 255, 255, 0.78);
  font-size: 1.2em;
}

.sidebar-color .nav > .nav-item.open > .nav-link {
  color: rgba(255, 255, 255, 0.92);
  background-color: transparent;
}

.sidebar-color.sidebar-spaced .nav > .nav-item.open:not(.active) > .nav-link {
  background-color: rgba(255, 255, 255, 0.125);
}

.sidebar-color .nav > .nav-item > .nav-link:hover {
  background-color: rgba(255, 255, 255, 0.125);
  color: #fff;
}

.sidebar-color .nav > .nav-item > .nav-link:hover > .nav-icon {
  color: rgba(255, 255, 238, 0.9);
}

.sidebar-color .nav > .nav-item > .submenu {
  background-color: rgba(0, 0, 0, 0.2);
}

.sidebar-color .submenu .nav-link {
  color: rgba(255, 255, 255, 0.85);
  font-size: 0.9125rem;
}

.sidebar-color .submenu .nav-item.open > .nav-link {
  color: rgba(255, 255, 255, 0.95);
}

.sidebar-color .submenu .nav-item > .nav-link:hover {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.175);
}

.sidebar-color .submenu .nav-icon {
  color: #92afd0;
  font-size: 1.1875rem;
}

.sidebar-color .submenu .nav-link:hover > .nav-icon {
  color: #92afd0;
}

.sidebar-color .submenu .nav-item.open > .nav-link > .nav-icon {
  color: #92afd0;
}

.sidebar-color .submenu .nav-item.active > .nav-link > .nav-icon {
  color: #70a1d1;
}

.sidebar-color .caret {
  font-size: 0.925em;
  color: rgba(255, 255, 255, 0.7);
}

.sidebar-color .open > .nav-link > .caret {
  color: rgba(255, 255, 255, 0.9);
}

.sidebar-color .nav-item > .nav-link:hover > .caret {
  color: inherit;
  opacity: 0.82;
}

.sidebar-color .nav-item.active > .nav-link > .caret {
  color: inherit;
  opacity: 0.82;
}

.sidebar-color .nav-item.active.open > .nav-link > .caret {
  color: inherit;
  opacity: 0.92;
}

@media (min-width: 1200px) {
  .sidebar-color.sidebar-h .nav > .nav-item.active {
    border-width: 0 1px;
  }
}

.sidebar-color .nav > .nav-item.active > .nav-link {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.15);
}

.sidebar-color .nav > .nav-item.active:not(.open) > .nav-link {
  font-weight: 600;
}

.sidebar-color .nav > .nav-item.active > .nav-link > .nav-icon {
  color: rgba(255, 255, 255, 0.95);
}

.sidebar-color .submenu .nav-item.active > .nav-link {
  color: #8ebfe7;
}

.sidebar-color .submenu .nav-item.active > .nav-link > .nav-icon {
  color: #70a1d1;
}

.sidebar-color .submenu .nav-item.active:not(.open) > .nav-link {
  font-weight: 600;
}

@media (min-width: 1200px) {
  .sidebar-color.hoverable.collapsed .submenu .nav-item.active.open > .nav-link, .sidebar-color.sidebar-hover:not(.collapsed) .submenu .nav-item.active.open > .nav-link {
    color: #8ebfe7;
    font-weight: 600;
  }
}

.sidebar-color .nav.has-active-border .nav-item.active > .nav-link::before {
  content: "";
  border-color: rgba(255, 255, 255, 0.75);
}

.sidebar-color .submenu .nav-link {
  padding-left: 4em;
}

.sidebar-color .submenu .submenu .nav-link {
  padding-left: 4.75em;
}

.sidebar-color .submenu .submenu .submenu .nav-link {
  padding-left: 5.375em;
}

.sidebar-color .nav-item-caption {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.875rem;
}

.sidebar-color .nav-item-divider {
  border-top: 1px solid rgba(208, 217, 221, 0.175);
}

.sidebar-color .submenu .nav-item-divider {
  border-top-color: rgba(223, 229, 232, 0.175);
}

@media (min-width: 1200px) {
  .sidebar-color.hoverable.collapsed .sidebar-section-item .fadeable,
  .sidebar-color.hoverable.collapsed .nav > .nav-item > .nav-link > .nav-text,
  .sidebar-color.hoverable.collapsed .nav > .nav-item > .submenu {
    background-color: #1e2635;
  }
  .sidebar-color.hoverable.collapsed .sub-arrow {
    border-color: #1e2635 !important;
  }
}

@media (min-width: 1200px) {
  .sidebar-color.sidebar-hover:not(.collapsed) .sidebar-section-item .fadeable,
  .sidebar-color.sidebar-hover:not(.collapsed) .nav > .nav-item > .submenu, .sidebar-color.sidebar-h:not(.collapsed) .sidebar-section-item .fadeable,
  .sidebar-color.sidebar-h:not(.collapsed) .nav > .nav-item > .submenu {
    background-color: #1e2635;
  }
  .sidebar-color.sidebar-hover:not(.collapsed) .sub-arrow, .sidebar-color.sidebar-h:not(.collapsed) .sub-arrow {
    border-color: #1e2635 !important;
  }
}

.sidebar-color .ace-scroll {
  scrollbar-color: rgba(255, 255, 255, 0.75) rgba(255, 255, 255, 0.33);
}

.sidebar-color .ace-scroll::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.33);
  border-right: 1px solid #1e2635;
}

.sidebar-color .ace-scroll::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.75);
  border-right: 1px solid #1e2635;
}

@media (min-width: 1200px) {
  .sidebar-color.toggling .nav > .nav-item.active.open {
    transition: none;
  }
  .sidebar-color.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.open:not(.active), .sidebar-color.hoverable.collapsed .nav > .nav-item.open:not(.active) {
    border-color: transparent;
    background-color: transparent;
  }
  .sidebar-color.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.open:not(.active) > .nav-link, .sidebar-color.hoverable.collapsed .nav > .nav-item.open:not(.active) > .nav-link {
    color: rgba(255, 255, 255, 0.92);
    background-color: transparent;
  }
  .sidebar-color.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.open:not(.active) > .nav-link > .nav-icon, .sidebar-color.hoverable.collapsed .nav > .nav-item.open:not(.active) > .nav-link > .nav-icon {
    color: rgba(255, 255, 255, 0.78);
  }
  .sidebar-color.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.active.open, .sidebar-color.hoverable.collapsed .nav > .nav-item.active.open {
    transition: none;
  }
}

.sidebar-color .sidebar-search-input {
  color: rgba(255, 255, 255, 0.85);
  border-bottom-color: rgba(255, 255, 255, 0.15);
}

.sidebar-color .sidebar-search-input:focus {
  border-bottom-color: rgba(255, 255, 255, 0.5);
}

.sidebar-color .sidebar-search-input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.sidebar-color .sidebar-search-input::-moz-placeholder {
  color: #fff;
}

.sidebar-dark {
  background-color: #1e2635;
  --active-border-offset-right: 1px;
}

.sidebar-dark .sidebar-inner {
  background: inherit;
}

.sidebar-dark .nav.active-on-right .nav-item.active .nav-link::before {
  right: 1px;
}

@media (max-width: 1199.98px) {
  .sidebar-dark:not(.sidebar-fixed) .sidebar-inner {
    border-bottom: 1px solid #1e2635;
  }
}

.sidebar-dark .nav > .nav-item {
  margin-bottom: 1px;
}

.sidebar-dark .nav > .nav-item > .nav-link {
  color: rgba(255, 255, 255, 0.88);
  font-size: 0.95rem;
}

.sidebar-dark .nav > .nav-item > .nav-link > .nav-icon {
  color: rgba(187, 218, 238, 0.6);
  font-size: 1.2em;
}

.sidebar-dark .nav > .nav-item.open > .nav-link {
  color: rgba(255, 255, 255, 0.92);
  background-color: transparent;
}

.sidebar-dark.sidebar-spaced .nav > .nav-item.open:not(.active) > .nav-link {
  background-color: rgba(151, 186, 247, 0.09);
}

.sidebar-dark .nav > .nav-item > .nav-link:hover {
  background-color: rgba(151, 186, 247, 0.09);
  color: #fff;
}

.sidebar-dark .nav > .nav-item > .nav-link:hover > .nav-icon {
  color: rgba(187, 218, 238, 0.92);
}

.sidebar-dark .nav > .nav-item > .submenu {
  background-color: rgba(0, 0, 0, 0.2);
}

.sidebar-dark .submenu .nav-link {
  color: rgba(255, 255, 255, 0.87);
  font-size: 0.9125rem;
}

.sidebar-dark .submenu .nav-item.open > .nav-link {
  color: rgba(255, 255, 255, 0.95);
}

.sidebar-dark .submenu .nav-item > .nav-link:hover {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.175);
}

.sidebar-dark .submenu .nav-icon {
  color: #92afd0;
  font-size: 1.1875rem;
}

.sidebar-dark .submenu .nav-link:hover > .nav-icon {
  color: #92afd0;
}

.sidebar-dark .submenu .nav-item.open > .nav-link > .nav-icon {
  color: #92afd0;
}

.sidebar-dark .submenu .nav-item.active > .nav-link > .nav-icon {
  color: #70a1d1;
}

.sidebar-dark .caret {
  font-size: 0.925em;
  color: rgba(255, 255, 255, 0.7);
}

.sidebar-dark .open > .nav-link > .caret {
  color: rgba(255, 255, 255, 0.9);
}

.sidebar-dark .nav-item > .nav-link:hover > .caret {
  color: inherit;
  opacity: 0.82;
}

.sidebar-dark .nav-item.active > .nav-link > .caret {
  color: inherit;
  opacity: 0.82;
}

.sidebar-dark .nav-item.active.open > .nav-link > .caret {
  color: inherit;
  opacity: 0.92;
}

@media (min-width: 1200px) {
  .sidebar-dark.sidebar-h .nav > .nav-item.active {
    border-width: 0 1px;
  }
}

.sidebar-dark .nav > .nav-item.active > .nav-link {
  color: #fff;
  background-color: #37536a;
}

.sidebar-dark .nav > .nav-item.active:not(.open) > .nav-link {
  font-weight: 600;
}

.sidebar-dark .nav > .nav-item.active > .nav-link > .nav-icon {
  color: rgba(255, 255, 255, 0.95);
}

.sidebar-dark .submenu .nav-item.active > .nav-link {
  color: #8ebfe7;
}

.sidebar-dark .submenu .nav-item.active > .nav-link > .nav-icon {
  color: #70a1d1;
}

.sidebar-dark .submenu .nav-item.active:not(.open) > .nav-link {
  font-weight: 600;
}

@media (min-width: 1200px) {
  .sidebar-dark.hoverable.collapsed .submenu .nav-item.active.open > .nav-link, .sidebar-dark.sidebar-hover:not(.collapsed) .submenu .nav-item.active.open > .nav-link {
    color: #8ebfe7;
    font-weight: 600;
  }
  .sidebar-dark.hoverable.collapsed .submenu .nav-item.active > .nav-link > .nav-text::before, .sidebar-dark.sidebar-hover:not(.collapsed) .submenu .nav-item.active > .nav-link > .nav-text::before {
    content: "";
    display: block;
    opacity: 1;
  }
}

.sidebar-dark .nav.has-active-border .nav-item.active > .nav-link::before {
  content: "";
  border-color: rgba(113, 200, 236, 0.8);
}

.sidebar-dark .submenu .nav-link {
  padding-left: 4em;
}

.sidebar-dark .submenu .submenu .nav-link {
  padding-left: 4.75em;
}

.sidebar-dark .submenu .submenu .submenu .nav-link {
  padding-left: 5.375em;
}

.sidebar-dark .submenu .nav-text::before {
  display: none;
  position: absolute;
  left: 3em;
  z-index: 1;
  opacity: 0.65;
  top: calc(50% - 0.1875rem);
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 0.75rem;
  background-color: currentColor;
}

.sidebar-dark .submenu .submenu .nav-text::before {
  left: 3.75em;
}

.sidebar-dark .submenu .submenu .submenu .nav-text::before {
  left: 4.375em;
}

.sidebar-dark .submenu .nav-item > .nav-link:hover > .nav-text::before {
  content: "";
  display: block;
  opacity: 0.8;
}

.sidebar-dark .submenu .nav-item.active:not(.open) > .nav-link > .nav-text::before {
  content: "";
  display: block;
  opacity: 1;
}

@media (min-width: 1200px) {
  .sidebar-dark.hoverable.collapsed .submenu .nav-text::before, .sidebar-dark.sidebar-hover:not(.collapsed) .submenu .nav-text::before {
    left: calc(0.5em + 1px) !important;
  }
}

.sidebar-dark .nav-item-caption {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.875rem;
}

.sidebar-dark .nav-item-divider {
  border-top: 1px solid rgba(208, 217, 221, 0.175);
}

.sidebar-dark .submenu .nav-item-divider {
  border-top-color: rgba(223, 229, 232, 0.175);
}

@media (min-width: 1200px) {
  .sidebar-dark.hoverable.collapsed .sidebar-section-item .fadeable,
  .sidebar-dark.hoverable.collapsed .nav > .nav-item > .nav-link > .nav-text,
  .sidebar-dark.hoverable.collapsed .nav > .nav-item > .submenu {
    background-color: #1e2635;
  }
  .sidebar-dark.hoverable.collapsed .sub-arrow {
    border-color: #1e2635 !important;
  }
}

@media (min-width: 1200px) {
  .sidebar-dark.sidebar-hover:not(.collapsed) .sidebar-section-item .fadeable,
  .sidebar-dark.sidebar-hover:not(.collapsed) .nav > .nav-item > .submenu, .sidebar-dark.sidebar-h:not(.collapsed) .sidebar-section-item .fadeable,
  .sidebar-dark.sidebar-h:not(.collapsed) .nav > .nav-item > .submenu {
    background-color: #1e2635;
  }
  .sidebar-dark.sidebar-hover:not(.collapsed) .sub-arrow, .sidebar-dark.sidebar-h:not(.collapsed) .sub-arrow {
    border-color: #1e2635 !important;
  }
}

.sidebar-dark .ace-scroll {
  scrollbar-color: rgba(255, 255, 255, 0.75) rgba(255, 255, 255, 0.33);
}

.sidebar-dark .ace-scroll::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.33);
  border-right: 1px solid #1e2635;
}

.sidebar-dark .ace-scroll::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.75);
  border-right: 1px solid #1e2635;
}

@media (min-width: 1200px) {
  .sidebar-dark.toggling .nav > .nav-item.active.open {
    transition: none;
  }
  .sidebar-dark.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.open:not(.active), .sidebar-dark.hoverable.collapsed .nav > .nav-item.open:not(.active) {
    border-color: transparent;
    background-color: transparent;
  }
  .sidebar-dark.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.open:not(.active) > .nav-link, .sidebar-dark.hoverable.collapsed .nav > .nav-item.open:not(.active) > .nav-link {
    color: rgba(255, 255, 255, 0.88);
    background-color: transparent;
  }
  .sidebar-dark.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.open:not(.active) > .nav-link > .nav-icon, .sidebar-dark.hoverable.collapsed .nav > .nav-item.open:not(.active) > .nav-link > .nav-icon {
    color: rgba(187, 218, 238, 0.6);
  }
  .sidebar-dark.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.active.open, .sidebar-dark.hoverable.collapsed .nav > .nav-item.active.open {
    transition: none;
  }
}

.sidebar-dark .sidebar-search-input {
  color: rgba(255, 255, 255, 0.85);
  border-bottom-color: rgba(255, 255, 255, 0.15);
}

.sidebar-dark .sidebar-search-input:focus {
  border-bottom-color: rgba(255, 255, 255, 0.5);
}

.sidebar-dark .sidebar-search-input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.sidebar-dark .sidebar-search-input::-moz-placeholder {
  color: #fff;
}

.sidebar-dark2 {
  background-color: #1e2e38;
  --active-border-offset-right: 1px;
}

.sidebar-dark2 .sidebar-inner {
  background: inherit;
}

.sidebar-dark2 .nav.active-on-right .nav-item.active .nav-link::before {
  right: 1px;
}

@media (max-width: 1199.98px) {
  .sidebar-dark2:not(.sidebar-fixed) .sidebar-inner {
    border-bottom: 1px solid #1e2e38;
  }
}

.sidebar-dark2 .nav > .nav-item {
  margin-bottom: 1px;
}

.sidebar-dark2 .nav > .nav-item > .nav-link {
  color: rgba(255, 255, 255, 0.88);
  font-size: 0.95rem;
}

.sidebar-dark2 .nav > .nav-item > .nav-link > .nav-icon {
  color: rgba(187, 218, 238, 0.85);
  font-size: 1.2em;
}

.sidebar-dark2 .nav > .nav-item.open > .nav-link {
  color: rgba(255, 255, 255, 0.92);
  background-color: transparent;
}

.sidebar-dark2.sidebar-spaced .nav > .nav-item.open:not(.active) > .nav-link {
  background-color: rgba(151, 186, 247, 0.09);
}

.sidebar-dark2 .nav > .nav-item > .nav-link:hover {
  background-color: rgba(151, 186, 247, 0.09);
  color: #fff;
}

.sidebar-dark2 .nav > .nav-item > .nav-link:hover > .nav-icon {
  color: rgba(187, 218, 238, 0.92);
}

.sidebar-dark2 .nav > .nav-item > .submenu {
  background-color: rgba(0, 0, 0, 0.2);
}

.sidebar-dark2 .submenu .nav-link {
  color: rgba(255, 255, 255, 0.87);
  font-size: 0.9125rem;
}

.sidebar-dark2 .submenu .nav-item.open > .nav-link {
  color: rgba(255, 255, 255, 0.95);
}

.sidebar-dark2 .submenu .nav-item > .nav-link:hover {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.175);
}

.sidebar-dark2 .submenu .nav-icon {
  color: #92afd0;
  font-size: 1.1875rem;
}

.sidebar-dark2 .submenu .nav-link:hover > .nav-icon {
  color: #92afd0;
}

.sidebar-dark2 .submenu .nav-item.open > .nav-link > .nav-icon {
  color: #92afd0;
}

.sidebar-dark2 .submenu .nav-item.active > .nav-link > .nav-icon {
  color: #70a1d1;
}

.sidebar-dark2 .caret {
  font-size: 0.925em;
  color: rgba(255, 255, 255, 0.7);
}

.sidebar-dark2 .open > .nav-link > .caret {
  color: rgba(255, 255, 255, 0.9);
}

.sidebar-dark2 .nav-item > .nav-link:hover > .caret {
  color: inherit;
  opacity: 0.82;
}

.sidebar-dark2 .nav-item.active > .nav-link > .caret {
  color: inherit;
  opacity: 0.82;
}

.sidebar-dark2 .nav-item.active.open > .nav-link > .caret {
  color: inherit;
  opacity: 0.92;
}

@media (min-width: 1200px) {
  .sidebar-dark2.sidebar-h .nav > .nav-item.active {
    border-width: 0 1px;
  }
}

.sidebar-dark2 .nav > .nav-item.active > .nav-link {
  color: #fff;
  background-color: #466884;
}

.sidebar-dark2 .nav > .nav-item.active:not(.open) > .nav-link {
  font-weight: 600;
}

.sidebar-dark2 .nav > .nav-item.active > .nav-link > .nav-icon {
  color: rgba(255, 255, 255, 0.95);
}

.sidebar-dark2 .submenu .nav-item.active > .nav-link {
  color: #8ebfe7;
}

.sidebar-dark2 .submenu .nav-item.active > .nav-link > .nav-icon {
  color: #70a1d1;
}

.sidebar-dark2 .submenu .nav-item.active:not(.open) > .nav-link {
  font-weight: 600;
}

@media (min-width: 1200px) {
  .sidebar-dark2.hoverable.collapsed .submenu .nav-item.active.open > .nav-link, .sidebar-dark2.sidebar-hover:not(.collapsed) .submenu .nav-item.active.open > .nav-link {
    color: #8ebfe7;
    font-weight: 600;
  }
  .sidebar-dark2.hoverable.collapsed .submenu .nav-item.active > .nav-link > .nav-text::before, .sidebar-dark2.sidebar-hover:not(.collapsed) .submenu .nav-item.active > .nav-link > .nav-text::before {
    content: "";
    display: block;
    opacity: 1;
  }
}

@media (hover: hover) {
  .sidebar-dark2 .nav > .nav-item > .nav-link:hover::before {
    border-color: rgba(255, 255, 255, 0.75);
  }
}

.sidebar-dark2 .nav.has-active-border .nav-item.active > .nav-link::before {
  content: "";
  border-color: rgba(113, 200, 236, 0.8);
}

.sidebar-dark2 .nav.has-active-border > .nav-item.active:hover > .nav-link::before {
  border-color: rgba(113, 200, 236, 0.8);
}

@media (hover: hover) {
  .sidebar-dark2 .nav.has-active-border > .nav-item.active.open > .nav-link:hover::before {
    display: block;
  }
}

.sidebar-dark2 .submenu .nav-link {
  padding-left: 4em;
}

.sidebar-dark2 .submenu .submenu .nav-link {
  padding-left: 4.75em;
}

.sidebar-dark2 .submenu .submenu .submenu .nav-link {
  padding-left: 5.375em;
}

.sidebar-dark2 .submenu .nav-text::before {
  display: none;
  position: absolute;
  left: 3em;
  z-index: 1;
  opacity: 0.65;
  top: calc(50% - 0.1875rem);
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 0.75rem;
  background-color: currentColor;
}

.sidebar-dark2 .submenu .submenu .nav-text::before {
  left: 3.75em;
}

.sidebar-dark2 .submenu .submenu .submenu .nav-text::before {
  left: 4.375em;
}

.sidebar-dark2 .submenu .nav-item > .nav-link:hover > .nav-text::before {
  content: "";
  display: block;
  opacity: 0.8;
}

.sidebar-dark2 .submenu .nav-item.active:not(.open) > .nav-link > .nav-text::before {
  content: "";
  display: block;
  opacity: 1;
}

@media (min-width: 1200px) {
  .sidebar-dark2.hoverable.collapsed .submenu .nav-text::before, .sidebar-dark2.sidebar-hover:not(.collapsed) .submenu .nav-text::before {
    left: calc(0.5em + 1px) !important;
  }
}

.sidebar-dark2 .nav-item-caption {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.875rem;
}

.sidebar-dark2 .nav-item-divider {
  border-top: 1px solid rgba(208, 217, 221, 0.175);
}

.sidebar-dark2 .submenu .nav-item-divider {
  border-top-color: rgba(223, 229, 232, 0.175);
}

@media (min-width: 1200px) {
  .sidebar-dark2.hoverable.collapsed .sidebar-section-item .fadeable,
  .sidebar-dark2.hoverable.collapsed .nav > .nav-item > .nav-link > .nav-text,
  .sidebar-dark2.hoverable.collapsed .nav > .nav-item > .submenu {
    background-color: #1e2e38;
  }
  .sidebar-dark2.hoverable.collapsed .sub-arrow {
    border-color: #1e2e38 !important;
  }
}

@media (min-width: 1200px) {
  .sidebar-dark2.sidebar-hover:not(.collapsed) .sidebar-section-item .fadeable,
  .sidebar-dark2.sidebar-hover:not(.collapsed) .nav > .nav-item > .submenu, .sidebar-dark2.sidebar-h:not(.collapsed) .sidebar-section-item .fadeable,
  .sidebar-dark2.sidebar-h:not(.collapsed) .nav > .nav-item > .submenu {
    background-color: #1e2e38;
  }
  .sidebar-dark2.sidebar-hover:not(.collapsed) .sub-arrow, .sidebar-dark2.sidebar-h:not(.collapsed) .sub-arrow {
    border-color: #1e2e38 !important;
  }
}

.sidebar-dark2 .ace-scroll {
  scrollbar-color: rgba(255, 255, 255, 0.75) rgba(255, 255, 255, 0.33);
}

.sidebar-dark2 .ace-scroll::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.33);
  border-right: 1px solid #1e2e38;
}

.sidebar-dark2 .ace-scroll::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.75);
  border-right: 1px solid #1e2e38;
}

@media (min-width: 1200px) {
  .sidebar-dark2.toggling .nav > .nav-item.active.open {
    transition: none;
  }
  .sidebar-dark2.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.open:not(.active), .sidebar-dark2.hoverable.collapsed .nav > .nav-item.open:not(.active) {
    border-color: transparent;
    background-color: transparent;
  }
  .sidebar-dark2.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.open:not(.active) > .nav-link, .sidebar-dark2.hoverable.collapsed .nav > .nav-item.open:not(.active) > .nav-link {
    color: rgba(255, 255, 255, 0.88);
    background-color: transparent;
  }
  .sidebar-dark2.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.open:not(.active) > .nav-link > .nav-icon, .sidebar-dark2.hoverable.collapsed .nav > .nav-item.open:not(.active) > .nav-link > .nav-icon {
    color: rgba(187, 218, 238, 0.85);
  }
  .sidebar-dark2.expandable.collapsed .sidebar-inner:not(.is-hover) .nav > .nav-item.active.open, .sidebar-dark2.hoverable.collapsed .nav > .nav-item.active.open {
    transition: none;
  }
}

.sidebar-dark2 .sidebar-search-input {
  color: rgba(255, 255, 255, 0.85);
  border-bottom-color: rgba(255, 255, 255, 0.15);
}

.sidebar-dark2 .sidebar-search-input:focus {
  border-bottom-color: rgba(255, 255, 255, 0.5);
}

.sidebar-dark2 .sidebar-search-input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.sidebar-dark2 .sidebar-search-input::-moz-placeholder {
  color: #fff;
}

.sidebar-purple {
  background-color: #5f4e9a;
  --active-border-offset-right: 1px;
}

.sidebar-purple .sidebar-inner {
  background: inherit;
}

.sidebar-purple .nav.active-on-right .nav-item.active .nav-link::before {
  right: 1px;
}

@media (max-width: 1199.98px) {
  .sidebar-purple:not(.sidebar-fixed) .sidebar-inner {
    border-bottom: 1px solid #5f4e9a;
  }
}

.sidebar-purple .nav > .nav-item > .nav-link {
  color: #fff;
}

.sidebar-purple .caret {
  color: inherit;
  opacity: 0.75;
}

.sidebar-purple .open > .nav-link > .caret {
  color: inherit;
  opacity: 0.82;
}

.sidebar-purple .nav-item > .nav-link:hover > .caret {
  color: inherit;
  opacity: 0.82;
}

.sidebar-purple .nav-item.active > .nav-link > .caret {
  color: inherit;
  opacity: 0.82;
}

.sidebar-purple .nav-item.active.open > .nav-link > .caret {
  color: inherit;
  opacity: 0.92;
}

@media (min-width: 1200px) {
  .sidebar-purple.sidebar-h .nav > .nav-item.active {
    border-width: 0 1px;
  }
}

.sidebar-purple .nav > .nav-item.active > .nav-link {
  background-color: #a07e63;
}

.sidebar-purple .nav > .nav-item.active:not(.open) > .nav-link {
  font-weight: 600;
}

.sidebar-purple .submenu .nav-item.active > .nav-link {
  color: #e8c177;
}

.sidebar-purple .submenu .nav-item.active:not(.open) > .nav-link {
  font-weight: 600;
}

@media (min-width: 1200px) {
  .sidebar-purple.hoverable.collapsed .submenu .nav-item.active.open > .nav-link, .sidebar-purple.sidebar-hover:not(.collapsed) .submenu .nav-item.active.open > .nav-link {
    color: #e8c177;
    font-weight: 600;
  }
  .sidebar-purple.hoverable.collapsed .submenu .nav-item.active > .nav-link > .nav-text::before, .sidebar-purple.sidebar-hover:not(.collapsed) .submenu .nav-item.active > .nav-link > .nav-text::before {
    content: "";
    display: block;
    opacity: 1;
  }
}

.sidebar-purple .nav.has-active-border .nav-item.active > .nav-link::before {
  content: "";
  border-color: #e3c14e;
}

.sidebar-purple .submenu .nav-text::before {
  display: none;
  position: absolute;
  left: 2.75em;
  z-index: 1;
  opacity: 0.65;
  top: calc(50% - 0.375rem);
  width: 0;
  height: 0;
  border: 0.375rem solid transparent;
  border-left-color: currentColor;
  border-right-width: 0;
}

.sidebar-purple .submenu .submenu .nav-text::before {
  left: 3.25em;
}

.sidebar-purple .submenu .submenu .submenu .nav-text::before {
  left: 4em;
}

.sidebar-purple .submenu .nav-item.active:not(.open) > .nav-link > .nav-text::before {
  content: "";
  display: block;
  opacity: 1;
}

@media (min-width: 1200px) {
  .sidebar-purple.hoverable.collapsed .submenu .nav-text::before, .sidebar-purple.sidebar-hover:not(.collapsed) .submenu .nav-text::before {
    left: calc(0.5em + 1px) !important;
  }
}

@media (min-width: 1200px) {
  .sidebar-purple.hoverable.collapsed .sidebar-section-item .fadeable,
  .sidebar-purple.hoverable.collapsed .nav > .nav-item > .nav-link > .nav-text,
  .sidebar-purple.hoverable.collapsed .nav > .nav-item > .submenu {
    background-color: #5f4e9a;
  }
  .sidebar-purple.hoverable.collapsed .sub-arrow {
    border-color: #5f4e9a !important;
  }
}

@media (min-width: 1200px) {
  .sidebar-purple.sidebar-hover:not(.collapsed) .sidebar-section-item .fadeable,
  .sidebar-purple.sidebar-hover:not(.collapsed) .nav > .nav-item > .submenu, .sidebar-purple.sidebar-h:not(.collapsed) .sidebar-section-item .fadeable,
  .sidebar-purple.sidebar-h:not(.collapsed) .nav > .nav-item > .submenu {
    background-color: #5f4e9a;
  }
  .sidebar-purple.sidebar-hover:not(.collapsed) .sub-arrow, .sidebar-purple.sidebar-h:not(.collapsed) .sub-arrow {
    border-color: #5f4e9a !important;
  }
}

.sidebar-purple .ace-scroll {
}

.sidebar-purple .ace-scroll::-webkit-scrollbar-track {
  border-right: 1px solid #5f4e9a;
}

.sidebar-purple .ace-scroll::-webkit-scrollbar-thumb {
  border-right: 1px solid #5f4e9a;
}

.sidebar-plum {
  background-color: #633765;
  --active-border-offset-right: 1px;
}

.sidebar-plum .sidebar-inner {
  background: inherit;
}

.sidebar-plum .nav.active-on-right .nav-item.active .nav-link::before {
  right: 1px;
}

@media (max-width: 1199.98px) {
  .sidebar-plum:not(.sidebar-fixed) .sidebar-inner {
    border-bottom: 1px solid #633765;
  }
}

.sidebar-plum .caret {
  color: inherit;
  opacity: 0.75;
}

.sidebar-plum .open > .nav-link > .caret {
  color: inherit;
  opacity: 0.82;
}

.sidebar-plum .nav-item > .nav-link:hover > .caret {
  color: inherit;
  opacity: 0.82;
}

.sidebar-plum .nav-item.active > .nav-link > .caret {
  color: inherit;
  opacity: 0.82;
}

.sidebar-plum .nav-item.active.open > .nav-link > .caret {
  color: inherit;
  opacity: 0.92;
}

@media (min-width: 1200px) {
  .sidebar-plum.sidebar-h .nav > .nav-item.active {
    border-width: 0 1px;
  }
}

.sidebar-plum .nav > .nav-item.active > .nav-link {
  background-color: rgba(211, 175, 42, 0.45);
}

.sidebar-plum .nav > .nav-item.active:not(.open) > .nav-link {
  font-weight: 600;
}

.sidebar-plum .submenu .nav-item.active > .nav-link {
  color: #dcc286;
}

.sidebar-plum .submenu .nav-item.active:not(.open) > .nav-link {
  font-weight: 600;
}

@media (min-width: 1200px) {
  .sidebar-plum.hoverable.collapsed .submenu .nav-item.active.open > .nav-link, .sidebar-plum.sidebar-hover:not(.collapsed) .submenu .nav-item.active.open > .nav-link {
    color: #dcc286;
    font-weight: 600;
  }
  .sidebar-plum.hoverable.collapsed .submenu .nav-item.active > .nav-link > .nav-text::before, .sidebar-plum.sidebar-hover:not(.collapsed) .submenu .nav-item.active > .nav-link > .nav-text::before {
    content: "";
    display: block;
    opacity: 1;
  }
}

.sidebar-plum .nav.has-active-border .nav-item.active > .nav-link::before {
  content: "";
  border-color: #dbbd59;
}

.sidebar-plum .submenu .nav-text::before {
  display: none;
  position: absolute;
  left: 2.75em;
  z-index: 1;
  opacity: 0.65;
  top: calc(50% - 0.375rem);
  width: 0;
  height: 0;
  border: 0.375rem solid transparent;
  border-left-color: currentColor;
  border-right-width: 0;
}

.sidebar-plum .submenu .submenu .nav-text::before {
  left: 3.25em;
}

.sidebar-plum .submenu .submenu .submenu .nav-text::before {
  left: 4em;
}

.sidebar-plum .submenu .nav-item.active:not(.open) > .nav-link > .nav-text::before {
  content: "";
  display: block;
  opacity: 1;
}

@media (min-width: 1200px) {
  .sidebar-plum.hoverable.collapsed .submenu .nav-text::before, .sidebar-plum.sidebar-hover:not(.collapsed) .submenu .nav-text::before {
    left: calc(0.5em + 1px) !important;
  }
}

@media (min-width: 1200px) {
  .sidebar-plum.hoverable.collapsed .sidebar-section-item .fadeable,
  .sidebar-plum.hoverable.collapsed .nav > .nav-item > .nav-link > .nav-text,
  .sidebar-plum.hoverable.collapsed .nav > .nav-item > .submenu {
    background-color: #633765;
  }
  .sidebar-plum.hoverable.collapsed .sub-arrow {
    border-color: #633765 !important;
  }
}

@media (min-width: 1200px) {
  .sidebar-plum.sidebar-hover:not(.collapsed) .sidebar-section-item .fadeable,
  .sidebar-plum.sidebar-hover:not(.collapsed) .nav > .nav-item > .submenu, .sidebar-plum.sidebar-h:not(.collapsed) .sidebar-section-item .fadeable,
  .sidebar-plum.sidebar-h:not(.collapsed) .nav > .nav-item > .submenu {
    background-color: #633765;
  }
  .sidebar-plum.sidebar-hover:not(.collapsed) .sub-arrow, .sidebar-plum.sidebar-h:not(.collapsed) .sub-arrow {
    border-color: #633765 !important;
  }
}

.sidebar-plum .ace-scroll {
}

.sidebar-plum .ace-scroll::-webkit-scrollbar-track {
  border-right: 1px solid #633765;
}

.sidebar-plum .ace-scroll::-webkit-scrollbar-thumb {
  border-right: 1px solid #633765;
}

.sidebar-green {
  background-color: #3e674b;
  --active-border-offset-right: 1px;
}

.sidebar-green .sidebar-inner {
  background: inherit;
}

.sidebar-green .nav.active-on-right .nav-item.active .nav-link::before {
  right: 1px;
}

@media (max-width: 1199.98px) {
  .sidebar-green:not(.sidebar-fixed) .sidebar-inner {
    border-bottom: 1px solid #3e674b;
  }
}

.sidebar-green .nav > .nav-item {
  margin-bottom: 1px;
}

.sidebar-green .caret {
  color: inherit;
  opacity: 0.75;
}

.sidebar-green .open > .nav-link > .caret {
  color: inherit;
  opacity: 0.82;
}

.sidebar-green .nav-item > .nav-link:hover > .caret {
  color: inherit;
  opacity: 0.82;
}

.sidebar-green .nav-item.active > .nav-link > .caret {
  color: inherit;
  opacity: 0.82;
}

.sidebar-green .nav-item.active.open > .nav-link > .caret {
  color: inherit;
  opacity: 0.92;
}

@media (min-width: 1200px) {
  .sidebar-green.sidebar-h .nav > .nav-item.active {
    border-width: 0 1px;
  }
}

.sidebar-green .nav > .nav-item.active:not(.open) > .nav-link {
  font-weight: 600;
}

.sidebar-green .submenu .nav-item.active > .nav-link {
  color: #e6d170;
}

.sidebar-green .submenu .nav-item.active:not(.open) > .nav-link {
  font-weight: 600;
}

@media (min-width: 1200px) {
  .sidebar-green.hoverable.collapsed .submenu .nav-item.active.open > .nav-link, .sidebar-green.sidebar-hover:not(.collapsed) .submenu .nav-item.active.open > .nav-link {
    color: #e6d170;
    font-weight: 600;
  }
  .sidebar-green.hoverable.collapsed .submenu .nav-item.active > .nav-link > .nav-text::before, .sidebar-green.sidebar-hover:not(.collapsed) .submenu .nav-item.active > .nav-link > .nav-text::before {
    content: "";
    display: block;
    opacity: 1;
  }
}

.sidebar-green .nav.has-active-border .nav-item.active > .nav-link::before {
  content: "";
  border-color: rgba(240, 222, 142, 0.88);
}

.sidebar-green .submenu .nav-text::before {
  display: none;
  position: absolute;
  left: 2.75em;
  z-index: 1;
  opacity: 0.65;
  top: calc(50% - 0.1875rem);
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 0.75rem;
  background-color: currentColor;
}

.sidebar-green .submenu .submenu .nav-text::before {
  left: 3.25em;
}

.sidebar-green .submenu .submenu .submenu .nav-text::before {
  left: 4em;
}

.sidebar-green .submenu .nav-item.active:not(.open) > .nav-link > .nav-text::before {
  content: "";
  display: block;
  opacity: 1;
}

@media (min-width: 1200px) {
  .sidebar-green.hoverable.collapsed .submenu .nav-text::before, .sidebar-green.sidebar-hover:not(.collapsed) .submenu .nav-text::before {
    left: calc(0.5em + 1px) !important;
  }
}

@media (min-width: 1200px) {
  .sidebar-green.hoverable.collapsed .sidebar-section-item .fadeable,
  .sidebar-green.hoverable.collapsed .nav > .nav-item > .nav-link > .nav-text,
  .sidebar-green.hoverable.collapsed .nav > .nav-item > .submenu {
    background-color: #3e674b;
  }
  .sidebar-green.hoverable.collapsed .sub-arrow {
    border-color: #3e674b !important;
  }
}

@media (min-width: 1200px) {
  .sidebar-green.sidebar-hover:not(.collapsed) .sidebar-section-item .fadeable,
  .sidebar-green.sidebar-hover:not(.collapsed) .nav > .nav-item > .submenu, .sidebar-green.sidebar-h:not(.collapsed) .sidebar-section-item .fadeable,
  .sidebar-green.sidebar-h:not(.collapsed) .nav > .nav-item > .submenu {
    background-color: #3e674b;
  }
  .sidebar-green.sidebar-hover:not(.collapsed) .sub-arrow, .sidebar-green.sidebar-h:not(.collapsed) .sub-arrow {
    border-color: #3e674b !important;
  }
}

.sidebar-green .ace-scroll {
}

.sidebar-green .ace-scroll::-webkit-scrollbar-track {
  border-right: 1px solid #3e674b;
}

.sidebar-green .ace-scroll::-webkit-scrollbar-thumb {
  border-right: 1px solid #3e674b;
}

.sidebar-blue {
  background-color: #1d4687;
  --active-border-offset-right: 1px;
}

.sidebar-blue .sidebar-inner {
  background: inherit;
}

.sidebar-blue .nav.active-on-right .nav-item.active .nav-link::before {
  right: 1px;
}

@media (max-width: 1199.98px) {
  .sidebar-blue:not(.sidebar-fixed) .sidebar-inner {
    border-bottom: 1px solid #1d4687;
  }
}

.sidebar-blue .nav > .nav-item {
  margin-bottom: 1px;
}

.sidebar-blue .caret {
  color: inherit;
  opacity: 0.75;
}

.sidebar-blue .open > .nav-link > .caret {
  color: inherit;
  opacity: 0.82;
}

.sidebar-blue .nav-item > .nav-link:hover > .caret {
  color: inherit;
  opacity: 0.82;
}

.sidebar-blue .nav-item.active > .nav-link > .caret {
  color: inherit;
  opacity: 0.82;
}

.sidebar-blue .nav-item.active.open > .nav-link > .caret {
  color: inherit;
  opacity: 0.92;
}

@media (min-width: 1200px) {
  .sidebar-blue.sidebar-h .nav > .nav-item.active {
    border-width: 0 1px;
  }
}

.sidebar-blue .nav > .nav-item.active:not(.open) > .nav-link {
  font-weight: 600;
}

.sidebar-blue .submenu .nav-item.active > .nav-link {
  color: #f9e092;
}

.sidebar-blue .submenu .nav-item.active:not(.open) > .nav-link {
  font-weight: 600;
}

@media (min-width: 1200px) {
  .sidebar-blue.hoverable.collapsed .submenu .nav-item.active.open > .nav-link, .sidebar-blue.sidebar-hover:not(.collapsed) .submenu .nav-item.active.open > .nav-link {
    color: #f9e092;
    font-weight: 600;
  }
  .sidebar-blue.hoverable.collapsed .submenu .nav-item.active > .nav-link > .nav-text::before, .sidebar-blue.sidebar-hover:not(.collapsed) .submenu .nav-item.active > .nav-link > .nav-text::before {
    content: "";
    display: block;
    opacity: 1;
  }
}

.sidebar-blue .submenu .nav-text::before {
  display: none;
  position: absolute;
  left: 2.75em;
  z-index: 1;
  opacity: 0.65;
  top: calc(50% - 0.1875rem);
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 0.75rem;
  background-color: currentColor;
}

.sidebar-blue .submenu .submenu .nav-text::before {
  left: 3.25em;
}

.sidebar-blue .submenu .submenu .submenu .nav-text::before {
  left: 4em;
}

.sidebar-blue .submenu .nav-item.active:not(.open) > .nav-link > .nav-text::before {
  content: "";
  display: block;
  opacity: 1;
}

@media (min-width: 1200px) {
  .sidebar-blue.hoverable.collapsed .submenu .nav-text::before, .sidebar-blue.sidebar-hover:not(.collapsed) .submenu .nav-text::before {
    left: calc(0.5em + 1px) !important;
  }
}

@media (min-width: 1200px) {
  .sidebar-blue.hoverable.collapsed .sidebar-section-item .fadeable,
  .sidebar-blue.hoverable.collapsed .nav > .nav-item > .nav-link > .nav-text,
  .sidebar-blue.hoverable.collapsed .nav > .nav-item > .submenu {
    background-color: #1d4687;
  }
  .sidebar-blue.hoverable.collapsed .sub-arrow {
    border-color: #1d4687 !important;
  }
}

@media (min-width: 1200px) {
  .sidebar-blue.sidebar-hover:not(.collapsed) .sidebar-section-item .fadeable,
  .sidebar-blue.sidebar-hover:not(.collapsed) .nav > .nav-item > .submenu, .sidebar-blue.sidebar-h:not(.collapsed) .sidebar-section-item .fadeable,
  .sidebar-blue.sidebar-h:not(.collapsed) .nav > .nav-item > .submenu {
    background-color: #1d4687;
  }
  .sidebar-blue.sidebar-hover:not(.collapsed) .sub-arrow, .sidebar-blue.sidebar-h:not(.collapsed) .sub-arrow {
    border-color: #1d4687 !important;
  }
}

.sidebar-blue .ace-scroll {
}

.sidebar-blue .ace-scroll::-webkit-scrollbar-track {
  border-right: 1px solid #1d4687;
}

.sidebar-blue .ace-scroll::-webkit-scrollbar-thumb {
  border-right: 1px solid #1d4687;
}

.sidebar-darkblue {
  background-color: #2d4a5e;
  --active-border-offset-right: 1px;
}

.sidebar-darkblue .sidebar-inner {
  background: inherit;
}

.sidebar-darkblue .nav.active-on-right .nav-item.active .nav-link::before {
  right: 1px;
}

@media (max-width: 1199.98px) {
  .sidebar-darkblue:not(.sidebar-fixed) .sidebar-inner {
    border-bottom: 1px solid #2d4a5e;
  }
}

.sidebar-darkblue .nav > .nav-item {
  margin-bottom: 1px;
}

.sidebar-darkblue.sidebar-spaced .nav > .nav-item.open:not(.active) > .nav-link {
  background-color: #496579;
}

.sidebar-darkblue .nav > .nav-item > .nav-link:hover {
  background-color: #496579;
}

.sidebar-darkblue .nav > .nav-item > .submenu {
  background-color: rgba(0, 0, 0, 0.15);
}

.sidebar-darkblue .caret {
  color: inherit;
  opacity: 0.75;
}

.sidebar-darkblue .open > .nav-link > .caret {
  color: inherit;
  opacity: 0.82;
}

.sidebar-darkblue .nav-item > .nav-link:hover > .caret {
  color: inherit;
  opacity: 0.82;
}

.sidebar-darkblue .nav-item.active > .nav-link > .caret {
  color: inherit;
  opacity: 0.82;
}

.sidebar-darkblue .nav-item.active.open > .nav-link > .caret {
  color: inherit;
  opacity: 0.92;
}

@media (min-width: 1200px) {
  .sidebar-darkblue.sidebar-h .nav > .nav-item.active {
    border-width: 0 1px;
  }
}

.sidebar-darkblue .nav > .nav-item.active > .nav-link {
  background-color: #4b7293;
}

.sidebar-darkblue .nav > .nav-item.active:not(.open) > .nav-link {
  font-weight: 600;
}

.sidebar-darkblue .submenu .nav-item.active > .nav-link {
  color: #9ad3fc;
}

.sidebar-darkblue .submenu .nav-item.active:not(.open) > .nav-link {
  font-weight: 600;
}

@media (min-width: 1200px) {
  .sidebar-darkblue.hoverable.collapsed .submenu .nav-item.active.open > .nav-link, .sidebar-darkblue.sidebar-hover:not(.collapsed) .submenu .nav-item.active.open > .nav-link {
    color: #9ad3fc;
    font-weight: 600;
  }
  .sidebar-darkblue.hoverable.collapsed .submenu .nav-item.active > .nav-link > .nav-text::before, .sidebar-darkblue.sidebar-hover:not(.collapsed) .submenu .nav-item.active > .nav-link > .nav-text::before {
    content: "";
    display: block;
    opacity: 1;
  }
}

.sidebar-darkblue .nav.has-active-border .nav-item.active > .nav-link::before {
  content: "";
  border-color: #9ad3fc;
}

.sidebar-darkblue .submenu .nav-text::before {
  display: none;
  position: absolute;
  left: 2.75em;
  z-index: 1;
  opacity: 0.65;
  top: calc(50% - 0.375rem);
  width: 0;
  height: 0;
  border: 0.375rem solid transparent;
  border-left-color: currentColor;
  border-right-width: 0;
}

.sidebar-darkblue .submenu .submenu .nav-text::before {
  left: 3.25em;
}

.sidebar-darkblue .submenu .submenu .submenu .nav-text::before {
  left: 4em;
}

.sidebar-darkblue .submenu .nav-item.active:not(.open) > .nav-link > .nav-text::before {
  content: "";
  display: block;
  opacity: 1;
}

@media (min-width: 1200px) {
  .sidebar-darkblue.hoverable.collapsed .submenu .nav-text::before, .sidebar-darkblue.sidebar-hover:not(.collapsed) .submenu .nav-text::before {
    left: calc(0.5em + 1px) !important;
  }
}

@media (min-width: 1200px) {
  .sidebar-darkblue.hoverable.collapsed .sidebar-section-item .fadeable,
  .sidebar-darkblue.hoverable.collapsed .nav > .nav-item > .nav-link > .nav-text,
  .sidebar-darkblue.hoverable.collapsed .nav > .nav-item > .submenu {
    background-color: #2d4a5e;
  }
  .sidebar-darkblue.hoverable.collapsed .sub-arrow {
    border-color: #2d4a5e !important;
  }
}

@media (min-width: 1200px) {
  .sidebar-darkblue.sidebar-hover:not(.collapsed) .sidebar-section-item .fadeable,
  .sidebar-darkblue.sidebar-hover:not(.collapsed) .nav > .nav-item > .submenu, .sidebar-darkblue.sidebar-h:not(.collapsed) .sidebar-section-item .fadeable,
  .sidebar-darkblue.sidebar-h:not(.collapsed) .nav > .nav-item > .submenu {
    background-color: #2d4a5e;
  }
  .sidebar-darkblue.sidebar-hover:not(.collapsed) .sub-arrow, .sidebar-darkblue.sidebar-h:not(.collapsed) .sub-arrow {
    border-color: #2d4a5e !important;
  }
}

.sidebar-darkblue .ace-scroll {
}

.sidebar-darkblue .ace-scroll::-webkit-scrollbar-track {
  border-right: 1px solid #2d4a5e;
}

.sidebar-darkblue .ace-scroll::-webkit-scrollbar-thumb {
  border-right: 1px solid #2d4a5e;
}

.sidebar-steelblue {
  background-color: #195e98;
  --active-border-offset-right: 1px;
}

.sidebar-steelblue .sidebar-inner {
  background: inherit;
}

.sidebar-steelblue .nav.active-on-right .nav-item.active .nav-link::before {
  right: 1px;
}

@media (max-width: 1199.98px) {
  .sidebar-steelblue:not(.sidebar-fixed) .sidebar-inner {
    border-bottom: 1px solid #195e98;
  }
}

.sidebar-steelblue .nav > .nav-item {
  margin-bottom: 1px;
}

.sidebar-steelblue .caret {
  color: inherit;
  opacity: 0.75;
}

.sidebar-steelblue .open > .nav-link > .caret {
  color: inherit;
  opacity: 0.82;
}

.sidebar-steelblue .nav-item > .nav-link:hover > .caret {
  color: inherit;
  opacity: 0.82;
}

.sidebar-steelblue .nav-item.active > .nav-link > .caret {
  color: inherit;
  opacity: 0.82;
}

.sidebar-steelblue .nav-item.active.open > .nav-link > .caret {
  color: inherit;
  opacity: 0.92;
}

@media (min-width: 1200px) {
  .sidebar-steelblue.sidebar-h .nav > .nav-item.active {
    border-width: 0 1px;
  }
}

.sidebar-steelblue .nav > .nav-item.active > .nav-link {
  background-color: rgba(211, 168, 32, 0.75);
}

.sidebar-steelblue .nav > .nav-item.active:not(.open) > .nav-link {
  font-weight: 600;
}

.sidebar-steelblue .submenu .nav-item.active > .nav-link {
  color: #f9e092;
}

.sidebar-steelblue .submenu .nav-item.active:not(.open) > .nav-link {
  font-weight: 600;
}

@media (min-width: 1200px) {
  .sidebar-steelblue.hoverable.collapsed .submenu .nav-item.active.open > .nav-link, .sidebar-steelblue.sidebar-hover:not(.collapsed) .submenu .nav-item.active.open > .nav-link {
    color: #f9e092;
    font-weight: 600;
  }
  .sidebar-steelblue.hoverable.collapsed .submenu .nav-item.active > .nav-link > .nav-text::before, .sidebar-steelblue.sidebar-hover:not(.collapsed) .submenu .nav-item.active > .nav-link > .nav-text::before {
    content: "";
    display: block;
    opacity: 1;
  }
}

@media (hover: hover) {
  .sidebar-steelblue .nav > .nav-item > .nav-link:hover::before {
    border-color: rgba(255, 255, 255, 0.8);
  }
}

.sidebar-steelblue .nav.has-active-border .nav-item.active > .nav-link::before {
  content: "";
  border-color: rgba(255, 255, 255, 0.93);
}

.sidebar-steelblue .nav.has-active-border > .nav-item.active:hover > .nav-link::before {
  border-color: rgba(255, 255, 255, 0.93);
}

@media (hover: hover) {
  .sidebar-steelblue .nav.has-active-border > .nav-item.active.open > .nav-link:hover::before {
    display: block;
  }
}

.sidebar-steelblue .submenu .nav-text::before {
  display: none;
  position: absolute;
  left: 2.75em;
  z-index: 1;
  opacity: 0.65;
  top: calc(50% - 0.1875rem);
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 0.75rem;
  background-color: currentColor;
}

.sidebar-steelblue .submenu .submenu .nav-text::before {
  left: 3.25em;
}

.sidebar-steelblue .submenu .submenu .submenu .nav-text::before {
  left: 4em;
}

.sidebar-steelblue .submenu .nav-item.active:not(.open) > .nav-link > .nav-text::before {
  content: "";
  display: block;
  opacity: 1;
}

@media (min-width: 1200px) {
  .sidebar-steelblue.hoverable.collapsed .submenu .nav-text::before, .sidebar-steelblue.sidebar-hover:not(.collapsed) .submenu .nav-text::before {
    left: calc(0.5em + 1px) !important;
  }
}

@media (min-width: 1200px) {
  .sidebar-steelblue.hoverable.collapsed .sidebar-section-item .fadeable,
  .sidebar-steelblue.hoverable.collapsed .nav > .nav-item > .nav-link > .nav-text,
  .sidebar-steelblue.hoverable.collapsed .nav > .nav-item > .submenu {
    background-color: #195e98;
  }
  .sidebar-steelblue.hoverable.collapsed .sub-arrow {
    border-color: #195e98 !important;
  }
}

@media (min-width: 1200px) {
  .sidebar-steelblue.sidebar-hover:not(.collapsed) .sidebar-section-item .fadeable,
  .sidebar-steelblue.sidebar-hover:not(.collapsed) .nav > .nav-item > .submenu, .sidebar-steelblue.sidebar-h:not(.collapsed) .sidebar-section-item .fadeable,
  .sidebar-steelblue.sidebar-h:not(.collapsed) .nav > .nav-item > .submenu {
    background-color: #195e98;
  }
  .sidebar-steelblue.sidebar-hover:not(.collapsed) .sub-arrow, .sidebar-steelblue.sidebar-h:not(.collapsed) .sub-arrow {
    border-color: #195e98 !important;
  }
}

.sidebar-steelblue .ace-scroll {
}

.sidebar-steelblue .ace-scroll::-webkit-scrollbar-track {
  border-right: 1px solid #195e98;
}

.sidebar-steelblue .ace-scroll::-webkit-scrollbar-thumb {
  border-right: 1px solid #195e98;
}

.sidebar-cadetblue {
  background-color: #226467;
  --active-border-offset-right: 1px;
}

.sidebar-cadetblue .sidebar-inner {
  background: inherit;
}

.sidebar-cadetblue .nav.active-on-right .nav-item.active .nav-link::before {
  right: 1px;
}

@media (max-width: 1199.98px) {
  .sidebar-cadetblue:not(.sidebar-fixed) .sidebar-inner {
    border-bottom: 1px solid #226467;
  }
}

.sidebar-cadetblue .nav > .nav-item {
  margin-bottom: 1px;
}

.sidebar-cadetblue .nav > .nav-item > .nav-link {
  color: rgba(255, 255, 255, 0.92);
}

.sidebar-cadetblue .nav > .nav-item > .nav-link > .nav-icon {
  color: rgba(255, 255, 255, 0.82);
}

.sidebar-cadetblue .caret {
  color: inherit;
  opacity: 0.75;
}

.sidebar-cadetblue .open > .nav-link > .caret {
  color: inherit;
  opacity: 0.82;
}

.sidebar-cadetblue .nav-item > .nav-link:hover > .caret {
  color: inherit;
  opacity: 0.82;
}

.sidebar-cadetblue .nav-item.active > .nav-link > .caret {
  color: inherit;
  opacity: 0.82;
}

.sidebar-cadetblue .nav-item.active.open > .nav-link > .caret {
  color: inherit;
  opacity: 0.92;
}

@media (min-width: 1200px) {
  .sidebar-cadetblue.sidebar-h .nav > .nav-item.active {
    border-width: 0 1px;
  }
}

.sidebar-cadetblue .nav > .nav-item.active > .nav-link {
  background-color: rgba(255, 255, 255, 0.12);
}

.sidebar-cadetblue .nav > .nav-item.active:not(.open) > .nav-link {
  font-weight: 600;
}

.sidebar-cadetblue .submenu .nav-item.active > .nav-link {
  color: #fed5a1;
}

.sidebar-cadetblue .submenu .nav-item.active:not(.open) > .nav-link {
  font-weight: 600;
}

@media (min-width: 1200px) {
  .sidebar-cadetblue.hoverable.collapsed .submenu .nav-item.active.open > .nav-link, .sidebar-cadetblue.sidebar-hover:not(.collapsed) .submenu .nav-item.active.open > .nav-link {
    color: #fed5a1;
    font-weight: 600;
  }
  .sidebar-cadetblue.hoverable.collapsed .submenu .nav-item.active > .nav-link > .nav-text::before, .sidebar-cadetblue.sidebar-hover:not(.collapsed) .submenu .nav-item.active > .nav-link > .nav-text::before {
    content: "";
    display: block;
    opacity: 1;
  }
}

@media (hover: hover) {
  .sidebar-cadetblue .nav > .nav-item > .nav-link:hover::before {
    border-color: rgba(255, 255, 255, 0.73);
  }
}

.sidebar-cadetblue .nav.has-active-border .nav-item.active > .nav-link::before {
  content: "";
  border-color: #f4b76a;
}

.sidebar-cadetblue .nav.has-active-border > .nav-item.active:hover > .nav-link::before {
  border-color: #f4b76a;
}

@media (hover: hover) {
  .sidebar-cadetblue .nav.has-active-border > .nav-item.active.open > .nav-link:hover::before {
    display: block;
  }
}

.sidebar-cadetblue .submenu .nav-text::before {
  display: none;
  position: absolute;
  left: 2.75em;
  z-index: 1;
  opacity: 0.65;
  top: calc(50% - 0.1875rem);
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 0.75rem;
  background-color: currentColor;
}

.sidebar-cadetblue .submenu .submenu .nav-text::before {
  left: 3.25em;
}

.sidebar-cadetblue .submenu .submenu .submenu .nav-text::before {
  left: 4em;
}

.sidebar-cadetblue .submenu .nav-item.active:not(.open) > .nav-link > .nav-text::before {
  content: "";
  display: block;
  opacity: 1;
}

@media (min-width: 1200px) {
  .sidebar-cadetblue.hoverable.collapsed .submenu .nav-text::before, .sidebar-cadetblue.sidebar-hover:not(.collapsed) .submenu .nav-text::before {
    left: calc(0.5em + 1px) !important;
  }
}

@media (min-width: 1200px) {
  .sidebar-cadetblue.hoverable.collapsed .sidebar-section-item .fadeable,
  .sidebar-cadetblue.hoverable.collapsed .nav > .nav-item > .nav-link > .nav-text,
  .sidebar-cadetblue.hoverable.collapsed .nav > .nav-item > .submenu {
    background-color: #226467;
  }
  .sidebar-cadetblue.hoverable.collapsed .sub-arrow {
    border-color: #226467 !important;
  }
}

@media (min-width: 1200px) {
  .sidebar-cadetblue.sidebar-hover:not(.collapsed) .sidebar-section-item .fadeable,
  .sidebar-cadetblue.sidebar-hover:not(.collapsed) .nav > .nav-item > .submenu, .sidebar-cadetblue.sidebar-h:not(.collapsed) .sidebar-section-item .fadeable,
  .sidebar-cadetblue.sidebar-h:not(.collapsed) .nav > .nav-item > .submenu {
    background-color: #226467;
  }
  .sidebar-cadetblue.sidebar-hover:not(.collapsed) .sub-arrow, .sidebar-cadetblue.sidebar-h:not(.collapsed) .sub-arrow {
    border-color: #226467 !important;
  }
}

.sidebar-cadetblue .ace-scroll {
}

.sidebar-cadetblue .ace-scroll::-webkit-scrollbar-track {
  border-right: 1px solid #226467;
}

.sidebar-cadetblue .ace-scroll::-webkit-scrollbar-thumb {
  border-right: 1px solid #226467;
}

.sidebar-darkslateblue {
  background-color: #463e7e;
  --active-border-offset-right: 1px;
}

.sidebar-darkslateblue .sidebar-inner {
  background: inherit;
}

.sidebar-darkslateblue .nav.active-on-right .nav-item.active .nav-link::before {
  right: 1px;
}

@media (max-width: 1199.98px) {
  .sidebar-darkslateblue:not(.sidebar-fixed) .sidebar-inner {
    border-bottom: 1px solid #463e7e;
  }
}

.sidebar-darkslateblue .nav > .nav-item {
  margin-bottom: 1px;
}

.sidebar-darkslateblue .caret {
  color: inherit;
  opacity: 0.75;
}

.sidebar-darkslateblue .open > .nav-link > .caret {
  color: inherit;
  opacity: 0.82;
}

.sidebar-darkslateblue .nav-item > .nav-link:hover > .caret {
  color: inherit;
  opacity: 0.82;
}

.sidebar-darkslateblue .nav-item.active > .nav-link > .caret {
  color: inherit;
  opacity: 0.82;
}

.sidebar-darkslateblue .nav-item.active.open > .nav-link > .caret {
  color: inherit;
  opacity: 0.92;
}

@media (min-width: 1200px) {
  .sidebar-darkslateblue.sidebar-h .nav > .nav-item.active {
    border-width: 0 1px;
  }
}

.sidebar-darkslateblue .nav > .nav-item.active > .nav-link {
  background-color: #a19c2c;
}

.sidebar-darkslateblue .nav > .nav-item.active:not(.open) > .nav-link {
  font-weight: 600;
}

.sidebar-darkslateblue .submenu .nav-item.active > .nav-link {
  color: #e3dd65;
}

.sidebar-darkslateblue .submenu .nav-item.active:not(.open) > .nav-link {
  background-color: rgba(255, 255, 255, 0.1);
  font-weight: 600;
}

@media (min-width: 1200px) {
  .sidebar-darkslateblue.hoverable.collapsed .submenu .nav-item.active.open > .nav-link, .sidebar-darkslateblue.sidebar-hover:not(.collapsed) .submenu .nav-item.active.open > .nav-link {
    color: #e3dd65;
    background-color: rgba(255, 255, 255, 0.1);
    font-weight: 600;
  }
}

@media (hover: hover) {
  .sidebar-darkslateblue .nav > .nav-item > .nav-link:hover::before {
    border-color: rgba(255, 255, 255, 0.8);
  }
}

.sidebar-darkslateblue .nav.has-active-border .nav-item.active > .nav-link::before {
  content: "";
  border-color: rgba(255, 255, 255, 0.93);
}

.sidebar-darkslateblue .nav.has-active-border > .nav-item.active:hover > .nav-link::before {
  border-color: rgba(255, 255, 255, 0.93);
}

@media (hover: hover) {
  .sidebar-darkslateblue .nav.has-active-border > .nav-item.active.open > .nav-link:hover::before {
    display: block;
  }
}

@media (min-width: 1200px) {
  .sidebar-darkslateblue.hoverable.collapsed .sidebar-section-item .fadeable,
  .sidebar-darkslateblue.hoverable.collapsed .nav > .nav-item > .nav-link > .nav-text,
  .sidebar-darkslateblue.hoverable.collapsed .nav > .nav-item > .submenu {
    background-color: #463e7e;
  }
  .sidebar-darkslateblue.hoverable.collapsed .sub-arrow {
    border-color: #463e7e !important;
  }
}

@media (min-width: 1200px) {
  .sidebar-darkslateblue.sidebar-hover:not(.collapsed) .sidebar-section-item .fadeable,
  .sidebar-darkslateblue.sidebar-hover:not(.collapsed) .nav > .nav-item > .submenu, .sidebar-darkslateblue.sidebar-h:not(.collapsed) .sidebar-section-item .fadeable,
  .sidebar-darkslateblue.sidebar-h:not(.collapsed) .nav > .nav-item > .submenu {
    background-color: #463e7e;
  }
  .sidebar-darkslateblue.sidebar-hover:not(.collapsed) .sub-arrow, .sidebar-darkslateblue.sidebar-h:not(.collapsed) .sub-arrow {
    border-color: #463e7e !important;
  }
}

.sidebar-darkslateblue .ace-scroll {
}

.sidebar-darkslateblue .ace-scroll::-webkit-scrollbar-track {
  border-right: 1px solid #463e7e;
}

.sidebar-darkslateblue .ace-scroll::-webkit-scrollbar-thumb {
  border-right: 1px solid #463e7e;
}

.sidebar-darkcrimson {
  background-color: #653e45;
  --active-border-offset-right: 1px;
}

.sidebar-darkcrimson .sidebar-inner {
  background: inherit;
}

.sidebar-darkcrimson .nav.active-on-right .nav-item.active .nav-link::before {
  right: 1px;
}

@media (max-width: 1199.98px) {
  .sidebar-darkcrimson:not(.sidebar-fixed) .sidebar-inner {
    border-bottom: 1px solid #653e45;
  }
}

.sidebar-darkcrimson .caret {
  color: inherit;
  opacity: 0.75;
}

.sidebar-darkcrimson .open > .nav-link > .caret {
  color: inherit;
  opacity: 0.82;
}

.sidebar-darkcrimson .nav-item > .nav-link:hover > .caret {
  color: inherit;
  opacity: 0.82;
}

.sidebar-darkcrimson .nav-item.active > .nav-link > .caret {
  color: inherit;
  opacity: 0.82;
}

.sidebar-darkcrimson .nav-item.active.open > .nav-link > .caret {
  color: inherit;
  opacity: 0.92;
}

@media (min-width: 1200px) {
  .sidebar-darkcrimson.sidebar-h .nav > .nav-item.active {
    border-width: 0 1px;
  }
}

.sidebar-darkcrimson .nav > .nav-item.active > .nav-link {
  background-color: #77785e;
}

.sidebar-darkcrimson .nav > .nav-item.active:not(.open) > .nav-link {
  font-weight: 600;
}

.sidebar-darkcrimson .submenu .nav-item.active > .nav-link {
  color: #b7d7a9;
}

.sidebar-darkcrimson .submenu .nav-item.active:not(.open) > .nav-link {
  font-weight: 600;
}

@media (min-width: 1200px) {
  .sidebar-darkcrimson.hoverable.collapsed .submenu .nav-item.active.open > .nav-link, .sidebar-darkcrimson.sidebar-hover:not(.collapsed) .submenu .nav-item.active.open > .nav-link {
    color: #b7d7a9;
    font-weight: 600;
  }
  .sidebar-darkcrimson.hoverable.collapsed .submenu .nav-item.active > .nav-link > .nav-text::before, .sidebar-darkcrimson.sidebar-hover:not(.collapsed) .submenu .nav-item.active > .nav-link > .nav-text::before {
    content: "";
    display: block;
    opacity: 1;
  }
}

.sidebar-darkcrimson .submenu .nav-text::before {
  display: none;
  position: absolute;
  left: 2.75em;
  z-index: 1;
  opacity: 0.65;
  top: calc(50% - 0.1875rem);
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 0.75rem;
  background-color: currentColor;
}

.sidebar-darkcrimson .submenu .submenu .nav-text::before {
  left: 3.25em;
}

.sidebar-darkcrimson .submenu .submenu .submenu .nav-text::before {
  left: 4em;
}

.sidebar-darkcrimson .submenu .nav-item.active:not(.open) > .nav-link > .nav-text::before {
  content: "";
  display: block;
  opacity: 1;
}

@media (min-width: 1200px) {
  .sidebar-darkcrimson.hoverable.collapsed .submenu .nav-text::before, .sidebar-darkcrimson.sidebar-hover:not(.collapsed) .submenu .nav-text::before {
    left: calc(0.5em + 1px) !important;
  }
}

@media (min-width: 1200px) {
  .sidebar-darkcrimson.hoverable.collapsed .sidebar-section-item .fadeable,
  .sidebar-darkcrimson.hoverable.collapsed .nav > .nav-item > .nav-link > .nav-text,
  .sidebar-darkcrimson.hoverable.collapsed .nav > .nav-item > .submenu {
    background-color: #653e45;
  }
  .sidebar-darkcrimson.hoverable.collapsed .sub-arrow {
    border-color: #653e45 !important;
  }
}

@media (min-width: 1200px) {
  .sidebar-darkcrimson.sidebar-hover:not(.collapsed) .sidebar-section-item .fadeable,
  .sidebar-darkcrimson.sidebar-hover:not(.collapsed) .nav > .nav-item > .submenu, .sidebar-darkcrimson.sidebar-h:not(.collapsed) .sidebar-section-item .fadeable,
  .sidebar-darkcrimson.sidebar-h:not(.collapsed) .nav > .nav-item > .submenu {
    background-color: #653e45;
  }
  .sidebar-darkcrimson.sidebar-hover:not(.collapsed) .sub-arrow, .sidebar-darkcrimson.sidebar-h:not(.collapsed) .sub-arrow {
    border-color: #653e45 !important;
  }
}

.sidebar-darkcrimson .ace-scroll {
}

.sidebar-darkcrimson .ace-scroll::-webkit-scrollbar-track {
  border-right: 1px solid #653e45;
}

.sidebar-darkcrimson .ace-scroll::-webkit-scrollbar-thumb {
  border-right: 1px solid #653e45;
}

.sidebar-darkslategrey {
  background-color: darkslategrey;
  --active-border-offset-right: 1px;
}

.sidebar-darkslategrey .sidebar-inner {
  background: inherit;
}

.sidebar-darkslategrey .nav.active-on-right .nav-item.active .nav-link::before {
  right: 1px;
}

@media (max-width: 1199.98px) {
  .sidebar-darkslategrey:not(.sidebar-fixed) .sidebar-inner {
    border-bottom: 1px solid darkslategrey;
  }
}

.sidebar-darkslategrey .caret {
  color: inherit;
  opacity: 0.75;
}

.sidebar-darkslategrey .open > .nav-link > .caret {
  color: inherit;
  opacity: 0.82;
}

.sidebar-darkslategrey .nav-item > .nav-link:hover > .caret {
  color: inherit;
  opacity: 0.82;
}

.sidebar-darkslategrey .nav-item.active > .nav-link > .caret {
  color: inherit;
  opacity: 0.82;
}

.sidebar-darkslategrey .nav-item.active.open > .nav-link > .caret {
  color: inherit;
  opacity: 0.92;
}

@media (min-width: 1200px) {
  .sidebar-darkslategrey.sidebar-h .nav > .nav-item.active {
    border-width: 0 1px;
  }
}

.sidebar-darkslategrey .nav > .nav-item.active > .nav-link {
  background-color: rgba(136, 192, 60, 0.5);
}

.sidebar-darkslategrey .nav > .nav-item.active:not(.open) > .nav-link {
  font-weight: 600;
}

.sidebar-darkslategrey .submenu .nav-item.active > .nav-link {
  color: #b6da88;
}

.sidebar-darkslategrey .submenu .nav-item.active:not(.open) > .nav-link {
  font-weight: 600;
}

@media (min-width: 1200px) {
  .sidebar-darkslategrey.hoverable.collapsed .submenu .nav-item.active.open > .nav-link, .sidebar-darkslategrey.sidebar-hover:not(.collapsed) .submenu .nav-item.active.open > .nav-link {
    color: #b6da88;
    font-weight: 600;
  }
  .sidebar-darkslategrey.hoverable.collapsed .submenu .nav-item.active > .nav-link > .nav-text::before, .sidebar-darkslategrey.sidebar-hover:not(.collapsed) .submenu .nav-item.active > .nav-link > .nav-text::before {
    content: "";
    display: block;
    opacity: 1;
  }
}

.sidebar-darkslategrey .submenu .nav-text::before {
  display: none;
  position: absolute;
  left: 2.75em;
  z-index: 1;
  opacity: 0.65;
  top: calc(50% - 0.1875rem);
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 0.75rem;
  background-color: currentColor;
}

.sidebar-darkslategrey .submenu .submenu .nav-text::before {
  left: 3.25em;
}

.sidebar-darkslategrey .submenu .submenu .submenu .nav-text::before {
  left: 4em;
}

.sidebar-darkslategrey .submenu .nav-item.active:not(.open) > .nav-link > .nav-text::before {
  content: "";
  display: block;
  opacity: 1;
}

@media (min-width: 1200px) {
  .sidebar-darkslategrey.hoverable.collapsed .submenu .nav-text::before, .sidebar-darkslategrey.sidebar-hover:not(.collapsed) .submenu .nav-text::before {
    left: calc(0.5em + 1px) !important;
  }
}

@media (min-width: 1200px) {
  .sidebar-darkslategrey.hoverable.collapsed .sidebar-section-item .fadeable,
  .sidebar-darkslategrey.hoverable.collapsed .nav > .nav-item > .nav-link > .nav-text,
  .sidebar-darkslategrey.hoverable.collapsed .nav > .nav-item > .submenu {
    background-color: darkslategrey;
  }
  .sidebar-darkslategrey.hoverable.collapsed .sub-arrow {
    border-color: darkslategrey !important;
  }
}

@media (min-width: 1200px) {
  .sidebar-darkslategrey.sidebar-hover:not(.collapsed) .sidebar-section-item .fadeable,
  .sidebar-darkslategrey.sidebar-hover:not(.collapsed) .nav > .nav-item > .submenu, .sidebar-darkslategrey.sidebar-h:not(.collapsed) .sidebar-section-item .fadeable,
  .sidebar-darkslategrey.sidebar-h:not(.collapsed) .nav > .nav-item > .submenu {
    background-color: darkslategrey;
  }
  .sidebar-darkslategrey.sidebar-hover:not(.collapsed) .sub-arrow, .sidebar-darkslategrey.sidebar-h:not(.collapsed) .sub-arrow {
    border-color: darkslategrey !important;
  }
}

.sidebar-darkslategrey .ace-scroll {
}

.sidebar-darkslategrey .ace-scroll::-webkit-scrollbar-track {
  border-right: 1px solid darkslategrey;
}

.sidebar-darkslategrey .ace-scroll::-webkit-scrollbar-thumb {
  border-right: 1px solid darkslategrey;
}

.sidebar-gradient1 {
  background-color: #2d5365;
  background-image: linear-gradient(#237b49, #362a81);
  --active-border-offset-right: 1px;
}

.sidebar-gradient1 .sidebar-inner {
  background: inherit;
}

.sidebar-gradient1 .nav.active-on-right .nav-item.active .nav-link::before {
  right: 1px;
}

@media (max-width: 1199.98px) {
  .sidebar-gradient1:not(.sidebar-fixed) .sidebar-inner {
    border-bottom: 1px solid #2d5365;
  }
}

.sidebar-gradient1 .nav > .nav-item {
  margin-bottom: 1px;
}

.sidebar-gradient1 .nav > .nav-item > .nav-link > .nav-icon {
  color: rgba(255, 255, 255, 0.66);
}

.sidebar-gradient1 .caret {
  color: inherit;
  opacity: 0.75;
}

.sidebar-gradient1 .open > .nav-link > .caret {
  color: inherit;
  opacity: 0.82;
}

.sidebar-gradient1 .nav-item > .nav-link:hover > .caret {
  color: inherit;
  opacity: 0.82;
}

.sidebar-gradient1 .nav-item.active > .nav-link > .caret {
  color: inherit;
  opacity: 0.82;
}

.sidebar-gradient1 .nav-item.active.open > .nav-link > .caret {
  color: inherit;
  opacity: 0.92;
}

@media (min-width: 1200px) {
  .sidebar-gradient1.sidebar-h .nav > .nav-item.active {
    border-width: 0 1px;
  }
}

.sidebar-gradient1 .nav > .nav-item.active:not(.open) > .nav-link {
  font-weight: 600;
}

.sidebar-gradient1 .submenu .nav-item.active > .nav-link {
  color: #f5e685;
}

.sidebar-gradient1 .submenu .nav-item.active:not(.open) > .nav-link {
  font-weight: 600;
}

@media (min-width: 1200px) {
  .sidebar-gradient1.hoverable.collapsed .submenu .nav-item.active.open > .nav-link, .sidebar-gradient1.sidebar-hover:not(.collapsed) .submenu .nav-item.active.open > .nav-link {
    color: #f5e685;
    font-weight: 600;
  }
  .sidebar-gradient1.hoverable.collapsed .submenu .nav-item.active > .nav-link > .nav-text::before, .sidebar-gradient1.sidebar-hover:not(.collapsed) .submenu .nav-item.active > .nav-link > .nav-text::before {
    content: "";
    display: block;
    opacity: 1;
  }
}

.sidebar-gradient1 .submenu .nav-text::before {
  display: none;
  position: absolute;
  left: 2.75em;
  z-index: 1;
  opacity: 0.65;
  top: calc(50% - 0.1875rem);
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 0.75rem;
  background-color: currentColor;
}

.sidebar-gradient1 .submenu .submenu .nav-text::before {
  left: 3.25em;
}

.sidebar-gradient1 .submenu .submenu .submenu .nav-text::before {
  left: 4em;
}

.sidebar-gradient1 .submenu .nav-item.active:not(.open) > .nav-link > .nav-text::before {
  content: "";
  display: block;
  opacity: 1;
}

@media (min-width: 1200px) {
  .sidebar-gradient1.hoverable.collapsed .submenu .nav-text::before, .sidebar-gradient1.sidebar-hover:not(.collapsed) .submenu .nav-text::before {
    left: calc(0.5em + 1px) !important;
  }
}

@media (min-width: 1200px) {
  .sidebar-gradient1.hoverable.collapsed .sidebar-section-item .fadeable,
  .sidebar-gradient1.hoverable.collapsed .nav > .nav-item > .nav-link > .nav-text,
  .sidebar-gradient1.hoverable.collapsed .nav > .nav-item > .submenu {
    background-color: #2d5365;
  }
  .sidebar-gradient1.hoverable.collapsed .sub-arrow {
    border-color: #2d5365 !important;
  }
}

@media (min-width: 1200px) {
  .sidebar-gradient1.sidebar-hover:not(.collapsed) .sidebar-section-item .fadeable,
  .sidebar-gradient1.sidebar-hover:not(.collapsed) .nav > .nav-item > .submenu, .sidebar-gradient1.sidebar-h:not(.collapsed) .sidebar-section-item .fadeable,
  .sidebar-gradient1.sidebar-h:not(.collapsed) .nav > .nav-item > .submenu {
    background-color: #2d5365;
  }
  .sidebar-gradient1.sidebar-hover:not(.collapsed) .sub-arrow, .sidebar-gradient1.sidebar-h:not(.collapsed) .sub-arrow {
    border-color: #2d5365 !important;
  }
}

.sidebar-gradient1 .ace-scroll {
}

.sidebar-gradient1 .ace-scroll::-webkit-scrollbar-track {
  border-right: 1px solid #2d5365;
}

.sidebar-gradient1 .ace-scroll::-webkit-scrollbar-thumb {
  border-right: 1px solid #2d5365;
}

.sidebar-gradient2 {
  background-color: #3b6471;
  background-image: linear-gradient(#362a81, #237b49);
  --active-border-offset-right: 1px;
}

.sidebar-gradient2 .sidebar-inner {
  background: inherit;
}

.sidebar-gradient2 .nav.active-on-right .nav-item.active .nav-link::before {
  right: 1px;
}

@media (max-width: 1199.98px) {
  .sidebar-gradient2:not(.sidebar-fixed) .sidebar-inner {
    border-bottom: 1px solid #3b6471;
  }
}

.sidebar-gradient2 .nav > .nav-item {
  margin-bottom: 1px;
}

.sidebar-gradient2 .nav > .nav-item > .nav-link > .nav-icon {
  color: rgba(255, 255, 255, 0.66);
}

.sidebar-gradient2 .caret {
  color: inherit;
  opacity: 0.75;
}

.sidebar-gradient2 .open > .nav-link > .caret {
  color: inherit;
  opacity: 0.82;
}

.sidebar-gradient2 .nav-item > .nav-link:hover > .caret {
  color: inherit;
  opacity: 0.82;
}

.sidebar-gradient2 .nav-item.active > .nav-link > .caret {
  color: inherit;
  opacity: 0.82;
}

.sidebar-gradient2 .nav-item.active.open > .nav-link > .caret {
  color: inherit;
  opacity: 0.92;
}

@media (min-width: 1200px) {
  .sidebar-gradient2.sidebar-h .nav > .nav-item.active {
    border-width: 0 1px;
  }
}

.sidebar-gradient2 .nav > .nav-item.active:not(.open) > .nav-link {
  font-weight: 600;
}

.sidebar-gradient2 .submenu .nav-item.active > .nav-link {
  color: #f5e685;
}

.sidebar-gradient2 .submenu .nav-item.active:not(.open) > .nav-link {
  font-weight: 600;
}

@media (min-width: 1200px) {
  .sidebar-gradient2.hoverable.collapsed .submenu .nav-item.active.open > .nav-link, .sidebar-gradient2.sidebar-hover:not(.collapsed) .submenu .nav-item.active.open > .nav-link {
    color: #f5e685;
    font-weight: 600;
  }
  .sidebar-gradient2.hoverable.collapsed .submenu .nav-item.active > .nav-link > .nav-text::before, .sidebar-gradient2.sidebar-hover:not(.collapsed) .submenu .nav-item.active > .nav-link > .nav-text::before {
    content: "";
    display: block;
    opacity: 1;
  }
}

.sidebar-gradient2 .nav.has-active-border .nav-item.active > .nav-link::before {
  content: "";
  border-color: #fff;
}

.sidebar-gradient2 .submenu .nav-text::before {
  display: none;
  position: absolute;
  left: 2.75em;
  z-index: 1;
  opacity: 0.65;
  top: calc(50% - 0.1875rem);
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 0.75rem;
  background-color: currentColor;
}

.sidebar-gradient2 .submenu .submenu .nav-text::before {
  left: 3.25em;
}

.sidebar-gradient2 .submenu .submenu .submenu .nav-text::before {
  left: 4em;
}

.sidebar-gradient2 .submenu .nav-item.active:not(.open) > .nav-link > .nav-text::before {
  content: "";
  display: block;
  opacity: 1;
}

@media (min-width: 1200px) {
  .sidebar-gradient2.hoverable.collapsed .submenu .nav-text::before, .sidebar-gradient2.sidebar-hover:not(.collapsed) .submenu .nav-text::before {
    left: calc(0.5em + 1px) !important;
  }
}

@media (min-width: 1200px) {
  .sidebar-gradient2.hoverable.collapsed .sidebar-section-item .fadeable,
  .sidebar-gradient2.hoverable.collapsed .nav > .nav-item > .nav-link > .nav-text,
  .sidebar-gradient2.hoverable.collapsed .nav > .nav-item > .submenu {
    background-color: #3b6471;
  }
  .sidebar-gradient2.hoverable.collapsed .sub-arrow {
    border-color: #3b6471 !important;
  }
}

@media (min-width: 1200px) {
  .sidebar-gradient2.sidebar-hover:not(.collapsed) .sidebar-section-item .fadeable,
  .sidebar-gradient2.sidebar-hover:not(.collapsed) .nav > .nav-item > .submenu, .sidebar-gradient2.sidebar-h:not(.collapsed) .sidebar-section-item .fadeable,
  .sidebar-gradient2.sidebar-h:not(.collapsed) .nav > .nav-item > .submenu {
    background-color: #3b6471;
  }
  .sidebar-gradient2.sidebar-hover:not(.collapsed) .sub-arrow, .sidebar-gradient2.sidebar-h:not(.collapsed) .sub-arrow {
    border-color: #3b6471 !important;
  }
}

.sidebar-gradient2 .ace-scroll {
}

.sidebar-gradient2 .ace-scroll::-webkit-scrollbar-track {
  border-right: 1px solid #3b6471;
}

.sidebar-gradient2 .ace-scroll::-webkit-scrollbar-thumb {
  border-right: 1px solid #3b6471;
}

.sidebar-gradient3 {
  background-color: #46444b;
  background-image: linear-gradient(#364663, #564133);
  --active-border-offset-right: 1px;
}

.sidebar-gradient3 .sidebar-inner {
  background: inherit;
}

.sidebar-gradient3 .nav.active-on-right .nav-item.active .nav-link::before {
  right: 1px;
}

@media (max-width: 1199.98px) {
  .sidebar-gradient3:not(.sidebar-fixed) .sidebar-inner {
    border-bottom: 1px solid #46444b;
  }
}

.sidebar-gradient3 .nav > .nav-item {
  margin-bottom: 1px;
}

.sidebar-gradient3 .nav > .nav-item > .nav-link > .nav-icon {
  color: rgba(255, 255, 255, 0.66);
}

.sidebar-gradient3 .caret {
  color: inherit;
  opacity: 0.75;
}

.sidebar-gradient3 .open > .nav-link > .caret {
  color: inherit;
  opacity: 0.82;
}

.sidebar-gradient3 .nav-item > .nav-link:hover > .caret {
  color: inherit;
  opacity: 0.82;
}

.sidebar-gradient3 .nav-item.active > .nav-link > .caret {
  color: inherit;
  opacity: 0.82;
}

.sidebar-gradient3 .nav-item.active.open > .nav-link > .caret {
  color: inherit;
  opacity: 0.92;
}

@media (min-width: 1200px) {
  .sidebar-gradient3.sidebar-h .nav > .nav-item.active {
    border-width: 0 1px;
  }
}

.sidebar-gradient3 .nav > .nav-item.active > .nav-link {
  color: #fff;
  font-weight: 600;
}

.sidebar-gradient3 .nav > .nav-item.active > .nav-link > .nav-icon {
  color: #fff;
}

.sidebar-gradient3 .submenu .nav-item.active > .nav-link {
  color: rgba(245, 225, 133, 0.9);
}

.sidebar-gradient3 .submenu .nav-item.active:not(.open) > .nav-link {
  font-weight: 600;
}

@media (min-width: 1200px) {
  .sidebar-gradient3.hoverable.collapsed .submenu .nav-item.active.open > .nav-link, .sidebar-gradient3.sidebar-hover:not(.collapsed) .submenu .nav-item.active.open > .nav-link {
    color: rgba(245, 225, 133, 0.9);
    font-weight: 600;
  }
  .sidebar-gradient3.hoverable.collapsed .submenu .nav-item.active > .nav-link > .nav-text::before, .sidebar-gradient3.sidebar-hover:not(.collapsed) .submenu .nav-item.active > .nav-link > .nav-text::before {
    content: "";
    display: block;
    opacity: 1;
  }
}

.sidebar-gradient3 .nav.has-active-border .nav-item.active > .nav-link::before {
  content: "";
  border-color: #fff;
}

.sidebar-gradient3 .submenu .nav-text::before {
  display: none;
  position: absolute;
  left: 2.75em;
  z-index: 1;
  opacity: 0.65;
  top: calc(50% - 0.375rem);
  width: 0;
  height: 0;
  border: 0.375rem solid transparent;
  border-left-color: currentColor;
  border-right-width: 0;
}

.sidebar-gradient3 .submenu .submenu .nav-text::before {
  left: 3.25em;
}

.sidebar-gradient3 .submenu .submenu .submenu .nav-text::before {
  left: 4em;
}

.sidebar-gradient3 .submenu .nav-item.active:not(.open) > .nav-link > .nav-text::before {
  content: "";
  display: block;
  opacity: 1;
}

@media (min-width: 1200px) {
  .sidebar-gradient3.hoverable.collapsed .submenu .nav-text::before, .sidebar-gradient3.sidebar-hover:not(.collapsed) .submenu .nav-text::before {
    left: calc(0.5em + 1px) !important;
  }
}

@media (min-width: 1200px) {
  .sidebar-gradient3.hoverable.collapsed .sidebar-section-item .fadeable,
  .sidebar-gradient3.hoverable.collapsed .nav > .nav-item > .nav-link > .nav-text,
  .sidebar-gradient3.hoverable.collapsed .nav > .nav-item > .submenu {
    background-color: #46444b;
  }
  .sidebar-gradient3.hoverable.collapsed .sub-arrow {
    border-color: #46444b !important;
  }
}

@media (min-width: 1200px) {
  .sidebar-gradient3.sidebar-hover:not(.collapsed) .sidebar-section-item .fadeable,
  .sidebar-gradient3.sidebar-hover:not(.collapsed) .nav > .nav-item > .submenu, .sidebar-gradient3.sidebar-h:not(.collapsed) .sidebar-section-item .fadeable,
  .sidebar-gradient3.sidebar-h:not(.collapsed) .nav > .nav-item > .submenu {
    background-color: #46444b;
  }
  .sidebar-gradient3.sidebar-hover:not(.collapsed) .sub-arrow, .sidebar-gradient3.sidebar-h:not(.collapsed) .sub-arrow {
    border-color: #46444b !important;
  }
}

.sidebar-gradient3 .ace-scroll {
}

.sidebar-gradient3 .ace-scroll::-webkit-scrollbar-track {
  border-right: 1px solid #46444b;
}

.sidebar-gradient3 .ace-scroll::-webkit-scrollbar-thumb {
  border-right: 1px solid #46444b;
}

.sidebar-gradient4 {
  background-color: #223a63;
  background-image: linear-gradient(#3e5376, #052050);
  --active-border-offset-right: 1px;
}

.sidebar-gradient4 .sidebar-inner {
  background: inherit;
}

.sidebar-gradient4 .nav.active-on-right .nav-item.active .nav-link::before {
  right: 1px;
}

@media (max-width: 1199.98px) {
  .sidebar-gradient4:not(.sidebar-fixed) .sidebar-inner {
    border-bottom: 1px solid #223a63;
  }
}

.sidebar-gradient4 .nav > .nav-item {
  margin-bottom: 1px;
}

.sidebar-gradient4 .nav > .nav-item > .nav-link > .nav-icon {
  color: rgba(255, 255, 255, 0.66);
}

.sidebar-gradient4 .caret {
  color: inherit;
  opacity: 0.75;
}

.sidebar-gradient4 .open > .nav-link > .caret {
  color: inherit;
  opacity: 0.82;
}

.sidebar-gradient4 .nav-item > .nav-link:hover > .caret {
  color: inherit;
  opacity: 0.82;
}

.sidebar-gradient4 .nav-item.active > .nav-link > .caret {
  color: inherit;
  opacity: 0.82;
}

.sidebar-gradient4 .nav-item.active.open > .nav-link > .caret {
  color: inherit;
  opacity: 0.92;
}

@media (min-width: 1200px) {
  .sidebar-gradient4.sidebar-h .nav > .nav-item.active {
    border-width: 0 1px;
  }
}

.sidebar-gradient4 .nav > .nav-item.active > .nav-link {
  color: #fff;
  background-color: rgba(90, 157, 195, 0.4);
}

.sidebar-gradient4 .nav > .nav-item.active:not(.open) > .nav-link {
  font-weight: 600;
}

.sidebar-gradient4 .nav > .nav-item.active > .nav-link > .nav-icon {
  color: rgba(255, 255, 255, 0.87);
}

.sidebar-gradient4 .submenu .nav-item.active > .nav-link {
  color: #baddf7;
}

.sidebar-gradient4 .submenu .nav-item.active:not(.open) > .nav-link {
  font-weight: 600;
}

@media (min-width: 1200px) {
  .sidebar-gradient4.hoverable.collapsed .submenu .nav-item.active.open > .nav-link, .sidebar-gradient4.sidebar-hover:not(.collapsed) .submenu .nav-item.active.open > .nav-link {
    color: #baddf7;
    font-weight: 600;
  }
  .sidebar-gradient4.hoverable.collapsed .submenu .nav-item.active > .nav-link > .nav-text::before, .sidebar-gradient4.sidebar-hover:not(.collapsed) .submenu .nav-item.active > .nav-link > .nav-text::before {
    content: "";
    display: block;
    opacity: 1;
  }
}

.sidebar-gradient4 .submenu .nav-text::before {
  display: none;
  position: absolute;
  left: 2.75em;
  z-index: 1;
  opacity: 0.65;
  top: calc(50% - 0.375rem);
  width: 0;
  height: 0;
  border: 0.375rem solid transparent;
  border-left-color: currentColor;
  border-right-width: 0;
}

.sidebar-gradient4 .submenu .submenu .nav-text::before {
  left: 3.25em;
}

.sidebar-gradient4 .submenu .submenu .submenu .nav-text::before {
  left: 4em;
}

.sidebar-gradient4 .submenu .nav-item > .nav-link:hover > .nav-text::before {
  content: "";
  display: block;
  opacity: 0.8;
}

.sidebar-gradient4 .submenu .nav-item.active:not(.open) > .nav-link > .nav-text::before {
  content: "";
  display: block;
  opacity: 1;
}

@media (min-width: 1200px) {
  .sidebar-gradient4.hoverable.collapsed .submenu .nav-text::before, .sidebar-gradient4.sidebar-hover:not(.collapsed) .submenu .nav-text::before {
    left: calc(0.5em + 1px) !important;
  }
}

@media (min-width: 1200px) {
  .sidebar-gradient4.hoverable.collapsed .sidebar-section-item .fadeable,
  .sidebar-gradient4.hoverable.collapsed .nav > .nav-item > .nav-link > .nav-text,
  .sidebar-gradient4.hoverable.collapsed .nav > .nav-item > .submenu {
    background-color: #223a63;
  }
  .sidebar-gradient4.hoverable.collapsed .sub-arrow {
    border-color: #223a63 !important;
  }
}

@media (min-width: 1200px) {
  .sidebar-gradient4.sidebar-hover:not(.collapsed) .sidebar-section-item .fadeable,
  .sidebar-gradient4.sidebar-hover:not(.collapsed) .nav > .nav-item > .submenu, .sidebar-gradient4.sidebar-h:not(.collapsed) .sidebar-section-item .fadeable,
  .sidebar-gradient4.sidebar-h:not(.collapsed) .nav > .nav-item > .submenu {
    background-color: #223a63;
  }
  .sidebar-gradient4.sidebar-hover:not(.collapsed) .sub-arrow, .sidebar-gradient4.sidebar-h:not(.collapsed) .sub-arrow {
    border-color: #223a63 !important;
  }
}

.sidebar-gradient4 .ace-scroll {
}

.sidebar-gradient4 .ace-scroll::-webkit-scrollbar-track {
  border-right: 1px solid #223a63;
}

.sidebar-gradient4 .ace-scroll::-webkit-scrollbar-thumb {
  border-right: 1px solid #223a63;
}

@media (min-width: 1200px) {
  .sidebar-color.hoverable.collapsed .submenu .submenu,
  .sidebar-color.hoverable.collapsed .submenu-inner,
  .sidebar-color.hoverable.collapsed .submenu .nav-item {
    background-color: inherit !important;
  }
  .sidebar-color.hoverable.collapsed .nav > .nav-item > .nav-link > .nav-text {
    color: rgba(255, 255, 255, 0.93) !important;
  }
  .sidebar-color.hoverable.collapsed .nav > .nav-item > .submenu {
    top: calc(100% - 2px);
  }
  .sidebar-color.hoverable.collapsed .sub-arrow::before {
    border-right-color: rgba(255, 255, 255, 0.33) !important;
  }
  .sidebar-color.hoverable.collapsed .sub-arrow::after {
    border-right-color: inherit !important;
  }
  .sidebar-color.hoverable.collapsed .sidebar-section-item .fadeable {
    border: 1px solid;
    box-shadow: 2px 1px 2px 0 rgba(0, 0, 0, 0.1);
  }
  .sidebar-color.hoverable.collapsed .submenu,
  .sidebar-color.hoverable.collapsed .nav > .nav-item > .nav-link > .nav-text,
  .sidebar-color.hoverable.collapsed .sidebar-section-item .fadeable {
    border-color: rgba(0, 0, 0, 0.13) rgba(0, 0, 0, 0.13) rgba(0, 0, 0, 0.13) rgba(255, 255, 255, 0.2);
  }
  .sidebar-color.hoverable.collapsed .nav > .nav-item > .submenu {
    border-top-color: rgba(255, 255, 255, 0.2);
  }
  .sidebar-color.hoverable.collapsed .submenu .nav-text::before {
    display: none;
  }
  .sidebar[class*="sidebar-gradient"]:not(.collapsed) .sidebar-color {
    background-color: transparent;
    background-image: none;
  }
  .sidebar-color.sidebar-hover:not(.collapsed) .submenu .submenu,
  .sidebar-color.sidebar-hover:not(.collapsed) .submenu-inner,
  .sidebar-color.sidebar-hover:not(.collapsed) .submenu .nav-item {
    background-color: inherit !important;
  }
  .sidebar-color.sidebar-hover:not(.collapsed) .sub-arrow::before {
    border-right-color: rgba(255, 255, 255, 0.33) !important;
  }
  .sidebar-color.sidebar-hover:not(.collapsed) .sub-arrow::after {
    border-right-color: inherit !important;
  }
  .sidebar-color.sidebar-hover:not(.collapsed) .submenu {
    border-color: rgba(0, 0, 0, 0.13) rgba(0, 0, 0, 0.13) rgba(0, 0, 0, 0.13) rgba(255, 255, 255, 0.2);
  }
  .sidebar-color.sidebar-hover:not(.collapsed) .submenu .nav-text::before {
    display: none;
  }
  .sidebar-color.sidebar-h .nav > .nav-item > .submenu,
  .sidebar-color.sidebar-h .sidebar-section-item .fadeable {
    border: 1px solid;
    border-color: rgba(255, 255, 255, 0.2) rgba(0, 0, 0, 0.13) rgba(0, 0, 0, 0.13) rgba(0, 0, 0, 0.13);
    box-shadow: none !important;
  }
  .sidebar-color.sidebar-h .nav > .nav-item > .sub-arrow::before,
  .sidebar-color.sidebar-h .sidebar-section-item .sub-arrow::before {
    border-right-color: transparent !important;
    border-bottom-color: inherit !important;
  }
  .sidebar-color.sidebar-h .nav > .nav-item > .sub-arrow::after,
  .sidebar-color.sidebar-h .sidebar-section-item .sub-arrow::after {
    border-right-color: transparent !important;
    border-bottom-color: rgba(255, 255, 255, 0.33) !important;
  }
}

.body-img1 {
  background-color: #424862;
  background-image: url("/assets/image/body-bg-1.jpg");
  background-attachment: fixed;
  background-repeat: no-repeat;
}

.body-img2 {
  background-color: #272f53;
  background-image: url("/assets/image/body-bg-2.jpg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}

/*# sourceMappingURL=ace-themes.css.map */